import { React, useState, useEffect } from "react";
import Logo from "../../../assets/pink Icon.png";
import GradientButton from "../../../components/GradientButton/GradientButton";
import Channels from "./Channels";
import Chat from "./Chat";
import {
	doc,
	getDoc,
	getDocs,
	query,
	where,
	collection,
	setDoc,
	onSnapshot,
	orderBy,
	or,
	limit,
	addDoc,
	updateDoc,
	arrayUnion,
	serverTimestamp,
	arrayRemove,
} from "firebase/firestore";
import { db, usersRef, channelsRef } from "../../../firebase/firestore_config"; //useful for setDoc
import { useDispatch, useSelector } from "react-redux";
import { loaderActions } from "../../../redux/reducers/loader";
import { Avatar, CircularProgress } from "@mui/material";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { channelSchema } from "../../../validations/community";
import { Backdrop, Dialog, DialogContent, Modal } from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import EmojiKeyboard from "./EmojiKeyboard";
import { useNavigate } from "react-router-dom";

const Community = () => {
	const navigate = useNavigate();
	const { resolutionType } = useSelector((state) => state.resolution);
	const { loading } = useSelector((state) => state.loader);
	const dispatch = useDispatch();
	const userState = useSelector((state) => state.user);

	const [channelsLoading, setChannelsLoading] = useState(true);
	const [chatLoading, setChatLoading] = useState(true);
	const [openMem, setOpenMem] = useState(false);
	const [channelMembersLoading, setChannelMembersLoading] = useState(true);

	const [welcome, setWelcome] = useState(false);

	const [flag, setFlag] = useState(true);
	const [firstTime, setFirstTime] = useState(true);
	const [channels, setChannels] = useState([]);

	const [currChannel, setCurrChannel] = useState(null);
	const [messages, setMessages] = useState(null); // PAGINATION????
	const [earliest, setEarliest] = useState(null);
	const [createChannelError, setCreateChannelError] = useState(null);

	const [messagesCache, setMessagesCache] = useState({}); // id of channel to draft and mediaDraft
	const [draft, setDraft] = useState(""); // current draft to show (when updating snapshot, update messagesCache and draft accordingly)
	const [mediaDraft, setMediaDraft] = useState(null);

	const [channelMembers, setChannelMembers] = useState({});
	const [openAddMembers, setOpenAddMembers] = useState(false);
	const [searchList, setSearchList] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [selectedMembers, setSelectedMembers] = useState({});
	const [addMemberError, setAddMemberError] = useState(false);

	const [openAddChannel, setOpenAddChannel] = useState(false);
	const [addChannel, setAddChannel] = useState("");

	const [openLeave, setOpenLeave] = useState(false);
	const [openEmoji, setOpenEmoji] = useState(false);

	// show welcome page?
	const insertUser = async () => {
		// yet to test with new users!
		const userData = userState.userData;
		const userDocRef = doc(usersRef, userData._id);
		await setDoc(userDocRef, {
			email: userData.email,
			firstName: userData.firstName,
			lastName: userData.lastName,
			pfp: "", // deal with this later
		});
		// const q = query(where("isPublic", "==", "true"));
		// const docsList = await getDocs(channelsRef, query);
		// for (let i = 0; i < docsList.doc().length; i++) {
		// 	await updateDoc(doc(db, "channels", docsList.id), {
		// 		users: arrayUnion(userData._id),
		// 	});
		// }
		const q = query(channelsRef, where("isPublic", "==", true));
		const docsList = await getDocs(q);
		docsList.forEach(async (doc) => {
			await updateDoc(doc.ref, {
				users: arrayUnion(userData._id),
			});
		});
	};

	// const welcomeAsync = async () => {
	// 	const welcomeRef = doc(db, "users", userState.userData._id);

	// 	const welcomeSnapshot = await getDoc(welcomeRef);

	// 	if (!welcomeSnapshot.exists()) {
	// 		await insertUser();
	// 	}
	// 	setWelcome(true);
	// };

	const fetchChannels = async () => {
		const channelQuery = query(
			channelsRef,
			or(
				where("isPublic", "==", true),
				where("users", "array-contains", userState.userData._id)
			),
		);
		const channel_lifecycle = onSnapshot(channelQuery, (channelSnap) => {
			const tempChannels = [];
			channelSnap.forEach((doc) => {
				tempChannels.push({
					id: doc.id,
					fields: { ...doc.data() },
				});
			});
			const sortedChannels = tempChannels.sort((a, b) => {
				const titleA = a.fields.title.toLowerCase();
				const titleB = b.fields.title.toLowerCase();
				if (titleA === "aeternal lovers") return -1;
				if (titleB === "aeternal lovers") return 1; 
				return titleA.localeCompare(titleB);
			});
			setChannels(sortedChannels);
			// if (flag) {
			// 	setCurrChannel(tempChannels[0]);
			// 	setChannelMembersLoading(true);
			// 	fetchChannelMembers(tempChannels[0]).finally(() => {
			// 		setTimeout(() => {
			// 			setChannelMembersLoading(false);
			// 		}, 200);
			// 	});
			// 	setFlag(false);
			// }
		});

		return () => channel_lifecycle();
	};
	useEffect(() => {
		if (channels.length && flag) {
			setCurrChannel(channels[0]);
			setChannelMembersLoading(true);
			fetchChannelMembers(channels[0]).finally(() => {
				setTimeout(() => {
					setChannelMembersLoading(false);
				}, 200);
			});
			setFlag(false);
		}
	}, [channels, flag])
	useEffect(() => {
		// if (userState) {
		// 	dispatch(loaderActions.toggleLoading(true));
		// 	welcomeAsync().then(() => {
		// 		dispatch(loaderActions.toggleLoading(false));
		// 	});
		// } // only when logged in

		// first check

		if (!JSON.parse(localStorage.getItem("AL_USER")).accessToken) {
			navigate("/login", { state: { from: window.location.pathname } });
		} else {
			if (userState?.userData?._id) {
				// will glitch after log-out?s
				getDoc(doc(db, "users", userState?.userData?._id)).then((res) => {
					if (res.exists()) {
						setWelcome(true);
						setChannelsLoading(true);
						fetchChannels().finally(() => {
							setChannelsLoading(false);
						});
					} else setChannelsLoading(false);
				});
			}
		}
	}, [userState]);

	const fetchChannelMembers = async (channel) => {
		if (channel) {
			// fetch array of users
			const channelDoc = await getDoc(doc(db, "channels", channel?.id));
			if (channelDoc.exists) {
				const userArr = channelDoc.data().users;
				const tempChannelMembers = {};

				let requests = await userArr.map((item, index) => {
					return new Promise((resolve) => {
						resolve(getDoc(doc(db, "users", item)));
					});
				});

				Promise.all(requests).then((res) => {
					let size = 0;
					res.forEach((item, index) => {
						if (item.exists) {
							size += 1;
							tempChannelMembers[item.id] = { ...item.data() };
						}
					});
					setChannelMembers({ ...tempChannelMembers, size });
				});
			}
		}
	};

	useEffect(() => {
		if (!currChannel) return;

		setChatLoading(true);

		const chatQuery = query(
			collection(doc(channelsRef, currChannel?.id), "chat"), // not using currChannel but same thing
			orderBy("createdAt", "desc") // earliest first
			// limit(2)
		);

		const chat_lifecycle = onSnapshot(chatQuery, (querySnap) => {
			const tempMessages = [];

			querySnap.forEach((doc) => {
				tempMessages.push({
					id: doc.id,
					...doc.data(),
				});
			});

			setEarliest(querySnap.docs[querySnap.docs.length - 1]);

			// onSnapshot will append to the beginning of the array
			// getDocs (triggered on scroll to top) will append to the end of the array

			setMessages(tempMessages);
			setChatLoading(false);
		});

		return () => chat_lifecycle();
	}, [currChannel]);

	useEffect(() => {
		if (!searchText) {
			setSearchList(null);
			return;
		}
		const timeoutID = setTimeout(() => {
			searchMembersFirestore(searchText);
		}, 500);

		return () => {
			clearTimeout(timeoutID);
		};
	}, [searchText]);

	const saveDraft = (oldChannel, newChannel) => {
		if (draft !== "" || mediaDraft) {
			// just update
			const newCacheObj = {
				draft,
				mediaDraft,
			};

			setMessagesCache((curr) => ({
				...curr,
				[oldChannel.id]: newCacheObj,
			}));
		}

		if (newChannel && messagesCache.hasOwnProperty(newChannel.id)) {
			setDraft(messagesCache[newChannel.id].draft);
			setMediaDraft(messagesCache[newChannel.id].mediaDraft);
		} else {
			setDraft("");
			setMediaDraft(null);
		}
	};

	const searchMembersFirestore = async (val) => {
		const q1 = query(
			usersRef,
			where("firstName", ">=", val),
			where("firstName", "<=", val + "\uf8ff")
		);
		const q2 = query(
			usersRef,
			where("lastName", ">=", val),
			where("lastName", "<=", val + "\uf8ff")
		);
		const tempSearch = {};
		const firstSnapshot = await getDocs(q1);
		const lastSnapshot = await getDocs(q2);
		firstSnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			tempSearch[doc.id] = doc.data();
		});
		lastSnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			tempSearch[doc.id] = doc.data();
		});

		// remove those members that exist in the current channel using the members state
		Object.entries(tempSearch).map((item, index) => {
			if (channelMembers.hasOwnProperty(item[0])) delete tempSearch[item[0]];
		});

		setSearchList(tempSearch);
	};

	const saveMember = (key, value) => {
		setSelectedMembers((curr) => ({ ...curr, [key]: value }));
		const tempList = searchList;
		delete tempList[key];
		if (!Object.keys(tempList).length) setSearchList(null);
		else setSearchList(tempList);
	};

	const addMember = () => {
		if (Object.keys(selectedMembers).length > 0) {
			// setChatLoading(true);
			updateDoc(doc(db, "channels", currChannel?.id), {
				users: arrayUnion(...Object.keys(selectedMembers)),
			}).then(async () => {
				const documentRef = doc(db, "channels", currChannel?.id);
				const currentChannel = await getDoc(documentRef)
				if (currentChannel.exists()) {
					setCurrChannel({
						id: currentChannel.id,
						fields: { ...currentChannel.data() },
					})
				}
				fetchChannelMembers(currChannel).finally(() => {
					setTimeout(() => {
						setChannelMembersLoading(false);
					}, 200);
				});
				setSearchText("");
				setSearchList(null);
				setSelectedMembers({});
				setOpenAddMembers(false);
			});
		} else {
			setAddMemberError(true);
			setTimeout(() => {
				setAddMemberError(false);
			}, 3000);
		}
	};

	const removeMember = (key, value) => {
		const tempObj = selectedMembers;
		delete tempObj[key];
		setSelectedMembers(tempObj);
		searchMembersFirestore(searchText);
	};

	useEffect(() => {
		setTimeout(() => {
			setCreateChannelError(null);
		}, 5000);
	}, [createChannelError]);

	const createChannel = async () => {
		const { error } = channelSchema.validate({ title: addChannel });
		setCurrChannel(currChannel)
		setOpenMem(false)
		if (error) {
			setCreateChannelError(error?.details[0]?.message);
		} else {
			setCreateChannelError(null);
			const channelObj = {
				title: addChannel,
				isPublic: false,
				admin: userState?.userData?._id,
				users: [userState.userData._id],
				createdAt: serverTimestamp()
			};

			addDoc(channelsRef, channelObj)
				.then(async (res) => {
					const newChannelSnap = await getDoc(res);
					if (newChannelSnap.exists()) {
						setCurrChannel({
							id: newChannelSnap.id,
							fields: { ...newChannelSnap.data() },
						});
					}
				})
				.finally(() => {
					setAddChannel("");
					setOpenAddChannel(false);
				});

			// try {
			// 	const newChannelRef = await addDoc(channelsRef, channelObj);
			// 	console.log("NEW CHANNEL: ", newChannelRef.doc());
			// } catch (err) {
			// } finally {
			// 	setAddChannel("");
			// 	setOpenAddChannel(false);
			// }
		}
	};

	const leaveChannel = () => {
		updateDoc(doc(db, "channels", currChannel?.id), {
			users: arrayRemove(userState.userData._id),
		}).then(() => {
			setOpenLeave(false);
			setOpenMem(false)
			setFlag(true)
		});
	};
	console.group(currChannel)
	return (
		<>
			{channelsLoading ? (
				<div
					className="h-full flex justify-center items-center"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
				>
					<CircularProgress color="inherit" />
				</div>
			) : (
				<>
					{!welcome ? (
						<div className="flex h-full bg-[#FFF1F4] justify-center items-center">
							<div
								className={`rounded-lg bg-white ${resolutionType.type === "mobile"
									? "w-full p-4"
									: " w-1/3 p-10"
									}`}
							>
								<div className="flex justify-center items-center">
									<img src={Logo} className="w-14" />
								</div>
								<div class="my-2.5 text-center">
									<span class="block font-bold text-2xl leading-9">
										Welcome to the community!
									</span>
									<span class="block text-base leading-6">
										Explore, share, and collaborate: Unleash your musical
										passion in our vibrant community chat – where your voice is
										the melody of the conversation.
									</span>
								</div>
								<div class="flex justify-center">
									<GradientButton
										title="Explore Community"
										onClick={() => {
											dispatch(loaderActions.toggleLoading(true));
											insertUser().then(() => {
												fetchChannels().then(() => {
													setWelcome(true);
												});
												dispatch(loaderActions.toggleLoading(false));
											});
										}}
									/>
								</div>
							</div>
						</div>
					) : (
						<>
							<div
								class={`w-full h-full flex ${resolutionType.type === "mobile" ? "flex-col" : ""
									}`}
							>
								<Channels
									channels={channels}
									loading={channelsLoading}
									currChannel={currChannel}
									setCurrChannel={setCurrChannel}
									setOpenMem={setOpenMem}
									saveDraft={saveDraft}
									openAddModal={() => setOpenAddChannel(true)}
									fetchChannelMembers={fetchChannelMembers}
									setChatLoading={setChatLoading}
									setChannelMembersLoading={setChannelMembersLoading}
								/>
								<Chat
									chatLoading={chatLoading}
									membersLoading={channelMembersLoading}
									setCurrChannel={setCurrChannel}
									currChannel={currChannel}
									setMessagesCache={setMessagesCache}
									fetchChannelMembers={fetchChannelMembers}
									draft={draft}
									setOpenMem={setOpenMem}
									openMem={openMem}
									setDraft={setDraft}
									mediaDraft={mediaDraft}
									setMediaDraft={setMediaDraft}
									setChannelMembersLoading={setChannelMembersLoading}
									messages={messages}
									setOpenAddMembers={setOpenAddMembers}
									channelMembers={channelMembers}
									setOpenLeave={setOpenLeave}
									openEmoji={openEmoji}
									setOpenEmoji={setOpenEmoji}
								/>
							</div>
							{openAddMembers && (
								<CustomModal
									title="Add Members"
									caption="Invite friend to Join the community"
									open={openAddMembers}
									onClose={() => setOpenAddMembers(false)}
								>
									<div className="bg-white p-4 flex flex-col gap-y-2">
										<div className="flex flex-col">
											<span className="block font-medium text-base">
												Search Members
											</span>
											<CustomInput
												placeholder="Search..."
												value={searchText}
												onChange={(e) => {
													setSearchText(e.target.value);
												}}
												style={{ marginY: "0.5rem" }}
											/>
											{searchList && (
												<div className="w-full max-h-64 overflow-y-auto flex flex-col gap-y-2 my-4 border-y-2 border-[#e5e7eb]">
													{!Object.keys(searchList)?.length ? (
														<div className="my-2">
															{searchText ? "No member available" : "..."}
														</div>
													) : (
														Object.entries(searchList)?.map((item, index) => {
															if (!selectedMembers.hasOwnProperty(item[0])) {
																return (
																	<div
																		key={index}
																		className="my-2 flex gap-x-2 items-center hover:bg-[#e5e7eb] hover:cursor-pointer"
																		onClick={() => saveMember(item[0], item[1])}
																	>
																		<div className="w-11">
																			<div
																				className={`w-full aspect-square rounded-full`}
																			>
																				<Avatar
																					className="w-full"
																					src={item?.[1]?.pfp}
																					sx={{ fontSize: "16px" }}
																				>
																					{item?.[1]?.firstName?.[0].toUpperCase()}
																					{item?.[1]?.lastName?.[0].toUpperCase()}
																				</Avatar>
																			</div>
																		</div>
																		<div className="text-base font-semibold">{`${item[1].firstName} ${item[1].lastName}`}</div>
																	</div>
																);
															}
														})
													)}
												</div>
											)}
										</div>
										{Object.keys(selectedMembers).length > 0 && (
											<div className="flex flex-col">
												<span className="block font-medium text-base">
													Selected Members
												</span>
												<div
													className={`w-full max-h-64 overflow-y-auto flex flex-col gap-y-2 my-4 ${Object.keys(selectedMembers).length &&
														"border-y-2 border-[#e5e7eb]"
														}`}
												>
													{Object.keys(selectedMembers).length ? (
														<div className="flex flex-wrap gap-2">
															{Object.entries(selectedMembers).map(
																(item, index) => {
																	return (
																		<div key={index} className="m-2 relative">
																			<GradientButton
																				style={{
																					padding: "8px",
																					fontSize: "16px",
																				}}
																				alt={
																					item[1].firstName?.[0] +
																					item[1].lastName?.[0]
																				}
																				profilePicture={item[1]?.pfp}
																				title={
																					item[1].firstName +
																					" " +
																					item[1].lastName
																				}
																			/>
																			<div
																				style={{
																					position: "absolute",
																					bottom: "0.75rem",
																					left: "90%",
																					width: "1.5rem",
																					display: "flex",
																					cursor: "pointer",
																				}}
																				onClick={() =>
																					removeMember(item[0], item[1])
																				}
																			>
																				<svg
																					width="56"
																					height="56"
																					viewBox="0 0 56 56"
																					fill="none"
																					xmlns="http://www.w3.org/2000/svg"
																				>
																					<circle
																						cx="28"
																						cy="28"
																						r="16"
																						fill="white"
																					/>
																					<path
																						d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
																						fill="#E6355C"
																					/>
																				</svg>
																			</div>
																		</div>
																	);
																}
															)}
														</div>
													) : (
														<div className="w-full flex justify-center items-center">
															...
														</div>
													)}
												</div>
											</div>
										)}

										<GradientButton
											title="Add Member"
											style={{}}
											disable={Object.keys(selectedMembers)?.length === 0}
											onClick={addMember}
										// onClick={() => {
										// 	if (Object.keys(selectedMembers).length > 0) {
										// 		// setChatLoading(true);

										// 		updateDoc(doc(db, "channels", currChannel?.id), {
										// 			users: arrayUnion(...Object.keys(selectedMembers)),
										// 		}).then(() => {
										// 			setSearchText("");
										// 			setSearchList(null);
										// 			setSelectedMembers({});
										// 			setOpenAddMembers(false);
										// 		});
										// 	} else {
										// 		setAddMemberError(true);
										// 		setTimeout(() => {
										// 			setAddMemberError(false);
										// 		}, 3000);
										// 	}
										// }}
										/>
										{addMemberError && (
											<span style={{ display: "block" }}>
												Please select members to add
											</span>
										)}
									</div>
								</CustomModal>
							)}
							{openAddChannel && (
								<CustomModal
									title="Add New Community Channel"
									caption="Here you can add a new community channel"
									open={openAddChannel}
									onClose={() => {
										setCreateChannelError(null);
										setOpenAddChannel(false);
									}}
								>
									<div className="bg-white p-4 flex flex-col gap-y-2">
										<span className="block font-medium text-base">
											Enter channel Name
										</span>
										<div className="w-full flex flex-col">
											{/* <div className="w-8 text-center items-center py-[16px]">
												#
											</div>
											<div className="w-full">
												<CustomInput
													placeholder="Channel Name"
													value={addChannel}
													onChange={(e) => {
														setAddChannel(e.target.value);
													}}
													error={createChannelError}
												/>
											</div> */}
											<div
												className={`w-full rounded-[8px] border-[1px] p-1 ${createChannelError
													? "border-red-400"
													: "border-[#E4E4E4]"
													} flex`}
											>
												<span className="block py-[16px] font-medium text-sm">
													#
												</span>

												<input
													type="text"
													className={`w-full py-[16px] px-[8px] border-none outline-none`}
													placeholder="Channel Name"
													value={addChannel}
													onChange={(e) => {
														setAddChannel(e.target.value);
													}}
												/>
											</div>
											{createChannelError && (
												<p className="text-red-400 mt-2 font-normal text-sm">
													{createChannelError}
												</p>
											)}
										</div>
										<GradientButton
											title="Create Now"
											style={{
												marginTop: "0.5rem",
												fontSize: "16px",
												fontWeight: "600",
											}}
											onClick={createChannel}
										/>
									</div>
								</CustomModal>
							)}
							{openLeave && (
								<CustomModal
									title="Are you sure?"
									open={openLeave}
									onClose={() => setOpenLeave(false)}
								>
									<div className="bg-white p-4 flex flex-col gap-y-2">
										<div>
											<span>You are about to leave </span>
											<span className="text-lg font-bold">
												#{currChannel?.fields?.title}
											</span>
											...
										</div>
										<div className="flex justify-center gap-x-4">
											<GradientButton
												style={{ fontSize: "1rem" }}
												title="Confirm"
												onClick={leaveChannel}
											/>
											<GradientButton
												style={{ fontSize: "1rem" }}
												title="Cancel"
												onClick={() => setOpenLeave(false)}
											/>
										</div>
									</div>
								</CustomModal>
							)}
							{openEmoji && (
								<Modal
									open={openEmoji}
									onClose={() => setOpenEmoji(false)}
									slotProps={{
										backdrop: {
											timeout: 500,
											style: {
												backgroundColor: "transparent",
											},
										},
									}}
								>
									<div className="z-[3000] w-auto h-auto absolute bottom-24 right-4">
										<EmojiKeyboard
											open={openEmoji}
											theme="dark"
											onEmojiClick={(emojiVal) => {
												setDraft((curr) => `${curr} ${emojiVal.emoji}`);
											}}
										/>
									</div>
								</Modal>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default Community;
