import React, { useState } from 'react'
import GradientButton from '../../GradientButton/GradientButton'
import CustomInput from '../../CustomInput/CustomInput'
import galleryIcon from "../../../assets/galleryIcon.svg"
import closeIcon from "../../../assets/closeIcon.svg"
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { formDataConverter } from '../../../utils/helperFunctions'
import Joi from "joi";
import { addArtType, editArtType } from '../../../api/api_calls/user'

function AddArtType(props) {
    const { setArtTypeModal, fetchArtTypeList, modalData } = props
    const { loading } = useSelector((state) => state.loader);
    const [artTypeUrl, setArtTypeUrl] = useState(modalData?.images?.length > 0 ? [...modalData?.images] : [])
    const [errors, setErrors] = useState({});
    const [formDatas, setFormDatas] = useState({
        artTypeName: modalData?.artTypeName ? modalData?.artTypeName : "",
        description: modalData?.description ? modalData?.description : "",
        media: []
    })
    const handleImageSelect = (e) => {
        const files = e.target.files;
        console.log(files)
        const ImageArr = Object.values(files)
        if ((artTypeUrl.length <= 5 && ImageArr.length <= 5) && (ImageArr.length + artTypeUrl.length <= 5)) {
            for (let item of ImageArr) {
                if (item?.type.split("/")[0] === "video" || item.type.split("/")[0] === "audio") {
                    toast.error("Please select images only..")
                    return
                }
                if (item) {
                    const maxSize = 2 * 1024 * 1024;
                    if (item.size <= maxSize) {
                        setArtTypeUrl((prevImage) => [...prevImage, item])
                        setFormDatas(prevFormData => ({
                            ...prevFormData,
                            media: [...prevFormData?.media || [], item]
                        }))
                    } else {
                        setArtTypeUrl([]);
                        toast.error("Art Type image must be less than 2MB")
                    }
                }
            }
            e.target.value = null
        } else {
            toast.error("Cannot add more than 5 Images")
            e.target.value = null
        }
    }
    const schema = Joi.object({
        artTypeName: Joi.string().min(3).required().messages({
            "string.empty": "Art Type Name is required",
            "string.min": "Art Type Name must be at least 3 characters long",
        }),
        description: Joi.string().required().messages({
            "any.required": "Description is required",
        })
    });
    const handleAddArtType = (e) => {
        e.preventDefault()
        const { error } = schema.validate({ artTypeName: formDatas?.artTypeName, description: formDatas?.description })
        if (error) {
            setErrors({ [error?.details?.[0]?.context?.key]: error?.message })
            return
        }
        if (!artTypeUrl.length && !error) {
            toast.error("Please Select Art Type Image")
            return
        }
        if (Object.keys(modalData).length === 0) {
            const formData = new FormData()
            formData.append("artTypeName", formDatas.artTypeName);
            formData.append("description", formDatas.description);
            formDatas.media.forEach((file) => {
                formData.append('media', file);
            });
            addArtType(formData)
                .then((res) => {
                    toast.success(res?.data?.message, { theme: "dark" });
                    setArtTypeModal(false)
                    fetchArtTypeList()
                })
                .catch((err) => {
                    toast.error(err?.message, { theme: "dark" });
                })
        } else {
            const formData = new FormData()
            formData.append("artTypeId", modalData?._id)
            formData.append("artTypeName", formDatas.artTypeName);
            formData.append("description", formDatas.description);
            formDatas.media.forEach((file) => {
                formData.append('media', file);
            });
            const updatedImages = artTypeUrl?.filter((item) => modalData.images.includes(item))
            updatedImages.forEach((file) => {
                formData.append('artTypeImages', file);
            });
            editArtType(formData)
                .then((res) => {
                    toast.success(res?.data?.message, { theme: "dark" });
                    setArtTypeModal(false)
                    fetchArtTypeList()
                })
                .catch((err) => {
                    toast.error(err?.message, { theme: "dark" });
                })
        }
    }
    const handleChange = (e) => {
        const { name, value } = e?.target
        setFormDatas({ ...formDatas, [name]: value })
    }
    console.log(formDatas?.media)
    console.log(artTypeUrl)
    return (
        <form
            className="bg-white max-h-[80vh] overflow-auto py-[24px] px-[16px] flex flex-col gap-[16px]"
            onSubmit={handleAddArtType}
        >
            <div className="flex flex-col gap-[8px]">
                <label className="font-medium text-black text-base">Art type name</label>
                <CustomInput
                    name="artTypeName"
                    placeholder="Art type name"
                    value={formDatas?.artTypeName}
                    onChange={handleChange}
                    error={errors?.artTypeName}
                />
            </div>
            <label className="font-medium text-black text-base">Art type Description</label>
            <textarea
                className={`min-h-[172px] rounded-lg border-[1px] border-[#E4E4E4] outline-none resize-none py-4 px-2`}
                name="description"
                placeholder="Art type description"
                maxLength={1000}
                value={formDatas?.description}
                onChange={handleChange}
            />
            {errors?.description && (
                <p className="text-red-400 mt-2 font-normal text-sm">{errors?.description}</p>
            )}
            <label className="font-medium text-black text-base">Art type Image</label>
            <input type='file' multiple={true} style={{ display: "none" }} id='uploadImage'
                accept=".jpg, .jpeg, .png"
                onChange={handleImageSelect}
            />
            <label htmlFor='uploadImage' className='w-[80%]'>
                <div type='button' className='border rounded-lg text-sm border-dashed bg-[#EAEFFC] p-3 border-[#6964B4] text-[#6964B4] md:text-base font-normal flex gap-1 cursor-pointer 2xl:text-lg'>
                    <img src={galleryIcon} alt='galleryIcon' />
                    <Box marginLeft={1}>
                        <p className='text-lg font-semibold'>Upload Art type</p>
                        <p className='text-xs'>Upload Up to 2MB</p>
                    </Box>
                </div>
            </label>
            {artTypeUrl.length > 0 &&
                <div className="flex flex-wrap gap-2">
                    {artTypeUrl?.map((item, i) => <div className="relative w-[30%]">
                        <img
                            src={typeof item === "object" ? URL.createObjectURL(item) : item}
                            alt="pictureProfile"
                            className="object-cover"
                        />
                        <img
                            src={closeIcon}
                            alt="closeIcon"
                            className="h-8 w-8 cursor-pointer absolute top-[-10px] right-[-10px]"
                            onClick={() => {
                                const remainingUrl = artTypeUrl.filter((_, index) => index !== i)
                                const remainMediaData = formDatas?.media?.filter((mediaItem) => mediaItem !== item)
                                setFormDatas({ ...formDatas, media: [...remainMediaData] })
                                setArtTypeUrl(remainingUrl)
                            }}
                        />
                    </div>)}
                </div>
            }
            {errors?.media && <span className="text-red-400 mt-2 font-normal text-sm">{errors?.media}</span>}
            <GradientButton
                loading={loading}
                title={Object.keys(modalData).length === 0 ? "Upload" : "Update"}
                type="submit"
            />
        </form>
    )
}

export default AddArtType
