import React, { useState } from 'react'
import CustomInput from '../../CustomInput/CustomInput'
import GradientButton from '../../GradientButton/GradientButton'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/firestore_config'

const EditCommunityChanel = ({ communityData, onClose, setCurrChannel }) => {
  const [title, setTitle] = useState(communityData?.fields?.title || "")

  const handleSubmit = (e) => {
    e.preventDefault()
    const documentRef = doc(db, "channels", communityData?.id);
    getDoc(documentRef).then((docSnap) => {
      if (docSnap.exists()) {
        updateDoc(documentRef, {
          ...docSnap.data(),
          title
        }).then(async () => {
          const updatedChannel = await getDoc(documentRef)
          if (updatedChannel.exists()) {
            setCurrChannel({
              id: updatedChannel.id,
              fields: { ...updatedChannel.data() },
            })
            onClose()
          }
        })
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  return (
    <form
      className="bg-white py-[24px] px-[16px] flex flex-col gap-[16px]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col gap-[8px]">
        <label className="font-medium text-black text-base">
          Community Name
        </label>
        <CustomInput
          placeholder="Enter Community name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        // error={formError?.title}
        />
      </div>
      <GradientButton type="submit" title="Save" />
    </form>
  )
}

export default EditCommunityChanel