import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb'
import { useSelector } from "react-redux";
import CustomInput from '../../../../components/CustomInput/CustomInput';
import GradientButton from '../../../../components/GradientButton/GradientButton';
import instagramIcon from "../../../../assets/instagramIcon.svg"
import facebookIcon from "../../../../assets/facebookIcon.svg"
import spotifyIcon from "../../../../assets/spotifyIcon.svg"
import soundCloudIcon from "../../../../assets/soundCloudIcon.svg"
import galleryIcon from "../../../../assets/galleryIcon.svg"
import closeIcon from "../../../../assets/closeIcon.svg"
import { addCollaboratorSchema } from '../../../../validations/auth';
import { Select, MenuItem } from '@mui/material';
import { formDataConverter } from '../../../../utils/helperFunctions';
import { collabReq } from '../../../../api/api_calls/music';
import CustomPhoneInput from '../../../../components/CustomPhoneInput/CustomPhoneInput';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Instagram } from '@mui/icons-material';

function AddCollaborators() {
    const { resolutionType } = useSelector((state) => state.resolution);
    const [collabLoad, setCollabLoad] = useState(false)
    const userState = useSelector((state) => state.user);
    const userData = userState?.userData
    console.log(userData)
    const [pictureProfileUrl, setPictureProfileUrl] = useState(null)
    const [formData, setFormData] = useState({
        profilePicture: "",
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        description: "",
        collabType: "",
        tellUsAboutInspirationFroMusic: "",
        favouriteAbtAeternal: "",
        additionalDescription: "",
        facebook: "",
        Instagram: "",
        spotify: "",
        soundCloud: ""
    })
    const [formError, setFormError] = useState({});
    const navigate = useNavigate()
    const handleChange = (e) => {
        const { name, value } = e?.target
        setFormData({ ...formData, [name]: value })
    }
    const handleAddCollaboratorSubmit = (e) => {
        e.preventDefault()
        const { error } = addCollaboratorSchema.validate({
            firstName: formData.firstName, lastName: formData.lastName, email: formData.email, mobileNumber: formData.mobileNumber, collabType: formData.collabType, Instagram: formData.Instagram, facebook: formData.facebook, spotify: formData.spotify, soundCloud: formData.soundCloud
        })
        if (error) {
            setFormError({ [error?.details?.[0]?.context?.key]: error?.message })
            return
        }
        if (!pictureProfileUrl && !error) {
            toast.error("Please Select Profile Picture")
            return
        }
        setCollabLoad(true);

        const { mobileNumber: phone, ...rest } = formData
        const country_code = phone?.slice(0, 1);
        const mobileNumber = phone?.slice(1)
        collabReq(formDataConverter({ ...rest, country_code, mobileNumber }))
            .then((res) => {
                toast.success(res?.data?.message, { theme: "dark" });
                navigate("/collaborators")
            })
            .catch((err) => {
                toast.error(err?.message, { theme: "dark" });
            })
            .finally(() => {
                setCollabLoad(false);
            });
    }
    const handleSelectImage = (e) => {
        if (e.target.files[0]) {
            const maxSize = 200 * 1024;
            const file = e.target.files[0];
            if (file.size <= maxSize) {
                setPictureProfileUrl(URL.createObjectURL(file))
                setFormData({ ...formData, "profilePicture": file })
                e.target.value = null
            } else {
                setPictureProfileUrl(null);
                setFormData({ ...formData, "profilePicture": "" })
                toast.error("Profile Picture must be less than 200kb")
                e.target.value = null
                return;
            }
        }
    }
    useEffect(() => {
        setFormData({ ...formData, firstName: userData?.firstName, lastName: userData?.lastName, email: userData?.email })
    }, [userData])
    console.log(formError)
    return (
        <>
            <BreadCrumb />
            <div
                className={`w-full flex flex-col gap-6 bg-white  ${resolutionType.type === "mobile" ? "px-10 py-2" : "px-60 py-16"
                    }`}
            >
                <p className='text-base md:text-xl lg:text-3xl font-bold'>Delighted to partner with you! Initiating collaboration is a breeze –simply complete a form to connect with us effortlessly.</p>
                <hr className="w-full h-[1px] bg-[#E4E4E4] rder-0" />
                <label className='text-lg font-semibold text-[#E6355C]'>Basic Details</label>
                {!pictureProfileUrl ? <><input type='file' style={{ display: "none" }} id='uploadImage' onChange={handleSelectImage}
                    accept=".jpg, .jpeg, .png"
                />
                    <label htmlFor='uploadImage' className='w-[90%] md:w-[35%] lg:w-[25%]'>
                        <div type='button' className='border rounded-lg text-sm border-dashed bg-[#EAEFFC] p-3 border-[#6964B4] text-[#6964B4] md:text-base font-normal flex gap-1 cursor-pointer 2xl:text-lg'>
                            <img src={galleryIcon} alt='galleryIcon' />
                            Upload your Images</div>
                    </label></> : <>
                    <div className="relative w-[20%]">
                        <img
                            src={pictureProfileUrl}
                            alt="pictureProfile"
                            className="object-cover"
                        />
                        <img
                            src={closeIcon}
                            alt="closeIcon"
                            className="h-8 w-8 cursor-pointer absolute top-[-10px] right-[-10px]"
                            onClick={() => {
                                setPictureProfileUrl(null);
                                setFormData({ ...formData, "profilePicture": "" })
                            }}
                        />
                    </div>

                </>
                }
                <form className='flex flex-col gap-6' onSubmit={handleAddCollaboratorSubmit}>
                    <div className='flex flex-col gap-2 md:flex-row'>
                        <div className='flex flex-col gap-6  md:w-[50%]'>
                            <label className="font-medium text-black text-base">First Name</label>
                            <CustomInput
                                name="firstName"
                                placeholder="Enter First Name"
                                type="text"
                                value={formData?.firstName}
                                disabled={true}
                                onChange={handleChange}
                                error={formError?.firstName}
                            />
                        </div>
                        <div className='flex flex-col gap-6 md:w-[50%]'>
                            <label className="font-medium text-black text-base">Last Name</label>
                            <CustomInput
                                name="lastName"
                                placeholder="Enter Last Name"
                                type="text"
                                value={formData?.lastName}
                                disabled={true}
                                onChange={handleChange}
                                error={formError?.lastName}
                            />
                        </div>
                    </div>
                    <label className="font-medium text-black text-base">Email Address</label>
                    <CustomInput
                        name="email"
                        type="email"
                        placeholder="Enter Email address"
                        disabled={true}
                        value={formData?.email}
                        onChange={handleChange}
                        error={formError?.email}
                    />
                    <label className="font-medium text-black text-base">Phone Number</label>
                    <CustomPhoneInput
                        name="mobileNumber"
                        value={formData?.mobileNumber}
                        onChange={(e) =>
                            setFormData((curr) => ({ ...curr, mobileNumber: e }))
                        }
                        error={formError?.mobileNumber}
                    />
                    <label className="font-medium text-black text-base">Collab Type</label>
                    <Select
                        id="demo-select-small"
                        name='collabType'
                        className='!outline-none !border-[#E4E4E4]'
                        sx={{
                            fontWeight: "400",
                            fontSize: "18px",
                            borderRadius: "8px",
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #E4E4E4"
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #E4E4E4"
                            },
                        }}
                        onChange={handleChange}
                        displayEmpty
                        renderValue={(value) => value || "Select Collab Type"}
                    >
                        <MenuItem value="general">General</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {formError?.collabType && (
                        <p className="text-red-400 mt-2 font-normal text-sm">{formError?.collabType}</p>
                    )}
                    <label className='text-lg font-semibold text-[#E6355C]'>Tell about yourself</label>
                    <label className="font-medium text-black text-base">Describe your style of music.</label>
                    <textarea
                        className={`h-[172px] rounded-lg border-[1px] border-[#E4E4E4] outline-none resize-none py-4 px-2`}
                        name="description"
                        placeholder="Type Here...."
                        onChange={handleChange}
                        value={formData?.description}
                        maxLength={1000}
                    />
                    {/* {formError?.description && (
                        <p className="text-red-400 mt-2 font-normal text-sm">{formError?.description}</p>
                    )} */}
                    <label className="font-medium text-black text-base">Tell us about your inspiration in making music. What inspires you?</label>
                    <textarea
                        className={`h-[172px] rounded-lg border-[1px] border-[#E4E4E4] outline-none resize-none py-4 px-2`}
                        name="tellUsAboutInspirationFroMusic"
                        placeholder="Type Here...."
                        onChange={handleChange}
                        value={formData?.tellUsAboutInspirationFroMusic}
                        maxLength={1000}
                    />
                    <label className="font-medium text-black text-base">What is your favorite Aeternal Lovers design or track. Why?</label>
                    <textarea
                        className={`h-[172px] rounded-lg border-[1px] border-[#E4E4E4] outline-none resize-none py-4 px-2`}
                        name="favouriteAbtAeternal"
                        placeholder="Type Here...."
                        onChange={handleChange}
                        value={formData?.favouriteAbtAeternal}
                        maxLength={1000}
                    />
                    {/* <CustomInput
                        name="phoneNumber"
                        placeholder="Paste the design link Here."
                    />
                    <CustomInput
                        name="phoneNumber"
                        placeholder="Paste the link to your favourite track here"
                    /> */}
                    <label className="font-medium text-black text-base">We would be delighted to hear about anything else you would like to share with us.</label>
                    <textarea
                        className={`h-[172px] rounded-lg border-[1px] border-[#E4E4E4] outline-none resize-none py-4 px-2`}
                        name="additionalDescription"
                        placeholder="Type Here...."
                        value={formData?.additionalDescription}
                        onChange={handleChange}
                        maxLength={1000}
                    />
                    <label className="font-medium text-black text-base">Connect your social media accounts so that people can learn more about your work.</label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
                            <img src={instagramIcon} alt='instagramIcon' />
                        </div>
                        <input
                            name="Instagram"
                            placeholder="www.Instagram.com"
                            value={formData?.Instagram}
                            className="w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#E6355C] focus:!border-[#E6355C] focus:outline-none"
                            onChange={handleChange}
                        />
                    </div>
                    {formError?.Instagram && (
                        <p className="text-red-400 mt-2 font-normal text-sm">{formError?.Instagram}</p>
                    )}
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
                            <img src={facebookIcon} alt='instagramIcon' />
                        </div>
                        <input
                            name="facebook"
                            placeholder="www.Facebook.Com"
                            value={formData?.facebook}
                            onChange={handleChange}
                            className=" w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#1877F2] focus:!border-[#1877F2] focus:outline-none"
                        />
                    </div>
                    {formError?.facebook && (
                        <p className="text-red-400 mt-2 font-normal text-sm">{formError?.facebook}</p>
                    )}
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
                            <img src={spotifyIcon} alt='instagramIcon' />
                        </div>
                        <input
                            name="spotify"
                            placeholder="www.Spotify.Com"
                            onChange={handleChange}
                            value={formData?.spotify}
                            className=" w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#0FA845] focus:!border-[#0FA845] focus:outline-none"
                        />
                    </div>
                    {formError?.spotify && (
                        <p className="text-red-400 mt-2 font-normal text-sm">{formError?.spotify}</p>
                    )}
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2">
                            <img src={soundCloudIcon} alt='instagramIcon' />
                        </div>
                        <input
                            name="soundCloud"
                            placeholder="www.soundCloud.Com"
                            value={formData?.soundCloud}
                            onChange={handleChange}
                            className="w-full py-[16px] pl-12 pr-[8px] rounded-[8px] border !border-[#FF5500] focus:!border-[#FF5500] focus:outline-none"
                        />
                    </div>
                    {formError?.soundCloud && (
                        <p className="text-red-400 mt-2 font-normal text-sm">{formError?.soundCloud}</p>
                    )}
                    <p classNam e='text-lg font-medium ' >Thank you for submitting the Collab form. Your information is important to us.Our team will carefully review it, and the process may take up to 24 hours. We appreciate your patience.</p>
                    <GradientButton
                        title="Submit Form"
                        className="text-xl w-[25%]"
                        type="submit"
                        loading={collabLoad}
                    />
                </form>
            </div >
        </>
    )
}

export default AddCollaborators
