import { React, useEffect, useRef, useState } from "react";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import styles from "./UploadMusic.module.css";
import {
	initialiseMultipart,
	finaliseMultipart,
} from "../../../../api/api_calls/music";
import {
	CircularProgress,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import {
	extractYouTubeVideoId,
	isValidYouTubeLink,
	getYouTubeVideoDuration,
	getYouTubeThumbnailFile,
	parseYouTubeDuration,
} from "../../../../utils/helperFunctions";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import { toast } from "react-toastify";
import axios from "axios";

const UploadMusic = ({ setCollabReqModal, setUploadedModal, setRender }) => {
	const audioInputRef = useRef();
	const imgInputRef = useRef();
	const videoInputRef = useRef();

	const [title, setTitle] = useState("");
	const [titleError, setTitleError] = useState(null);
	const [desc, setDesc] = useState("");
	const [descError, setDescError] = useState(null);
	const [playTime, setPlayTime] = useState(0);
	const [videoTime, setVideoTime] = useState(0);
	const [ytTime, setYTTime] = useState(0);

	const [ytType, setytType] = useState(false);
	const [ytURL, setYTURL] = useState("");
	const [ytError, setYTError] = useState(null);

	const [draggingAudio, setDraggingAudio] = useState(false);
	const [draggingImg, setDraggingImg] = useState(false);
	const [draggingVid, setDraggingVid] = useState(false);

	const [imgFile, setImgFile] = useState(null);
	const [imgError, setImgError] = useState(null);
	const [imgLocal, setImgLocal] = useState("");
	const [audioFile, setAudioFile] = useState(null);
	const [audioError, setAudioError] = useState(null);
	const [audioLocal, setAudioLocal] = useState("");
	const [audioLoad, setAudioLoad] = useState(false);

	// const [vidBool, setVidBool] = useState(false);
	const [vidFile, setVidFile] = useState(null);
	const [vidError, setVidError] = useState(null);
	const [vidLocal, setVidLocal] = useState("");
	const [videoLoad, setVideoLoad] = useState(false);
	const [videoThumbnailFile, setVideoThumbnailFile] = useState(null);
	const [videoThumbnail, setVideoThumbnail] = useState("");
	const [chunksUploaded, setChunksUploaded] = useState(0);
	const [uploadVideo, setUploadVideo] = useState(false);

	const [submitLoad, setSubmitLoad] = useState(false);

	const getPlayTime = async (file) => {
		// won't need this if i simply set duration state when loading audio file in DOM

		try {
			const reader = new FileReader();

			const blobUrl = await new Promise((resolve, reject) => {
				reader.onload = (event) => resolve(event.target.result);
				reader.onerror = (error) => reject(error);
				reader.readAsDataURL(file);
			});

			const duration = await new Promise((resolve, reject) => {
				const au = document.createElement("audio");

				au.addEventListener("loadedmetadata", () => {
					resolve(au.duration);
				});

				au.onerror = (error) => reject(error);

				au.src = blobUrl;
			});

			setPlayTime(duration);
		} catch (error) {
			throw error;
		}
	};

	const getVideoPlayTime = async (file) => {
		// find where it is best to revoke URL
		// console.log("file size: ", file.size);

		try {
			const fileReader = new FileReader();
			const video = document.createElement("video");

			const blobUrl = await new Promise((resolve, reject) => {
				fileReader.onload = (event) => {
					resolve(URL.createObjectURL(new Blob([event.target.result])));
				};
				fileReader.onerror = (error) => reject(error);
				fileReader.readAsArrayBuffer(file);
			});

			const duration = await new Promise((resolve, reject) => {
				video.addEventListener("loadedmetadata", () => {
					resolve(video.duration);
				});
				video.onerror = (error) => reject(error);
				video.src = blobUrl;
			});

			setVideoTime(duration);

			const randomTime = Math.random() * duration;
			video.currentTime = randomTime;

			await new Promise((resolve, reject) => {
				video.addEventListener("seeked", () => {
					const canvas = document.createElement("canvas");
					canvas.width = video.videoWidth;
					canvas.height = video.videoHeight;
					canvas
						.getContext("2d")
						.drawImage(video, 0, 0, canvas.width, canvas.height);

					// Convert canvas to blob
					canvas.toBlob((blob) => {
						const thumbnailFile = new File([blob], "thumbnail.png", {
							type: "image/png",
						});
						const thumbnailDataUrl = URL.createObjectURL(thumbnailFile);

						// Set the thumbnail file and URL to state
						setVideoThumbnailFile(thumbnailFile);
						setVideoThumbnail(thumbnailDataUrl);
						// setVidError("");
						resolve();
					}, "image/png");

					// Clean up by revoking the object URL
					URL.revokeObjectURL(video.src);
				});

				video.onerror = (error) => reject(error);
			});
		} catch (error) {
			throw error;
		}
	};

	async function uploadPart(fileChunk, presignedUrl, partNo) {
		const uploadResponse = await fetch(presignedUrl, {
			method: "PUT",
			body: fileChunk,
		});
		setChunksUploaded((curr) => curr + 1);
		return {
			ETag: uploadResponse.headers.get("ETag") || "",
			PartNumber: partNo,
		};
	}

	const errorFunc = (setState, msg, time) => {
		setState(msg);
		setTimeout(() => {
			setState(null);
		}, time);
	};

	const submitValidation = async () => {
		let temp = true;
		if (title === "") {
			errorFunc(setTitleError, "Mandatory field", 3000);
			temp = false;
		} else if (title.length > 100) {
			errorFunc(setTitleError, "Title too long", 3000);
			temp = false;
		}
		if (desc === "") {
			errorFunc(setDescError, "Mandatory field", 3000);
			temp = false;
		} else if (desc.length > 200) {
			errorFunc(setDescError, "Description too long", 3000);
			temp = false;
		}

		if (ytType) {
			if (ytURL === "") {
				errorFunc(setYTError, "Mandatory field", 3000);
				temp = false;
			} else if (extractYouTubeVideoId(ytURL) === null) {
				errorFunc(setYTError, "Please provide a valid YouTube URL.", 5000);
				temp = false;
			} else {
				try {
					await getYouTubeVideoDuration(extractYouTubeVideoId(ytURL));
				} catch {
					setYTTime(0);
					errorFunc(
						setYTError,
						"YouTube video of this ID does not exist.",
						5000
					);
					temp = false;
				}
				// console.log("TEMP AFTER GET YOUTUBE DURATION: ", temp);
			}
		} else {
			if (!imgFile) {
				errorFunc(setImgError, "Mandatory field", 3000);
				temp = false;
			}

			if (!audioFile && !vidFile) {
				errorFunc(setVidError, "Please upload a video file.", 5000);
				errorFunc(setAudioError, "Please upload an audio file.", 5000);
				temp = false;
			}

			if (vidFile) {
				// if (vidFile.size < 10485760) {
				// 	errorFunc(setVidError, "Must be at least 10 MB", 3000);
				// 	temp = false;
				// }
				if (vidFile.size > 838860800) {
					errorFunc(setVidError, "Must be less than 800 MB", 3000);
					temp = false;
				}
			}
		}
		return temp;
	};

	const submitFunction = async (e) => {
		e.preventDefault();
		const temp = await submitValidation();

		// console.log("YTTIME in SUBMIT: ", ytTime);

		// return;
		if (!temp) return;
		setSubmitLoad(true);

		// if youtube, ignore everything else
		// if !youtube prepare for video and make isVideoOnly true
		// then, if audio, make isVideoOnly false

		const newFileName = vidFile?.name?.replace(/\s+/g, "");
		const renamedFile = vidFile
			? new File([vidFile], newFileName, {
					type: vidFile?.type,
			  })
			: null;

		// parts for multipart
		let parts = 0,
			chunkSize = 0,
			vidSize = 0;
		if (renamedFile) {
			vidSize = renamedFile?.size;
			parts = Math.min(Math.ceil(renamedFile.size / 8000000), 20);
			chunkSize = renamedFile.size / parts;
		}

		let intialized = null;

		try {
			if (renamedFile && renamedFile?.size > 10485760) {
				intialized = await initialiseMultipart({
					name: renamedFile?.name,
					parts,
					videoSize: vidSize,
				});
			}

			// finalize multipart
			// populate formData with details other than the video multipart
			const formData = new FormData();
			formData.append("title", title);
			formData.append("description", desc);
			formData.append("isYoutube", ytType);
			if (ytType) {
				formData.append("isVideoExist", false);
				formData.append("youtubeUrl", ytURL);
				const tempID = extractYouTubeVideoId(ytURL);
				formData.append("youtubeId", tempID);
				// const playTemp = await getYouTubeVideoDuration(tempID);
				const playTemp = await getYouTubeVideoDuration(tempID);
				// console.log("YTTIME: ", ytTime);
				formData.append("playTime", parseYouTubeDuration(playTemp));
				formData.append("youtubeThumbnail", null);
			} else {
				// mandatory fields
				formData.append("musicThumbnail", imgFile);

				if (renamedFile) {
					formData.append("isVideoExist", true);
					formData.append("videoThumbnail", videoThumbnailFile);
					formData.append("name", renamedFile?.name);
					if (renamedFile?.size < 10485760)
						formData.append("video", renamedFile);
				} else {
					formData.append("isVideoExist", false);
				}

				if (audioFile) {
					formData.append("isVideoOnly", false);
					formData.append("music", audioFile);
					formData.append("playTime", playTime);
				} else {
					formData.append("isVideoOnly", true);
					formData.append("playTime", videoTime);
				}

				// formData.append("musicThumbnail", imgFile);
				// formData.append("playTime", playTime);
				// formData.append("music", audioFile);
				// if (renamedFile) {
				// 	formData.append("isVideoExist", "true");
				// 	formData.append("videoThumbnail", videoThumbnailFile);
				// 	formData.append("name", renamedFile?.name);
				// } else {
				// 	formData.append("isVideoExist", "false");
				// 	// formData.append("youtubeId", "false");
				// }
			}

			// link chunks with provided URLs and upload to finalizeMultipart
			const uploadsArray = [];
			if (intialized) {
				if (renamedFile) {
					for (let i = 0; i < parts; i++) {
						const start = i * chunkSize;
						const end = Math.min(start + chunkSize, renamedFile?.size);
						const blob = renamedFile?.slice(start, end);
						uploadsArray.push(
							uploadPart(blob, intialized?.data?.parts?.[i]?.signedUrl, i + 1)
						);
					}
				}
			}

			let uploadResponses = await Promise.all(uploadsArray);
			if (intialized) {
				// console.log("UPLOAD RESPONSES", uploadResponses);
				formData.append("fileId", intialized?.data?.fileId);
				formData.append("fileKey", intialized?.data?.fileKey);

				formData.append("parts", JSON.stringify(uploadResponses));
			}

			// for (let entry of formData.entries()) {
			// 	console.log(entry[0] + ": " + entry[1]);
			// }
			// console.log(
			// 	"----------------------------------------------------------------------------"
			// );
			// debugger;

			await finaliseMultipart(formData);
			setUploadedModal(true);
			setRender(true);
		} catch (error) {
			toast.error(error?.message);
		} finally {
			setSubmitLoad(false);
			setCollabReqModal(false);
		}
	};

	const handleDragOver = (e, type) => {
		e.preventDefault();
		if (type === "audioFile") setDraggingAudio(true);
		else if (type === "imgFile") setDraggingImg(true);
		else if (type === "videoFile") setDraggingVid(true);
	};

	const handleDragLeave = (type) => {
		if (type === "audioFile") setDraggingAudio(false);
		else if (type === "imgFile") setDraggingImg(false);
		else if (type === "videoFile") setDraggingVid(false);
	};

	const handleDrop = (e, type) => {
		e.preventDefault();

		if (type === "audioFile") setDraggingAudio(false);
		else if (type === "imgFile") setDraggingImg(false);
		else if (type === "videoFile") setDraggingVid(false);
		const file = e.dataTransfer.files[0];
		handleFileUpload(file, type);
	};

	const handleFileUpload = (file, fileType) => {
		// console.log(`TRYING TO Upload ${fileType} file:`, file);

		if (fileType === "videoFile" && !file.type.includes("video/")) {
			errorFunc(setVidError, "Please upload a video file", 5000);

			return;
		} else if (fileType === "audioFile" && !file.type.includes("audio/")) {
			errorFunc(setAudioError, "Please upload an audio file", 5000);

			return;
		} else if (fileType === "imgFile" && !file.type.includes("image/")) {
			errorFunc(setImgError, "Please upload an image file", 5000);

			return;
		} else {
			// Handle file upload logic here
			// console.log("OBJECT FILE: ", file);
			const temp = URL.createObjectURL(file);
			// console.log(`OBJECT URL: `, temp);

			// Determine which state variable to update based on fileType
			if (fileType === "audioFile") {
				// setAudioFile(null);
				// setAudioLocal("");
				setAudioLoad(true);
				getPlayTime(file)
					.then(() => {
						setAudioFile(file);
						setAudioLocal(temp);
					})
					.catch(() => {
						errorFunc(
							setAudioError,
							"Error with audio file. Please try again.",
							5000
						);
					})
					.finally(() => {
						setAudioLoad(false);
					});
				// setAudioError("");
			} else if (fileType === "videoFile") {
				setVideoLoad(true);
				getVideoPlayTime(file)
					.then(() => {
						setVidFile(file);
						setVidLocal(temp);
					})
					.catch(() => {
						errorFunc(
							setVidError,
							"Error with video file. Please try again.",
							5000
						);
					})
					.finally(() => {
						setVideoLoad(false);
					});
				// generateVideoThumbnail(file);
			} else if (fileType === "imgFile") {
				setImgFile(file);
				setImgLocal(temp);
				setImgError(null);
			}
		}
	};

	return (
		<form onSubmit={submitFunction}>
			<div className="flex flex-col gap-y-4">
				<div className="flex flex-col gap-y-2">
					<span className="block text-base font-medium">
						Title{titleError ? "*" : ""}
					</span>
					<input
						placeholder="Enter the title of this track"
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
						className={`w-full rounded-lg border border-[#E4E4E4] focus:border-[black] p-2 ${
							titleError && styles.error
						}`}
					/>
					{titleError && (
						<span
							className="block text-xs font-medium"
							style={{ color: "#ff0037" }}
						>
							{titleError}
						</span>
					)}
					{/* <CustomInput value={title} onChange={(e) => setTitle(e.target.value)} /> */}
				</div>

				<div className="flex flex-col gap-y-2">
					<span className="block text-base font-medium">
						Description{descError ? "*" : ""}
					</span>
					<textArea
						rows={4}
						placeholder="Type here"
						maxLength={1000}
						className={`${styles.textArea} ${descError && styles.error}`}
						value={desc}
						onChange={(e) => setDesc(e.target.value)}
					/>
					{descError && (
						<span
							className="block text-xs font-medium"
							style={{ color: "#ff0037" }}
						>
							{descError}
						</span>
					)}
					{/* <CustomInput value={desc} onChange={(e) => setDesc(e.target.value)} /> */}
				</div>

				<div className="w-full flex justify-center">
					<ToggleButtonGroup
						exclusive
						value={ytType}
						onChange={(e, newVal) => setytType(newVal)}
					>
						<ToggleButton value={false}>Local Media</ToggleButton>
						<ToggleButton value={true}>YouTube</ToggleButton>
					</ToggleButtonGroup>
				</div>

				{ytType ? (
					<>
						<span className="block text-base font-medium">
							YouTube URL{ytError ? "*" : ""}
						</span>
						<input
							placeholder="Enter YouTube URL"
							value={ytURL}
							onChange={(e) => setYTURL(e.target.value)}
							className={`w-full rounded-lg border border-[#E4E4E4] focus:border-[black] p-2 ${
								ytError && styles.error
							}`}
						/>
						{ytError && (
							<span
								className="block text-xs font-medium"
								style={{ color: "#ff0037" }}
							>
								{ytError}
							</span>
						)}
					</>
				) : (
					<>
						<div className="w-full flex items-center">
							<p className="text-base font-medium">
								Please upload an audio file, a video file, or both.
							</p>
						</div>

						<div className="flex gap-x-2">
							<div
								className={`${styles.file_uploader} ${
									draggingAudio ? styles.dragging : ""
								}`}
								onDragOver={(e) => handleDragOver(e, "audioFile")}
								onDragLeave={() => handleDragLeave("audioFile")}
								onDrop={(e) => handleDrop(e, "audioFile")}
								onClick={() => audioInputRef.current.click()}
							>
								<input
									type="file"
									accept=".mp3, .wav"
									style={{ display: "none" }}
									ref={audioInputRef}
									onChange={(e) => {
										handleFileUpload(e.target.files[0], "audioFile");
										e.target.value = "";
									}}
								/>
								<div className="h-full w-full flex flex-col justify-center items-center">
									{audioLoad ? (
										<CircularProgress />
									) : (
										<>
											<svg
												width="25"
												height="24"
												viewBox="0 0 25 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
													fill="#E6355C"
												/>
												<path
													d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
													fill="#E6355C"
												/>
											</svg>

											<p className="text-xs font-medium text-center">
												{audioFile
													? `${audioFile.name} | ${playTime}`
													: draggingAudio
													? "Drop Here"
													: `Drag or Upload your Audio${audioError ? "*" : ""}`}
											</p>
										</>
									)}
								</div>
								{audioError && (
									<span
										className="block text-xs font-medium"
										style={{ color: "#ff0037" }}
									>
										{audioError}
									</span>
								)}
							</div>
							<div
								className={`${styles.file_uploader} ${
									draggingImg ? styles.dragging : ""
								}`}
								onDragOver={(e) => handleDragOver(e, "imgFile")}
								onDragLeave={() => handleDragLeave("imgFile")}
								onDrop={(e) => handleDrop(e, "imgFile")}
								onClick={() => imgInputRef.current.click()}
							>
								<input
									type="file"
									accept=".jpg, .jpeg, .png"
									style={{ display: "none" }}
									ref={imgInputRef}
									onChange={(e) => {
										handleFileUpload(e.target.files[0], "imgFile");
										e.target.value = "";
									}}
								/>
								<div className="h-full w-full flex flex-col justify-center items-center">
									{imgFile ? (
										<div className="h-full w-full flex justify-center items-center p-4">
											<img
												src={imgLocal}
												style={{
													maxWidth: "100%",
													height: "100%",
													objectFit: "contain",
												}}
												alt="imgLocal"
											/>
										</div>
									) : (
										<>
											<svg
												width="25"
												height="24"
												viewBox="0 0 25 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
													fill="#E6355C"
												/>
												<path
													d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
													fill="#E6355C"
												/>
											</svg>

											<p className="text-xs font-medium text-center">
												{draggingImg
													? "Drop Here"
													: `Drag or Upload your Thumbnail Image${
															imgError ? "*" : ""
													  }`}
											</p>
										</>
									)}
								</div>
								{imgError && (
									<span
										className="block text-xs font-medium"
										style={{ color: "#ff0037" }}
									>
										{imgError}
									</span>
								)}
							</div>
						</div>
						{/* <div className="w-full flex gap-x-2 items-center">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{ cursor: "pointer" }}
								onClick={() => setVidBool((curr) => !curr)}
							>
								{vidBool ? (
									<>
										<rect
											x="0.75"
											y="0.75"
											width="14.5"
											height="14.5"
											rx="1.25"
											fill="#E6355C"
											stroke="#E6355C"
											stroke-width="1.5"
										/>
										<path
											d="M3 7.86364L5.86364 10.7273L13.5 5"
											stroke="white"
											stroke-width="1.90909"
											stroke-linecap="round"
										/>
									</>
								) : (
									<>
										<rect
											x="0.75"
											y="0.75"
											width="14.5"
											height="14.5"
											rx="1.25"
											stroke="#060709"
											stroke-width="1.5"
										/>
									</>
								)}
							</svg>
							<p className="text-base font-medium">
								Want to Upload a video with this track?
							</p>
						</div> */}
						{/* {vidBool ? (
							<div
								className={`${styles.file_uploader} ${
									draggingVid ? styles.dragging : ""
								}`}
								onDragOver={(e) => handleDragOver(e, "videoFile")}
								onDragLeave={() => handleDragLeave("videoFile")}
								onDrop={(e) => handleDrop(e, "videoFile")}
								onClick={() => videoInputRef.current.click()}
							>
								<input
									type="file"
									accept=".mp4, .avi, .mov"
									style={{ display: "none" }}
									ref={videoInputRef}
									onChange={(e) => {
										handleFileUpload(e.target.files[0], "videoFile");
										e.target.value = "";
									}}
								/>
								<div className="h-full w-full flex flex-col justify-center items-center">
									{vidFile ? (
										<div className="w-full h-full flex flex-col gap-y-2 p-4 justify-center items-center">
											{videoThumbnail ? (
												<img
													src={videoThumbnail}
													style={{
														maxHeight: "100%",
														objectFit: "contain",
													}}
												/>
											) : (
												<CircularProgress />
											)}
											<p className="text-xs font-medium text-center">
												{vidFile.name} | {videoTime}
											</p>
										</div>
									) : (
										<>
											<svg
												width="25"
												height="24"
												viewBox="0 0 25 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
													fill="#E6355C"
												/>
												<path
													d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
													fill="#E6355C"
												/>
											</svg>
											<p className="text-xs font-medium text-center">
												{draggingVid
													? "Drop Here"
													: `Drag or Upload your Video${
															vidError !== "" ? "*" : ""
													}`}
											</p>
										</>
									)}
								</div>
								{vidError !== 0 && (
									<span
										className="block text-xs font-medium"
										style={{ color: "#ff0037" }}
									>
										{vidError}
									</span>
								)}
							</div>
						) : (
							<></>
						)} */}
						<div
							className={`${styles.file_uploader} ${
								draggingVid ? styles.dragging : ""
							}`}
							onDragOver={(e) => handleDragOver(e, "videoFile")}
							onDragLeave={() => handleDragLeave("videoFile")}
							onDrop={(e) => handleDrop(e, "videoFile")}
							onClick={() => videoInputRef.current.click()}
						>
							<input
								type="file"
								accept=".mp4, .avi, .mov"
								style={{ display: "none" }}
								ref={videoInputRef}
								onChange={(e) => {
									handleFileUpload(e.target.files[0], "videoFile");
									e.target.value = "";
								}}
							/>
							<div className="h-full w-full flex flex-col justify-center items-center">
								{videoLoad ? (
									<CircularProgress />
								) : (
									<>
										{vidFile ? (
											<div className="w-full h-full flex flex-col gap-y-2 p-4 justify-center items-center">
												<img
													src={videoThumbnail}
													style={{
														maxHeight: "100%",
														objectFit: "contain",
													}}
												/>
												<p className="text-xs font-medium text-center">
													{vidFile.name} | {videoTime}
												</p>
											</div>
										) : (
											<>
												<svg
													width="25"
													height="24"
													viewBox="0 0 25 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
														fill="#E6355C"
													/>
													<path
														d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
														fill="#E6355C"
													/>
												</svg>
												<p className="text-xs font-medium text-center">
													{draggingVid
														? "Drop Here"
														: `Drag or Upload your Video${vidError ? "*" : ""}`}
												</p>
											</>
										)}
									</>
								)}

								{/* {vidFile ? (
									<div className="w-full h-full flex flex-col gap-y-2 p-4 justify-center items-center">
										{!videoLoad ? (
											<img
												src={videoThumbnail}
												style={{
													maxHeight: "100%",
													objectFit: "contain",
												}}
											/>
										) : (
											<CircularProgress />
										)}
										<p className="text-xs font-medium text-center">
											{vidFile.name} | {videoTime}
										</p>
									</div>
								) : (
									<>
										<svg
											width="25"
											height="24"
											viewBox="0 0 25 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M17.55 9H13.5V15.25C13.5 15.66 13.16 16 12.75 16C12.34 16 12 15.66 12 15.25V9H7.95C4.75 9 2.75 11 2.75 14.2V16.79C2.75 20 4.75 22 7.95 22H17.54C20.74 22 22.74 20 22.74 16.8V14.2C22.75 11 20.75 9 17.55 9Z"
												fill="#E6355C"
											/>
											<path
												d="M13.4998 4.56043L15.5698 6.63043C15.7198 6.78043 15.9098 6.85043 16.0998 6.85043C16.2898 6.85043 16.4798 6.78043 16.6298 6.63043C16.9198 6.34043 16.9198 5.86043 16.6298 5.57043L13.2798 2.22043C12.9898 1.93043 12.5098 1.93043 12.2198 2.22043L8.86984 5.57043C8.57984 5.86043 8.57984 6.34043 8.86984 6.63043C9.15984 6.92043 9.63984 6.92043 9.92984 6.63043L11.9998 4.56043V9.00043H13.4998V4.56043Z"
												fill="#E6355C"
											/>
										</svg>
										<p className="text-xs font-medium text-center">
											{draggingVid
												? "Drop Here"
												: `Drag or Upload your Video${
														vidError !== "" ? "*" : ""
												  }`}
										</p>
									</>
								)} */}
							</div>
							{vidError && (
								<span
									className="block text-xs font-medium mb-1"
									style={{ color: "#ff0037" }}
								>
									{vidError}
								</span>
							)}
						</div>
					</>
				)}
				<GradientButton
					title="Upload Music"
					onClick={submitFunction}
					loading={submitLoad}
					type="submit"
				/>
			</div>
		</form>
	);
};

export default UploadMusic;
