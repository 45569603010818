import React from "react";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import EastIcon from "@mui/icons-material/East";
import { motion } from "framer-motion";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProductsSkeleton } from "../../../../skeletons/skeleton";

// const products = [
//   {
//     id: 1,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
//   {
//     id: 2,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
//   {
//     id: 3,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
//   {
//     id: 4,
//     productName: "Aeternal T Shirt",
//     image:
//       "https://media.istockphoto.com/id/1789444175/photo/question-mark-sign-support-and-answers.jpg?s=2048x2048&w=is&k=20&c=X7oHy3YAWzHNB2x0nz9Y_8JV7NsoYgCmzAu6iWUr-w8=",
//     price: 20,
//   },
// ];

const boxVariant = {
	hidden: {},
	visible: {
		transition: {
			when: "beforeChildren", //use this instead of delay
			staggerChildren: 0.05, //apply stagger on the parent tag
		},
	},
};

const listVariant = {
	hidden: {
		scale: 0.5, //move out of the site
		opacity: 0,
	},
	visible: {
		scale: 1, // bring it back to nrmal
		opacity: 1,
	},
};

const TopSellers = ({ products }) => {
	const [artTypes, landingPageProducts] = useOutletContext();

	const navigate = useNavigate();
	const loader = useSelector((state) => state.loader);
	const { resolutionType } = useSelector((state) => state.resolution);

	return (
		<>
			{resolutionType.type === "mobile" ? (
				<div className="flex flex-col w-full">
					{/*taking full width... control this component from the outside using a parent component*/}
					<div className="w-full flex flex-col mb-4">
						<h3 className="font-bold text-[#060709] text-[48px] mb-2">
							Top Sellers
						</h3>
						<GradientButton
							title="View All"
							icon={<EastIcon />}
							onClick={() =>
								navigate("/shop", {
									state: {
										artType: artTypes?.[0]?._id,
										artName: artTypes?.[0]?.artTypeName,
									},
								})
							}
						/>
					</div>
					<motion.ul
						className="flex gap-[18px] overflow-x-auto"
						variants={boxVariant}
						animate="visible"
						initial="hidden"
					>
						{
							products?.map((product) => (
								<motion.li
									key={product?._id}
									value={product._id}
									className="flex flex-col gap-[16px] cursor-pointer"
									variants={listVariant}
									onClick={() =>
										navigate("/product-details", {
											state: { productId: product?._id },
										})
									}
								>
									<div className="h-[381px] rounded-[8px] w-[345px] overflow-hidden">
										<img
											src={product?.productImages[0]}
											className="h-full w-full object-cover unloaded-img"
											alt="productImage"
										/>
									</div>
									<div className="flex justify-between items-center">
										<div className="flex flex-col gap-[16px]">
											<p className="text-xl font-medium text-[#060709]">
												{product?.productName}
											</p>
											<p className="text-xl font-bold text-[#060709]">
												${product?.basePrice}
											</p>
										</div>
									</div>
								</motion.li>
							))
						}
					</motion.ul>
				</div>
			) : (
				<div className="flex flex-col py-[64px] gap-[64px]">
					<div className="flex items-center justify-between">
						<h3 className="font-bold text-[#060709] text-[48px]">
							Top Sellers
						</h3>
						<GradientButton
							title="View All"
							icon={<EastIcon />}
							onClick={() =>
								navigate("/shop", {
									state: {
										artType: artTypes?.[0]?._id,
										artName: artTypes?.[0]?.artTypeName,
									},
								})
							}
						/>
					</div>

					<motion.ul
						className="flex flex-wrap gap-[18px]"
						variants={boxVariant}
						animate="visible"
						initial="hidden"
					>
						{
							products?.map((product) => (
								<motion.li
									key={product?._id}
									value={product._id}
									className="flex flex-col gap-[16px] cursor-pointer"
									variants={listVariant}
									onClick={() =>
										navigate("/product-details", {
											state: { productId: product?._id },
										})
									}
								>
									<div className="h-[381px] rounded-[8px] w-[345px] overflow-hidden">
										<img
											src={product?.productImages[0]}
											className="h-full w-full object-cover unloaded-img"
										/>
									</div>
									<div className="flex justify-between items-center">
										<div className="flex flex-col gap-[16px]">
											<p className="text-xl font-medium text-[#060709]">
												{product?.productName}
											</p>
											<p className="text-xl font-bold text-[#060709]">
												${product?.basePrice}
											</p>
										</div>
									</div>
								</motion.li>
							))
						}
					</motion.ul>
				</div>
			)}
		</>
	);
};

export default TopSellers;
