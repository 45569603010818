import { API } from "../api_setting/apiConstants";
import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";

export const addMediaCommunity = (data) => {
	return wrappedFetch(API.ADD_MEDIA, "POST", data);
};

export const initialiseMultipart = (data) => {
	return wrappedFetch(API.INITIALISE_MULTIPART, "POST", data);
};

export const finaliseMultipart = (data) => {
	return wrappedFetch(API.FINALISE_MULTIPART, "POST", data);
};
export const collaboratorsDetails = (data) => {
	return wrappedGet(API.COLLABORATOR_DETAILS, "GET", data)
}
export const subscribeNewsletter = (data) => {
	return wrappedFetch(API.SUBSCRIBE_NEWSLETTER, "POST", data);
};

export const getCollaborators = (body) => {
	return wrappedGet(API.GET_COLLABORATORS, "GET", body);
};

/**
 * Sends a contact us message to the server.
 *
 * @param {Object} data - The data object containing the contact information.
 * @param {string} data.firstName - The first name of the user.
 * @param {string} data.lastName - The last name of the user.
 * @param {string} data.email - The email address of the user.
 * @param {string} data.country_code - The country code of the user.
 * @param {string} data.mobileNumber - The mobile number of the user.
 * @param {string} data.message - The message content.
 * @return {Promise} A promise that resolves to the response from the server.
 */
export const contactUs = (data) => {
	return wrappedFetch(API.CONTACT_US, "POST", data);
};
