import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomInput from "../../../components/CustomInput/CustomInput";
import GradientButton from "../../../components/GradientButton/GradientButton";
import { useSelector } from "react-redux";
import { resetPasswordSchema } from "../../../validations/auth";
import { setPassword } from "../../../api/api_calls/auth";
import { toast } from "react-toastify";

const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.loader);

  const [formData, setFormData] = useState({ password: "" });
  const [formError, setFormError] = useState({});

  const id = searchParams.get("id");
  const otp = searchParams.get("otp");

  if (!id || !otp) navigate("/");

  const handleFieldChange = (e) => {
    setFormData((curr) => ({ ...curr, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      newPassword: formData?.password,
      otp,
      id,
    };

    const { error } = resetPasswordSchema.validate(data);

    if (error) {
      setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
      return;
    } else setFormError({});

    if (formData?.password !== formData?.confirmPassword) {
      setFormError({
        password: "Passwords do not match!",
        confirmPassword: "Passwords do not match!",
      });
      return;
    } else setFormError({});

    setPassword(data)
      .then((res) => {
        toast.success("Password set successfully!");
        navigate("/login");
      })
      .catch((error) => toast.error(error?.message));
  };

  return (
    <div className="w-full h-full bg-[#FFF1F4] flex items-center justify-center">
      <div className="flex flex-col md:flex-row mx-[15%] py-[5%]">
        <form
          className="w-1/2 md:w-full px-12 py-16 flex-col bg-[#FFFFFF]"
          onSubmit={handleSubmit}
        >
          <div className="font-bold text-3xl mb-1">Set Password</div>
          <div className="font-normal text-base mb-5 pr-10">
            Enter your password and confirm it below.
          </div>
          <div className="font-medium text-lg mb-2">Password</div>

          <CustomInput
            type="password"
            name="password"
            placeholder="Enter Password"
            value={formData?.password}
            onChange={handleFieldChange}
            error={formError?.newPassword}
          />
          <div className="font-medium text-lg mt-5 mb-2">Confirm Password</div>

          <CustomInput
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData?.confirmPassword}
            onChange={handleFieldChange}
            error={formError?.confirmPassword}
          />
          <GradientButton
            className="mt-5"
            type="submit"
            style={{ width: "100%", justifyContent: "center" }}
            title={"Submit"}
            loading={loading}
          />
        </form>
      </div>
    </div>
  );
};

export default Onboarding;
