import { API } from "../api_setting/apiConstants";
import { wrappedFetch, wrappedGet } from "../api_setting/urlFuncs";

export const addAddress = (formData) => {
  return wrappedFetch(API.ADD_ADDRESS, "POST", formData);
};
export const addArtType = (formData) => {
  return wrappedFetch(API.ADD_ART_TYPE, "POST", formData);
};
export const deleteArtType = (id) => {
  return wrappedFetch(`${API.DELETE_ART_TYPE}?artType=${id}`, "DELETE");
}
export const editArtType = (formData) => {
  return wrappedFetch(API.EDIT_ART_TYPE, "POST", formData);
}
export const deleteAddress = (formData) => {
  return wrappedFetch(API.DELETE_ADDRESS, "POST", formData);
};

export const editAddress = (formData) => {
  return wrappedFetch(API.EDIT_ADDRESS, "POST", formData);
};

export const editName = (formData) => {
  return wrappedFetch(API.CHANGE_NAME, "POST", formData);
};

export const changePassword = (formData) => {
  return wrappedFetch(API.CHANGE_PASSWORD, "POST", formData);
};

export const changeProfilePicture = (formData) => {
  return wrappedFetch(API.CHANGE_PROFILE_PICTURE, "POST", formData);
};

export const getAllOrders = (page, filter, pageSize) => {
  return wrappedGet(
    `${API.GET_ALL_ORDERS}?page=${page}&filter=${filter}&pageSize=${pageSize}`
  );
};

export const getArtTypeList = () => {
  return wrappedGet(
    `${API.GET_ART_TYPE_LIST}`
  );
}