import { useState } from "react";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";

export const Burger = (props) => {
	const navigate = useNavigate();
	const [shop, setShop] = useState(false);

	return (
		<div className="flex flex-col gap-y-2 py-4 px-2 w-[300px]">
			<div
				className="text-white flex items-center gap-x-2"
				onClick={() => {
					navigate("/app/sound");
					props.onClose();
				}}
			>
				<MusicNoteOutlinedIcon sx={{ color: "#E6355C" }} />
				Sound
			</div>
			<div
				className="text-white w-full flex  gap-x-2"
				onClick={() => {
					setShop((curr) => !curr);
				}}
			>
				<ShoppingCartOutlinedIcon sx={{ color: "#E6355C" }} />
				<div className={`w-full flex flex-col gap-2`}>
					<div
						className={`${shop && "border-b-[1px] border-b-[#E6355C]"
							} flex-grow flex items-center justify-between`}
					>
						Shop
						<KeyboardArrowDownIcon
							sx={{ color: "#E6355C" }}
							className={`${!shop && "rotate-90"}`}
						/>
					</div>
					{shop && (

						<div className="w-full h-20 py-2 pl-2 flex flex-col overflow-y-auto gap-y-1 text-white list-disc">
							<div
								className="list-disc cursor-pointer"
								onClick={() => {
									navigate("/collection");
									props.onClose();
								}}
							>
								All Designs
							</div>
							{props.arts?.map((art, ind) => {
								return (
									<div
										className="list-disc cursor-pointer"
										key={ind}
										onClick={() => {
											navigate("/shop", {
												state: { artType: art?._id, artName: art?.artTypeName },
											});
											props.onClose();
										}}
									>
										{art?.artTypeName}
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
			<div
				className="text-white flex items-center gap-x-2"
				onClick={() => {
					navigate("/collaborators");
					props.onClose();
				}}
			>
				<GroupsOutlinedIcon sx={{ color: "#E6355C" }} />
				Collaborators
			</div>
			<div
				className="text-white flex items-center gap-x-2"
				onClick={() => {
					navigate("/sustainability");
					props.onClose();
				}}
			>
				<PublicOutlinedIcon sx={{ color: "#E6355C" }} />
				Sustainability
			</div>
			<div
				className="text-white flex items-center gap-x-2"
				onClick={() => {
					navigate("/app/calendar");
					props.onClose();
				}}
			>
				<CalendarMonthOutlinedIcon sx={{ color: "#E6355C" }} />
				Calendar
			</div>
			<div
				className="text-white flex items-center gap-x-2"
				onClick={() => {
					navigate("/blogs");
					props.onClose();
				}}
			>
				<ArticleOutlinedIcon sx={{ color: "#E6355C" }} />
				Blog
			</div>
			<div
				className="text-white flex items-center gap-x-2"
				onClick={() => {
					navigate("/aboutUs");
					props.onClose();
				}}
			>
				<InfoOutlinedIcon sx={{ color: "#E6355C" }} />
				About Us
			</div>
		</div>
	);
};
