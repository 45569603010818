import { useState, useEffect, useRef, useCallback, createContext } from "react";
import {
	Backdrop,
	CircularProgress,
	Dialog,
	DialogContent,
	Modal,
} from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import Player from "./Player/Player";
import {
	collabReq,
	musicByAll,
	musicByArtist,
	latestMusic,
	searchMusic,
} from "../../../api/api_calls/music";
import { useSelector } from "react-redux";
import CustomModal from "../../../components/CustomModal/CustomModal";
import GradientButton from "../../../components/GradientButton/GradientButton";
import { Outlet, useLocation } from "react-router-dom";
import CustomInput from "../../../components/CustomInput/CustomInput";
import UploadMusic from "./UploadMusic/UploadMusic";
import { toast } from "react-toastify";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";

export const PlayerContext = createContext(null);

const Sound = () => {
	const { resolutionType } = useSelector((state) => state.resolution);
	const userState = useSelector((state) => state.user);
	const userData = userState?.userData;
	const { state } = useLocation();

	// player states (might migrate right to Player later)
	const [open, setOpen] = useState(false);
	const [selectMedia, setSelectMedia] = useState(null);
	const [loadMedia, setLoadMedia] = useState(null);
	const [timeProgress, setTimeProgress] = useState(0);
	const [duration, setDuration] = useState(0);

	// primary controls
	const [play, setPlay] = useState(false);
	const [prev, setPrev] = useState(false);
	const [next, setNext] = useState(false);
	const [repeat, setRepeat] = useState(false);
	const [shuffle, setShuffle] = useState(false);
	const [showVideo, setShowVideo] = useState(false);

	const [playerEngine, setPlayerEngine] = useState(null);

	const [render, setRender] = useState(false);

	const [ytState, setYTState] = useState(-1);

	// collab request
	const [collabReqModal, setCollabReqModal] = useState(
		state?.modalOpen || false
	);
	const [collabLoad, setCollabLoad] = useState(false);
	const [uploadedModal, setUploadedModal] = useState(false);
	const [currentTrack, setCurrentTrack] = useState(null);

	const [buffering, setBuffering] = useState(false);

	const ytref = useRef(null);
	const videoRef = useRef(null);
	const miniVidRef = useRef(null);

	useEffect(() => {
		// handle error where playerEngine.index exists outside of playerEngine.arr
		if (playerEngine) {
			if (
				playerEngine?.index < 0 ||
				playerEngine?.index >= playerEngine?.arr.length
			) {
				setCurrentTrack(null);
				return;
			}

			// destruction of unused refs
			videoRef.current = null;
			audioRef.current = null;
			ytref.current = null;
			miniVidRef.current = null;

			setCurrentTrack(playerEngine?.arr[playerEngine?.index]);
			// console.log(
			// 	"CURRENT TRACK BEING SET TO: ",
			// 	playerEngine?.arr[playerEngine?.index]
			// );

			if (playerEngine.stdi - 1 >= 0) setPrev(true);
			else setPrev(false);
			if (playerEngine.stdi + 1 < playerEngine.totalCount) setNext(true);
			else setNext(false);

			if (!open) setOpen(true);
		}

		// console.log("PLAYER ENGINE: ", playerEngine);

		// any change happens on playerEngine, change currentTrack accordingly
	}, [playerEngine]);

	const audioRef = useRef();
	const progressBarRef = useRef();
	const vidProgressBarRef = useRef();
	const playAnimationRef = useRef();

	const makeCollabReq = () => {
		setCollabLoad(true);
		collabReq()
			.then((res) => {
				toast.success(res?.data?.message, { theme: "dark" });
			})
			.catch((err) => {
				toast.error(err?.message, { theme: "dark" });
			})
			.finally(() => {
				setCollabLoad(false);
				setCollabReqModal(false);
			});
	};

	const reOrg = (details, arr) => {
		let tempArr = arr?.map((item, index) => {
			const tempObj = {
				title: item?.title,
				artist: item?.artist,
				playTime: item?.playTime,
				isYoutube: item?.isYoutube,
				isVideoOnly: item?.isVideoOnly,
			};
			if (item?.isYoutube) {
				tempObj["youtube"] = {
					url: item?.youtubeUrl,
					thumbnail: item?.thumbnailImages["youtubeThumbnail"],
				};
			} else {
				tempObj["audio"] = {
					src: item?.musicUrl,
					thumbnail: item?.thumbnailImages["musicThumbnail"],
				};
				if ("videoUrl" in item && item.videoUrl !== "") {
					tempObj["video"] = {
						src: item?.videoUrl,
						thumbnail: item?.thumbnailImages["videoThumbnail"],
					};
				}
			}
			return tempObj;
		});

		// console.log("TEMP ARR: ", tempArr);

		return {
			arr: tempArr,
			totalCount: details?.totalCount ? details?.totalCount : tempArr.length,
			totalPages: details?.totalPages ? details?.totalPages : 1,
		};
	};

	const repeatAni = useCallback(() => {
		// console.log("repeatAni: ", { videoRef, audioRef, ytref });

		// console.log(".");

		const currentTime = !currentTrack?.isYoutube
			? videoRef.current
				? videoRef.current?.getCurrentTime()
				: audioRef.current?.currentTime || 0
			: ytref.current?.getCurrentTime() || 0;

		setTimeProgress(currentTime);
		const progressPercent = (currentTime / duration) * 100;

		// console.log("currentTime: ", currentTime);

		if (progressBarRef && progressBarRef.current) {
			progressBarRef.current.value = currentTime;
			progressBarRef.current.style.setProperty(
				"--range-progress",
				`${progressPercent}%`
			);
		}
		if (vidProgressBarRef && vidProgressBarRef.current) {
			vidProgressBarRef.current.value = currentTime;
			vidProgressBarRef.current.style.setProperty(
				"--range-progress",
				`${progressPercent}%`
			);
		}

		playAnimationRef.current = requestAnimationFrame(repeatAni);
	}, [duration, currentTrack]);

	const shuffleFunc = () => {
		const i = Math.round(Math.random() * (playerEngine.totalCount - 1));

		musicEngine(playerEngine.source, playerEngine.query, i);
	};

	const playerCleanup = () => {
		setLoadMedia(true); // will set false in onLoadedMetadata
		setShowVideo(false);
		setPlay(false);
		if (playAnimationRef.current) {
			cancelAnimationFrame(playAnimationRef.current);
		}
		setTimeProgress(0);
		if (progressBarRef.current) {
			progressBarRef.current.value = 0;
			progressBarRef.current.max = 0;
			progressBarRef.current.style.setProperty("--range-progress", "0%");
		}
		if (vidProgressBarRef.current) {
			vidProgressBarRef.current.value = 0;
			vidProgressBarRef.current.max = 0;
			vidProgressBarRef.current.style.setProperty("--range-progress", "0%");
		}
		setDuration(0); // why did i never place this here before...
		// setCurrentTrack(null);
	};

	const musicEngine = (s, q = null, stdi) => {
		// console.log("STDI: ", stdi);

		// console.log("music engine: ", s, q, stdi);
		playerCleanup();
		// if (playerEngine && stdi === playerEngine.stdi) return; // very weird...
		// debugger;

		if (
			s === playerEngine?.source &&
			q == playerEngine?.query &&
			stdi === playerEngine?.stdi
		) {
			// have to explicitly call requestAnimationFrame in case of same song
			if (playerEngine?.arr[playerEngine?.index]?.isYoutube) {
				// ytref.current.pauseVideo();
				ytref.current?.seekTo(0);
				// ytref.current?.playVideo();
				onLoadedMetadata(ytref.current.getDuration());
			} else {
				// console.log("REPLAYING AUDIO: ", audioRef);

				if (playerEngine?.arr[playerEngine?.index]?.isVideoOnly) {
					videoRef.current.seekTo(0, "seconds");
					videoRef.current.getInternalPlayer().play();
					onLoadedMetadata(videoRef.current.getDuration()); // see if this works...
				} else {
					audioRef.current.pause();
					audioRef.current.currentTime = 0;
					audioRef.current.play();
					// console.log("RA: PLAYER ENGINE SAME SONG");
					playAnimationRef.current = requestAnimationFrame(repeatAni);
					onLoadedMetadata(audioRef.current.duration);
				}
			}
		} else {
			const convertedPage = s === "search" ? 1 : Math.floor(stdi / 10) + 1;
			const convertedIndex = s === "search" ? stdi : stdi % 10;

			if (
				playerEngine &&
				s === playerEngine.source &&
				q === playerEngine.query &&
				(s === "search"
					? true
					: 10 * (playerEngine.page - 1) <= stdi &&
					  stdi < 10 * playerEngine.page)
			) {
				// no fetch calls needed
				// console.log("NO FETCH ME");
				setPlayerEngine((curr) => ({
					...curr,
					page: convertedPage,
					stdi,
					index: convertedIndex,
				}));
			} else {
				// assuming valid index
				// console.log("FETCH ME");
				if (s === "all") {
					musicByAll({ page: convertedPage, limit: 10 }).then((res) => {
						const reorganized = reOrg(res?.data, res?.data?.data);
						setPlayerEngine({
							...reorganized,
							page: convertedPage,
							limit: 10,
							source: s,
							stdi,
							index: convertedIndex,
						});
					});
				} else if (s === "latest") {
					latestMusic({ page: convertedPage, limit: 10 }).then((res) => {
						const reorganized = reOrg(res?.data, res?.data?.data?.latestMusic);
						setPlayerEngine({
							...reorganized,
							page: convertedPage,
							limit: 10,
							source: s,
							stdi,
							index: convertedIndex,
						});
					});
				} else if (s === "artist") {
					musicByArtist({ artist: q, page: convertedPage, limit: 10 }).then(
						(res) => {
							// optimize this using reOrg
							const reorganized = ((res) => {
								let artistDet = res?.data?.data[0]?.artist;
								let tempArr = res?.data?.data[0]?.music.map((item, index) => {
									let tempObj = {
										artist: artistDet,
										title: item?.title,
										playTime: item?.playTime,
										isYoutube: item?.isYoutube,
										isVideoOnly: item?.isVideoOnly,
									};

									if (item?.isYoutube) {
										tempObj["youtube"] = {
											url: item?.youtubeUrl,
											thumbnail: item?.thumbnailImages["youtubeThumbnail"],
										};
									} else {
										if (!item?.isVideoOnly) {
											tempObj["audio"] = {
												src: item?.musicUrl,
												thumbnail: item?.thumbnailImages["musicThumbnail"],
											};
										}
										if ("videoUrl" in item && item.videoUrl !== "") {
											tempObj["video"] = {
												src: item?.videoUrl,
												thumbnail: item?.thumbnailImages["videoThumbnail"],
											};
										}
									}

									return tempObj;
								});
								return {
									arr: tempArr,
									totalCount: res?.data?.totalCount,
									totalPages: res?.data?.totalPages,
								};
							})(res);
							setPlayerEngine({
								...reorganized,
								page: convertedPage,
								limit: 10,
								source: s,
								query: q,
								stdi,
								index: convertedIndex,
							});
						}
					);
				} else if (s === "search") {
					// console.log(
					// 	"MUSIC ENGINE BY SEARCH TRIGGERED",
					// 	q,
					// 	stdi,
					// 	convertedIndex
					// );
					searchMusic({ musicName: q }).then((res) => {
						// console.log({ res });

						const reorganized = reOrg(null, res?.data?.data);
						setPlayerEngine({
							...reorganized,
							page: convertedPage,
							limit: reorganized.totalCount,
							source: s,
							query: q,
							stdi,
							index: convertedIndex,
						});
						// const tempArr = res?.data?.data?.map((item, index) => ({
						// 	id: item?._id,
						// 	thumbnail: item?.isYoutube
						// 		? item?.thumbnailImages["youtubeThumbnail"]
						// 		: item?.thumbnailImages["musicThumbnail"],
						// 	title: item?.title,
						// 	artist: item?.artist?.firstName + " " + item?.artist?.lastName,
						// }));
						// setList(tempArr);
					});
				}
			}
		}
	};

	const handleBack = () => {
		if (playerEngine.stdi - 1 >= 0)
			musicEngine(
				playerEngine.source,
				playerEngine.query,
				playerEngine.stdi - 1
			);
		else musicEngine(playerEngine.source, playerEngine.query, 0);
	};

	const handleForward = () => {
		// console.log("HANDLE FORWARD: ", playerEngine);
		if (playerEngine.stdi + 1 < playerEngine.totalCount)
			musicEngine(
				playerEngine.source,
				playerEngine.query,
				playerEngine.stdi + 1
			);
		else {
			musicEngine(playerEngine.source, playerEngine.query, 0);
			// onLoadedMetadata(duration);
			// setLoadMedia(false);
		}
	};

	const onLoadedMetadata = (durationVal) => {
		// console.log({ durationVal });
		// const seconds = type !== "" ? durationVal : audioRef.current.duration;
		const seconds = durationVal;

		// const seconds = !currentTrack?.isYoutube
		// 	? videoRef.current
		// 		? videoRef.current.getInternalPlayer()?.duration
		// 		: audioRef.current.duration
		// 	: durationVal;
		setDuration(seconds);
		progressBarRef.current.max = seconds;
		if (currentTrack?.isVideoOnly === true)
			vidProgressBarRef.current.max = seconds;
		if (!currentTrack?.isYoutube) setPlay(true);
		if (currentTrack?.isYoutube || currentTrack?.isVideoOnly)
			setShowVideo(true);
		setLoadMedia(false);
	};

	return (
		<PlayerContext.Provider
			value={{
				play,
				setPlay,
				open,
				setOpen,
				currentTrack,
				setCurrentTrack,
				musicEngine,
				playerEngine,
				//-------
				// open,
				// setOpen,
				selectMedia,
				setSelectMedia,
				loadMedia,
				setLoadMedia,
				timeProgress,
				setTimeProgress,
				duration,
				setDuration,
				repeat,
				setRepeat,
				shuffle,
				setShuffle,
				showVideo,
				setShowVideo,
				audioRef,
				ytState,
				setYTState,
				progressBarRef,
				vidProgressBarRef,
				playAnimationRef,
				handleBack,
				handleForward,
				prev,
				next,
				shuffleFunc,
				ytref,
				playerCleanup,
				onLoadedMetadata,
				videoRef,
				miniVidRef,
				repeatAni,
				buffering,
				setBuffering,
			}}
		>
			<div
				className="h-full flex w-full flex-col"
				style={{
					...(resolutionType.type === "mobile"
						? { overflow: "hidden" }
						: { overflow: "hidden" }),
				}}
			>
				<Outlet context={[setCollabReqModal, render, setRender]} />
				{open && <Player />}
			</div>
			{collabReqModal && (
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={collabReqModal}
					onClose={() => setCollabReqModal(false)}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<motion.div
						initial={{ y: 30, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						className="z-[3000] w-auto flex flex-col max-h-[95vh] overflow-y-auto"
					>
						<div className="flex py-[8px] px-[16px] justify-between bg-[#E6355C] items-center gap-x-8">
							<div className="flex flex-col">
								<h3 className="font-bold text-lg text-white">
									{userData?.isCollaborator || userData?.collabReqExist
										? "Upload Your own Music"
										: "Become Collaborator?"}
								</h3>
								<p className="font-normal text-sm text-white">
									{(userData?.isCollaborator || userData?.collabReqExist) &&
										"Feel free to upload your beats or music, and we'll reach out to you promptly."}
								</p>
							</div>
							<svg
								className="cursor-pointer"
								width="28"
								height="29"
								viewBox="0 0 28 29"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={() => setCollabReqModal(false)}
							>
								<path
									d="M14 2.83301C7.57171 2.83301 2.33337 8.07134 2.33337 14.4997C2.33337 20.928 7.57171 26.1663 14 26.1663C20.4284 26.1663 25.6667 20.928 25.6667 14.4997C25.6667 8.07134 20.4284 2.83301 14 2.83301ZM17.92 17.183C18.2584 17.5213 18.2584 18.0813 17.92 18.4197C17.745 18.5947 17.5234 18.6763 17.3017 18.6763C17.08 18.6763 16.8584 18.5947 16.6834 18.4197L14 15.7363L11.3167 18.4197C11.1417 18.5947 10.92 18.6763 10.6984 18.6763C10.4767 18.6763 10.255 18.5947 10.08 18.4197C9.74171 18.0813 9.74171 17.5213 10.08 17.183L12.7634 14.4997L10.08 11.8163C9.74171 11.478 9.74171 10.918 10.08 10.5797C10.4184 10.2413 10.9784 10.2413 11.3167 10.5797L14 13.263L16.6834 10.5797C17.0217 10.2413 17.5817 10.2413 17.92 10.5797C18.2584 10.918 18.2584 11.478 17.92 11.8163L15.2367 14.4997L17.92 17.183Z"
									fill="white"
								/>
							</svg>
						</div>
						<div className="bg-white p-4 flex flex-col gap-y-2">
							{userData?.isCollaborator || userData?.collabReqExist ? (
								<UploadMusic
									setCollabReqModal={setCollabReqModal}
									setUploadedModal={setUploadedModal}
									setRender={setRender}
								/>
							) : (
								<>
									Are you sure?
									<GradientButton
										title="Yes!"
										onClick={makeCollabReq}
										loading={collabLoad}
									/>
								</>
							)}
						</div>
						{/* <div className="bg-white p-4 flex flex-col gap-y-2">
							{userData?.isCollaborator ? (
								<UploadMusic
									setCollabReqModal={setCollabReqModal}
									setUploadedModal={setUploadedModal}
									setRender={setRender}
								/>
							) : (
								<>
									{userData?.collabReqExist ? (
										<>Awaiting admin approval...</>
									) : (
										<>
											Are you sure?
											<GradientButton
												title="Yes!"
												onClick={makeCollabReq}
												loading={collabLoad}
											/>
										</>
									)}
								</>
							)}
						</div> */}
					</motion.div>
				</Modal>
			)}
			{uploadedModal && (
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={uploadedModal}
					onClose={() => {
						window.location.reload();
						setUploadedModal(false);
					}}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<motion.div
						initial={{ y: 30, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						className="z-[3000] w-[400px] flex flex-col"
					>
						<div className="flex py-[8px] px-[16px] justify-between bg-[#E6355C] items-center gap-x-8">
							<div className="flex flex-col">
								<h3 className="font-bold text-lg text-white">
									Successfully Uploaded
								</h3>
							</div>
							<svg
								className="cursor-pointer"
								width="28"
								height="29"
								viewBox="0 0 28 29"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={() => {
									window.location.reload();
									setCollabReqModal(false);
								}}
							>
								<path
									d="M14 2.83301C7.57171 2.83301 2.33337 8.07134 2.33337 14.4997C2.33337 20.928 7.57171 26.1663 14 26.1663C20.4284 26.1663 25.6667 20.928 25.6667 14.4997C25.6667 8.07134 20.4284 2.83301 14 2.83301ZM17.92 17.183C18.2584 17.5213 18.2584 18.0813 17.92 18.4197C17.745 18.5947 17.5234 18.6763 17.3017 18.6763C17.08 18.6763 16.8584 18.5947 16.6834 18.4197L14 15.7363L11.3167 18.4197C11.1417 18.5947 10.92 18.6763 10.6984 18.6763C10.4767 18.6763 10.255 18.5947 10.08 18.4197C9.74171 18.0813 9.74171 17.5213 10.08 17.183L12.7634 14.4997L10.08 11.8163C9.74171 11.478 9.74171 10.918 10.08 10.5797C10.4184 10.2413 10.9784 10.2413 11.3167 10.5797L14 13.263L16.6834 10.5797C17.0217 10.2413 17.5817 10.2413 17.92 10.5797C18.2584 10.918 18.2584 11.478 17.92 11.8163L15.2367 14.4997L17.92 17.183Z"
									fill="white"
								/>
							</svg>
						</div>
						<div className="bg-white p-4 flex flex-col gap-y-2">
							<>
								<CheckCircleOutlineTwoToneIcon
									sx={{
										color: "#e93359",
										alignSelf: "center",
										fontSize: "12rem",
									}}
								/>
							</>
						</div>
					</motion.div>
				</Modal>
			)}
		</PlayerContext.Provider>
	);
};

export default Sound;
