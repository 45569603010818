import React, { useState } from "react";
import companyLogo from "../../../assets/company_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Drawer, Menu, MenuItem } from "@mui/material";
import { userActions } from "../../../redux/reducers/user";
import SearchDrawer from "./SearchDrawer/SearchDrawer";
import { cartActions } from "../../../redux/reducers/cart";
import { Burger } from "./Burger/Burger";

const WebsiteNavbar = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const userState = useSelector((state) => state.user);
	const { resolutionType } = useSelector((state) => state.resolution);
	const { cart } = useSelector((state) => state.cart);
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchBar, setSearchBar] = useState(false);
	const [burger, setBurger] = useState(false);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		localStorage.removeItem("AL_USER");
		dispatch(userActions.removeUser());
		dispatch(cartActions.emptyCart());
		navigate("/");
	};

	return (
		<>
			{resolutionType.type === "mobile" ? (
				<div className="flex justify-between items-center w-100 z-20 py-[2%] px-[1%]">
					<img
						src={companyLogo}
						className="w-12 cursor-pointer"
						onClick={() => navigate("/")}
						alt="companyLogo"
					/>
					<div className="flex gap-x-4 items-center">
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => setSearchBar(true)}
						>
							<path
								d="M11.5 21.106C16.7467 21.106 21 16.8527 21 11.606C21 6.35925 16.7467 2.10596 11.5 2.10596C6.25329 2.10596 2 6.35925 2 11.606C2 16.8527 6.25329 21.106 11.5 21.106Z"
								stroke="white"
								stroke-width="1.5"
							/>
							<path
								d="M21.3 21.606C21.2456 21.606 21.1949 21.5838 21.1635 21.5525L19.3036 19.6925C19.2332 19.6221 19.2235 19.4963 19.3066 19.4066C19.3778 19.3387 19.502 19.3306 19.5905 19.4137L21.4464 21.2696C21.5151 21.3382 21.526 21.4597 21.4492 21.5489C21.402 21.5884 21.3475 21.606 21.3 21.606Z"
								fill="#060709"
								stroke="white"
							/>
						</svg>
						{userState?.userData?._id && (
							<Badge
								badgeContent={cart?.cartItems?.[0]?.cartItems?.length}
								color="primary"
								sx={{
									"& .MuiBadge-badge": {
										color: "white",
										backgroundColor: "#E6355C",
									},
								}}
							>
								<Link to="/cart">
									<svg
										className="stroke-white"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001"
											stroke-width="1.5"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M9.00007 22H15.0001C19.0201 22 19.7401 20.39 19.9501 18.43L20.7001 12.43C20.9701 9.99 20.2701 8 16.0001 8H8.00007C3.73007 8 3.03007 9.99 3.30007 12.43L4.05007 18.43C4.26007 20.39 4.98007 22 9.00007 22Z"
											stroke-width="1.5"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M15.4955 12H15.5045"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M8.49451 12H8.50349"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</Link>
							</Badge>
						)}
						{!userState?.userData?._id ? (
							<div
								className="flex cursor-pointer items-end gap-1"
								onClick={() =>
									navigate("/login", { state: { previousPage: true } })
								}
							>
								<svg
									className="stroke-white"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								{/* <p className="text-white font-medium text-lg m-0">Account</p> */}
							</div>
						) : (
							<>
								<Avatar
									className="cursor-pointer"
									src={userState?.userData?.profilePicture}
									alt={`${userState?.userData?.firstName} ${userState?.userData?.lastName}`}
									sx={{ width: 30, height: 30, fontSize: "16px" }}
									onClick={handleClick}
								>
									{userState?.userData?.firstName?.[0]}
									{userState?.userData?.lastName?.[0]}
								</Avatar>
								<Menu
									anchorEl={anchorEl}
									id="account-menu"
									open={open}
									onClose={handleClose}
									onClick={handleClose}
									sx={{ width: "200px" }}
									PaperProps={{
										elevation: 0,

										sx: {
											borderRadius: "8px",
											// padding: "16px",

											overflow: "visible",
											filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
											mt: 1.5,
											"& .MuiAvatar-root": {
												width: 32,
												height: 32,
												ml: -0.5,
												mr: 1,
											},
											"&::before": {
												content: '""',
												display: "block",
												position: "absolute",
												top: 0,
												right: 14,
												width: 10,
												height: 10,
												bgcolor: "background.paper",
												transform: "translateY(-50%) rotate(45deg)",
												zIndex: 0,
											},
										},
									}}
									transformOrigin={{ horizontal: "right", vertical: "top" }}
									anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
								>
									<MenuItem
										sx={{
											color: "black",
											display: "flex",
											alignItems: "center",
											background: "white !important",
											gap: "8px",
											"&:hover": {
												background: "white",
												color: "red",
												fill: "red",
											},
										}}
										onClick={() => {
											handleClose();
											navigate("/profile");
										}}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="inherit"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.99984 1.66699C7.8165 1.66699 6.0415 3.44199 6.0415 5.62533C6.0415 7.76699 7.7165 9.50033 9.89984 9.57533C9.9665 9.56699 10.0332 9.56699 10.0832 9.57533C10.0998 9.57533 10.1082 9.57533 10.1248 9.57533C10.1332 9.57533 10.1332 9.57533 10.1415 9.57533C12.2748 9.50033 13.9498 7.76699 13.9582 5.62533C13.9582 3.44199 12.1832 1.66699 9.99984 1.66699Z"
												fill="inherit"
											/>
											<path
												d="M14.2333 11.7914C11.9083 10.2414 8.11663 10.2414 5.77497 11.7914C4.71663 12.4997 4.1333 13.4581 4.1333 14.4831C4.1333 15.5081 4.71663 16.4581 5.76663 17.1581C6.9333 17.9414 8.46663 18.3331 9.99997 18.3331C11.5333 18.3331 13.0666 17.9414 14.2333 17.1581C15.2833 16.4497 15.8666 15.4997 15.8666 14.4664C15.8583 13.4414 15.2833 12.4914 14.2333 11.7914Z"
												fill="inherit"
											/>
										</svg>
										My account
									</MenuItem>

									<MenuItem
										sx={{
											color: "black",
											display: "flex",
											alignItems: "center",
											gap: "8px",
											"&:hover": {
												background: "white",
												color: "red",
												fill: "red",
											},
										}}
										onClick={() => {
											handleClose();
											handleLogout();
										}}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="inherit"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M14 1.66699H11.8333C9.16667 1.66699 7.5 3.33366 7.5 6.00033V9.37533H12.7083C13.05 9.37533 13.3333 9.65866 13.3333 10.0003C13.3333 10.342 13.05 10.6253 12.7083 10.6253H7.5V14.0003C7.5 16.667 9.16667 18.3337 11.8333 18.3337H13.9917C16.6583 18.3337 18.325 16.667 18.325 14.0003V6.00033C18.3333 3.33366 16.6667 1.66699 14 1.66699Z"
												fill="inherit"
											/>
											<path
												d="M3.8002 9.37454L5.5252 7.64954C5.6502 7.52454 5.70853 7.36621 5.70853 7.20788C5.70853 7.04954 5.6502 6.88288 5.5252 6.76621C5.28353 6.52454 4.88353 6.52454 4.64186 6.76621L1.8502 9.55788C1.60853 9.79954 1.60853 10.1995 1.8502 10.4412L4.64186 13.2329C4.88353 13.4745 5.28353 13.4745 5.5252 13.2329C5.76686 12.9912 5.76686 12.5912 5.5252 12.3495L3.8002 10.6245H7.5002V9.37454H3.8002Z"
												fill="inherit"
											/>
										</svg>
										Logout
									</MenuItem>
								</Menu>
							</>
						)}
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => setBurger(true)}
						>
							<path
								d="M3 7.10596H21"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
							/>
							<path
								d="M3 12.106H21"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
							/>
							<path
								d="M3 17.106H21"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
							/>
						</svg>
					</div>
					<Drawer
						sx={{
							"& .MuiPaper-root": {
								height: "50%",
							},
						}}
						anchor="right"
						open={searchBar}
						onClose={() => setSearchBar(false)}
					>
						<SearchDrawer onClose={() => setSearchBar(false)} />
					</Drawer>
					<Drawer
						sx={{
							"& .MuiPaper-root": {
								height: "auto",
								borderRadius: "8px",
								background: "rgba(0, 0, 0, 0.9)",
								borderLeft: "1px solid white",
								borderBottom: "1px solid white",
							},
						}}
						anchor="right"
						open={burger}
						onClose={() => setBurger(false)}
					>
						<Burger onClose={() => setBurger(false)} arts={props.arts} />
					</Drawer>
				</div>
			) : (
				<div className="flex justify-between w-100 px-[12.25%] z-20 sticky">
					<img
						src={companyLogo}
						className="w-[70px] cursor-pointer"
						onClick={() => navigate("/")}
						alt="companyLogo"
					/>
					<div className="flex items-end gap-x-8 pb-1">
						<Link className="text-white font-medium text-lg" to="/app/sound">
							Sound
						</Link>
						<span
							className="text-white font-medium text-lg cursor-pointer"
							onClick={() => props.setShowDrop((curr) => !curr)}
						>
							Shop
						</span>
						<Link
							className="text-white font-medium text-lg"
							to="/collaborators"
						>
							Collaborators
						</Link>
						<Link
							className="text-white font-medium text-lg"
							to="/sustainability"
						>
							Sustainability
						</Link>
						<Link className="text-white font-medium text-lg" to="/app/calendar">
							Calendar
						</Link>
						<Link className="text-white font-medium text-lg" to="/blogs">
							Blog
						</Link>
						<Link className="text-white font-medium text-lg" to="/aboutUs">
							About Us
						</Link>
						<Link
							className=" font-semibold text-lg"
							to="/app/calendar"
							state={{ modalOpen: true }}
							style={{
								background: "linear-gradient(90deg, #E93359 0%, #6167BA 100%)",
								"-webkit-text-fill-color": "transparent",
								"-webkit-background-clip": "text",
							}}
						>
							Book a Band
						</Link>
					</div>
					<div className="flex gap-x-8 items-end pb-1">
						<svg
							className="stroke-white cursor-pointer"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => setSearchBar(true)}
						>
							<path
								d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
								stroke-width="1.5"
							/>
							<path
								d="M21.3001 21.5001C21.2456 21.5001 21.195 21.4779 21.1636 21.4465L19.3036 19.5865C19.2333 19.5162 19.2235 19.3903 19.3067 19.3006C19.3779 19.2327 19.5021 19.2247 19.5906 19.3077L21.4465 21.1636C21.5152 21.2323 21.5261 21.3538 21.4493 21.443C21.4021 21.4824 21.3476 21.5001 21.3001 21.5001Z"
								fill="#060709"
							/>
						</svg>
						{userState?.userData?._id && (
							<Badge
								badgeContent={cart?.cartItems?.[0]?.cartItems?.length}
								color="primary"
								sx={{
									"& .MuiBadge-badge": {
										color: "white",
										backgroundColor: "#E6355C",
									},
								}}
							>
								<Link to="/cart">
									<svg
										className="stroke-white"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001"
											stroke-width="1.5"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M9.00007 22H15.0001C19.0201 22 19.7401 20.39 19.9501 18.43L20.7001 12.43C20.9701 9.99 20.2701 8 16.0001 8H8.00007C3.73007 8 3.03007 9.99 3.30007 12.43L4.05007 18.43C4.26007 20.39 4.98007 22 9.00007 22Z"
											stroke-width="1.5"
											stroke-miterlimit="10"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M15.4955 12H15.5045"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M8.49451 12H8.50349"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</Link>
							</Badge>
						)}
						{!userState?.userData?._id ? (
							<div
								className="flex cursor-pointer items-end gap-1"
								onClick={() =>
									navigate("/login", { state: { previousPage: true } })
								}
							>
								<svg
									className="stroke-white"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								{/* <p className="text-white font-medium text-lg m-0">Account</p> */}
							</div>
						) : (
							<>
								<Avatar
									className="cursor-pointer"
									src={userState?.userData?.profilePicture}
									alt={`${userState?.userData?.firstName} ${userState?.userData?.lastName}`}
									sx={{ width: 30, height: 30, fontSize: "16px" }}
									onClick={handleClick}
								>
									{userState?.userData?.firstName?.[0]}
									{userState?.userData?.lastName?.[0]}
								</Avatar>
								<Menu
									anchorEl={anchorEl}
									id="account-menu"
									open={open}
									onClose={handleClose}
									onClick={handleClose}
									sx={{ width: "200px" }}
									PaperProps={{
										elevation: 0,

										sx: {
											borderRadius: "8px",
											// padding: "16px",

											overflow: "visible",
											filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
											mt: 1.5,
											"& .MuiAvatar-root": {
												width: 32,
												height: 32,
												ml: -0.5,
												mr: 1,
											},
											"&::before": {
												content: '""',
												display: "block",
												position: "absolute",
												top: 0,
												right: 14,
												width: 10,
												height: 10,
												bgcolor: "background.paper",
												transform: "translateY(-50%) rotate(45deg)",
												zIndex: 0,
											},
										},
									}}
									transformOrigin={{ horizontal: "right", vertical: "top" }}
									anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
								>
									<MenuItem
										sx={{
											color: "black",
											display: "flex",
											alignItems: "center",
											background: "white !important",
											gap: "8px",
											"&:hover": {
												background: "white",
												color: "red",
												fill: "red",
											},
										}}
										onClick={() => {
											handleClose();
											navigate("/profile");
										}}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="inherit"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.99984 1.66699C7.8165 1.66699 6.0415 3.44199 6.0415 5.62533C6.0415 7.76699 7.7165 9.50033 9.89984 9.57533C9.9665 9.56699 10.0332 9.56699 10.0832 9.57533C10.0998 9.57533 10.1082 9.57533 10.1248 9.57533C10.1332 9.57533 10.1332 9.57533 10.1415 9.57533C12.2748 9.50033 13.9498 7.76699 13.9582 5.62533C13.9582 3.44199 12.1832 1.66699 9.99984 1.66699Z"
												fill="inherit"
											/>
											<path
												d="M14.2333 11.7914C11.9083 10.2414 8.11663 10.2414 5.77497 11.7914C4.71663 12.4997 4.1333 13.4581 4.1333 14.4831C4.1333 15.5081 4.71663 16.4581 5.76663 17.1581C6.9333 17.9414 8.46663 18.3331 9.99997 18.3331C11.5333 18.3331 13.0666 17.9414 14.2333 17.1581C15.2833 16.4497 15.8666 15.4997 15.8666 14.4664C15.8583 13.4414 15.2833 12.4914 14.2333 11.7914Z"
												fill="inherit"
											/>
										</svg>
										My account
									</MenuItem>

									<MenuItem
										sx={{
											color: "black",
											display: "flex",
											alignItems: "center",
											gap: "8px",
											"&:hover": {
												background: "white",
												color: "red",
												fill: "red",
											},
										}}
										onClick={() => {
											handleClose();
											handleLogout();
										}}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="inherit"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M14 1.66699H11.8333C9.16667 1.66699 7.5 3.33366 7.5 6.00033V9.37533H12.7083C13.05 9.37533 13.3333 9.65866 13.3333 10.0003C13.3333 10.342 13.05 10.6253 12.7083 10.6253H7.5V14.0003C7.5 16.667 9.16667 18.3337 11.8333 18.3337H13.9917C16.6583 18.3337 18.325 16.667 18.325 14.0003V6.00033C18.3333 3.33366 16.6667 1.66699 14 1.66699Z"
												fill="inherit"
											/>
											<path
												d="M3.8002 9.37454L5.5252 7.64954C5.6502 7.52454 5.70853 7.36621 5.70853 7.20788C5.70853 7.04954 5.6502 6.88288 5.5252 6.76621C5.28353 6.52454 4.88353 6.52454 4.64186 6.76621L1.8502 9.55788C1.60853 9.79954 1.60853 10.1995 1.8502 10.4412L4.64186 13.2329C4.88353 13.4745 5.28353 13.4745 5.5252 13.2329C5.76686 12.9912 5.76686 12.5912 5.5252 12.3495L3.8002 10.6245H7.5002V9.37454H3.8002Z"
												fill="inherit"
											/>
										</svg>
										Logout
									</MenuItem>
								</Menu>
							</>
						)}
					</div>
					<Drawer
						anchor="right"
						open={searchBar}
						onClose={() => setSearchBar(false)}
					>
						<SearchDrawer onClose={() => setSearchBar(false)} />
					</Drawer>
				</div>
			)}
		</>
	);
};

export default WebsiteNavbar;
