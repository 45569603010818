// import { delay, motion } from "framer-motion";
// import React, { useState, useEffect } from 'react'
// import { ProductsSkeleton } from "../../../../skeletons/skeleton";
// import { get_all_arts_type } from "../../../../api/api_calls/shop";
// import { useSelector } from "react-redux";
// const boxVariant = {
//     hidden: {},
//     visible: {
//         transition: {
//             // when: "beforeChildren", //use this instead of delay
//             delay: 0.5,
//             staggerChildren: 0.05, //apply stagger on the parent tag
//         },
//     },
// };

// const listVariant = {
//     hidden: {
//         scale: 0.5, //move out of the site
//         opacity: 0,
//     },
//     visible: {
//         scale: 1, // bring it back to nrmal
//         opacity: 1,
//     },
// };
// function AllCollection() {
//     const [artTypes, setArtTypes] = useState()
//     useEffect(() => {
//         get_all_arts_type()
//             .then((res) => {
//                 setArtTypes(res?.data?.data?.artsData);
//             })
//             .catch((err) => console.log(err));
//     }, []);
//     const loader = useSelector((state) => state.loader);
//     const [artHovered, setArtHovered] = useState("");
//     return (
//         <>
//             <div
//                 className={`bg-[#E6355C] min-h-[450px] flex flex-col justify-center items-center`}
//             >
//                 <label className='text-[70px] md:text-[80px] text-[#FFFFFF]'>DESIGNS</label>
//                 <label className='text-lg md:text-xl text-[#FFFFFF]'>Every Design has story to tell</label>
//             </div>
//             <div className='w-full bg-[#FFFFFF]'>
//                 <motion.ul
//                     className={`${"py-[120px] px-[12.25%] flex flex-wrap gap-y-[52px] gap-x-[18px]"
//                         }`}
//                     variants={boxVariant}
//                     animate="visible"
//                     initial="hidden"
//                 >
//                     {loader.loading ? (
//                         <ProductsSkeleton />
//                     ) : (
//                         artTypes?.map((art) => (
//                             <motion.li
//                                 key={art?._id}
//                                 value={art._id}
//                                 className="flex flex-col gap-[16px] cursor-pointer"
//                                 variants={listVariant}
//                                 onMouseEnter={(e) => setArtHovered(art._id)}
//                                 onMouseLeave={() => setArtHovered("")}
//                             >
//                                 <motion.div
//                                     animate={
//                                         artHovered === art?._id
//                                             ? { boxShadow: "0px 4px 20px 0px #4E659F59" }
//                                             : { boxShadow: "none" }
//                                     }
//                                     className="h-[381px] rounded-[8px] w-[345px] overflow-hidden"
//                                 >
//                                     <motion.img
//                                         src={art?.images[0]}
//                                         animate={artHovered === art?._id ? { scale: 1.1 } : {}}
//                                         className="h-full w-full object-cover unloaded-img"
//                                     />
//                                 </motion.div>
//                                 <p className="text-2xl">{art?.artTypeName}</p>
//                             </motion.li>
//                         ))
//                     )}

//                 </motion.ul>
//             </div>
//         </>
//     )
// }

// export default AllCollection
import { motion } from "framer-motion";
import React, { useState, useEffect } from 'react'
import { ProductsSkeleton } from "../../../../skeletons/skeleton";
import { get_all_arts_type } from "../../../../api/api_calls/shop";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const boxVariant = {
  hidden: {},
  visible: {
    transition: {
      delay: 0.5,
      staggerChildren: 0.05,
    },
  },
};

const listVariant = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
  },
};

function AllCollection() {
  const [artTypes, setArtTypes] = useState();
  useEffect(() => {
    get_all_arts_type()
      .then((res) => {
        setArtTypes(res?.data?.data?.artsData);
      })
      .catch((err) => console.log(err));
  }, []);

  const loader = useSelector((state) => state.loader);
  const navigate=useNavigate()
  const [artHovered, setArtHovered] = useState("");
	const { resolutionType } = useSelector((state) => state.resolution);

  return (
    <>
      <div
        className={`bg-[#E6355C] min-h-[350px] flex flex-col justify-center items-center`}
      >
        <label className='text-[70px] md:text-[80px] text-[#FFFFFF]'>DESIGNS</label>
        <label className='text-lg md:text-xl text-[#FFFFFF]'>Every Design has a story to tell</label>
      </div>
      <div className='w-full bg-[#FFFFFF]'>
        <motion.ul
          className={`${resolutionType?.type==="mobile"?"justify-center":"px-[12.25%]"} py-[120px] flex flex-wrap gap-y-[52px] gap-x-[18px]`}
          variants={boxVariant}
          animate="visible"
          initial="hidden"
        >
          {loader.loading ? (
            <ProductsSkeleton />
          ) : (
            artTypes?.map((art) => (
              <motion.li
                key={art?._id}
                value={art._id}
                className="flex flex-col gap-[16px] cursor-pointer"
                variants={listVariant}
                onMouseEnter={() => setArtHovered(art._id)}
                onMouseLeave={() => setArtHovered("")}
                onClick={ ()=>navigate("/shop", {
                    state: { artType: art?._id, artName: art?.artTypeName },
                  })}
              >
                <motion.div
                  animate={
                    artHovered === art?._id
                      ? { boxShadow: "0px 4px 20px 0px #4E659F59" }
                      : { boxShadow: "none" }
                  }
                  className="h-[381px] rounded-[8px] w-[345px] overflow-hidden relative"
                >
                  <motion.img
                    src={art?.images[0]}
                    animate={artHovered === art?._id ? { scale: 1.1 } : {}}
                    className="h-full w-full object-cover unloaded-img"
                  />

                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <p className="text-white text-3xl font-semibold">{art?.artTypeName}</p>
                  </div>
                </motion.div>
              </motion.li>
            ))
          )}
        </motion.ul>
      </div>
    </>
  )
}

export default AllCollection;
