import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import UniqueDesignBanner from "./UniqueDesignBanner/UniqueDesignBanner";
import ProductsSection from "./ProductsSection/ProductsSection";
import { getAllProducts } from "../../../api/api_calls/shop";
import classes from "../Banner/Banner.module.css"
import { useLocation, useOutletContext } from "react-router-dom";
import { Drawer } from "@mui/material";
import AddToCartDrawer from "./AddToCartDrawer/AddToCartDrawer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { queryCreator } from "../../../utils/helperFunctions";
const Shop = () => {
	const { state } = useLocation();
	const dispatch = useDispatch();
	const { resolutionType } = useSelector((state) => state.resolution);
	const [artTypes] = useOutletContext();
	const [products, setProducts] = useState(null);
	const [open, setOpen] = useState(false);
	const [productDetails, setProductDetails] = useState({});
	const [sorting, setSorting] = useState("newest");
	const [category, setCategory] = useState({});
	const [categoriesList, setCategoriesList] = useState([null]);
	const [artName, setArtName] = useState("");

	const fetchAllProducts = () => {
		getAllProducts(state?.artType || artTypes?.[0]?._id, sorting, category?._id)
			.then((res) => { setProducts(res?.data?.data); setCategoriesList(res?.data?.categoriesList) })
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		fetchAllProducts();
		// .finally(() => dispatch(loaderActions.toggleLoading(false)));
	}, [state, sorting, artTypes, category]);
	const currentArtType = artTypes.find((item) => item?._id === state?.artType)
	console.log()
	return (
		<>
			<div
				className={`bg-[#E6355C] flex justify-between text-center items-center ${resolutionType.type === "mobile"
					? `${classes.mobileBack}`
					: `${classes.back}`
					}`}
			>
				<div className="w-full flex-col flex justify-center items-center py-14 px-4 gap-6">
					<h2 className="font-bold text-5xl text-white">
						{currentArtType?.artTypeName}
					</h2>
					<img src={currentArtType?.images[0]} alt="artType" style={{
						flexShrink: "0",
						width: "20%",
						objectFit: "cover",
					}} />
					<p className="mt-[16px] font-normal text-xl text-white">
						{currentArtType?.description}
					</p>
				</div>
			</div>
			<div className="flex flex-col bg-white">
				<div
					className={`flex border-b-[1px] border-[#E4E4E4]  items-center justify-between ${resolutionType.type === "mobile"
						? "flex-col py-4 px-4"
						: "px-[12.25%] py-[24px]"
						}`}
				>
					<h3 className="font-medium text-lg text-black">
						{state?.artName || artTypes?.[0]?.artTypeName}
					</h3>
					<div className="flex gap-4">
						<div
							className={`flex flex-col gap-[7px] ${resolutionType.type === "mobile" && "w-full"
								}`}
						>
							<h3 className="font-medium text-lg text-black">Categories</h3>
							<Select
								id="demo-select-small"
								sx={{
									width: resolutionType.type === "mobile" ? "100%" : "247px",
									height: "43px",
									backgroundColor: "#E4E4E4",
									color: "#060709",
									fontWeight: "400",
									fontSize: "18px",
									border: "none",
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"&:hover .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
								}}
								displayEmpty
								renderValue={(value) => value?.categoryName || "Select Category"}
								value={category}
								onChange={(e) => setCategory(e.target.value)}
							><MenuItem value={{}}>All</MenuItem>
								{categoriesList?.sort((a, b) => a?.categoryName?.localeCompare(b.categoryName))?.map((item) => <MenuItem value={item} >{item?.categoryName.charAt(0).toUpperCase() + item?.categoryName.slice(1).toLowerCase()}</MenuItem>)}
							</Select>
						</div>
						<div
							className={`flex flex-col gap-[7px] ${resolutionType.type === "mobile" && "w-full"
								}`}
						>
							<h3 className="font-medium text-lg text-black">Sort By</h3>
							<Select
								id="demo-select-small"
								sx={{
									width: resolutionType.type === "mobile" ? "100%" : "247px",
									height: "43px",
									backgroundColor: "#E4E4E4",
									color: "#060709",
									fontWeight: "400",
									fontSize: "18px",
									border: "none",
									"& .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"&:hover .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										border: "none",
									},
								}}
								value={sorting}
								onChange={(e) => setSorting(e.target.value)}
							>
								<MenuItem value="a-z">A-Z</MenuItem>
								<MenuItem value="z-a">Z-A</MenuItem>
								<MenuItem value="newest">Latest first</MenuItem>
								<MenuItem value="oldest">Oldest first</MenuItem>
								<MenuItem value="price-low-high">Price: Low to High</MenuItem>
								<MenuItem value="price-high-low">Price: High to Low</MenuItem>
							</Select>
						</div>
					</div>
				</div>
				{products?.length === 0 ? (
					<div className="px-[12.25%] py-[120px] text-center">
						<h3 className="font-semibold text-xl text-[##858585]">
							Products of this category are not available at the moment
						</h3>
					</div>
				) : (
					<ProductsSection
						products={products}
						openDrawer={() => setOpen(true)}
						setProduct={(data) => setProductDetails(data)}
					/>
				)}
				<UniqueDesignBanner />
				<Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
					<AddToCartDrawer
						product={productDetails}
						close={() => setOpen(false)}
					/>
				</Drawer>
			</div>
		</>
	);
};

export default Shop;
