import { React, useState, useEffect } from "react";
import CollabBanner from "./CollabBanner/CollabBanner";
import { getCollaborators } from "../../../api/api_calls/misc";
import CollabCard from "./CollabCard/CollabCard";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

const Collaborators = () => {
	const [loading, setLoading] = useState(true);
	const [collabs, setCollabs] = useState([]);
	const { resolutionType } = useSelector((state) => state.resolution);
	const userState = useSelector((state) => state.user);
	const userData = userState?.userData;
	const getCollabs = () => {
		setLoading(true);
		getCollaborators({ page: 1, limit: 9 })
			.then((res) => {
				setCollabs(res?.data?.data);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		getCollabs();
	}, []);
	console.log(userData)
	return (
		<div
			className={`flex flex-col gap-y-4 bg-white ${resolutionType.type === "mobile"
				? "py-16 px-4"
				: "px-[12.25%] py-[64px]"
				}`}
		>
			{(userData?.isCollaborator || userData?.collabReqExist) ? "" : <CollabBanner />}
			<div className="flex flex-wrap px-2 gap-8 justify-center">
				{loading ? (
					<>
						<Skeleton variant="rectangular" width={367} height={362} />
						<Skeleton variant="rectangular" width={367} height={362} />
						<Skeleton variant="rectangular" width={367} height={362} />
						<Skeleton variant="rectangular" width={367} height={362} />
						<Skeleton variant="rectangular" width={367} height={362} />
						<Skeleton variant="rectangular" width={367} height={362} />
					</>
				) : (
					<>
						{collabs?.length > 0 ? (
							<>
								{collabs?.map((item, index) => (
									<CollabCard key={index} {...item} />
								))}
							</>
						) : (
							<div className="text-center text-lg text-black h-[15rem] flex justify-center items-center">
								No Collaborators Found
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Collaborators;
