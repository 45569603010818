import React, { useEffect, useState } from 'react'
import GradientButton from '../../../../components/GradientButton/GradientButton'
import AddIcon from "@mui/icons-material/Add";
import AddArtType from '../../../../components/ModalComponents/AddArtType/AddArtType';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { Button } from '@mui/material';
import ViewArtType from '../../../../components/ModalComponents/ViewArtType/ViewArtType';
import DefaultPfp from "../../../../assets/company_logo_dark.png";
import { toast } from 'react-toastify';
import ConfirmDeleteModal from '../../../../components/ModalComponents/ConfirmDeleteModal/ConfirmDeleteModal';
import { deleteArtType, getArtTypeList } from '../../../../api/api_calls/user';

function UploadArtType() {
    const [addArtTypeModal, setArtTypeModal] = useState(false)
    const [viewArtTypeModal, setViewArtTypeModal] = useState(false)
    const [artTypeDeleteModal, setArtTypeDeleteModal] = useState(false)
    const [selectedArtTypeId, setSelectedArtTypeId] = useState("")
    const [loading, setLoading] = useState(false);
    const [artTypeList, setArtTypeList] = useState([])
    const [modalData, setModalData] = useState({})
    const [showReadMore, setShowReadMore] = useState(false)
    const handleClose = () => { setArtTypeModal(false); setModalData({}) }
    const handleAddressDeleteClose = () => setArtTypeDeleteModal(false)
    const { resolutionType } = useSelector((state) => state.resolution);
    const fetchArtTypeList = async () => {
        try {
            const response = await getArtTypeList()
            const { data } = response
            setArtTypeList(data?.data)
            setLoading(false)
        } catch (err) {
            toast.error(err?.message)
            setLoading(false)
        }
    }
    useEffect(() => {
        setLoading(true)
        fetchArtTypeList()
    }, [])
    const handleArtTypeDelete = async () => {
        try {
            await deleteArtType(selectedArtTypeId)
            fetchArtTypeList()
            toast.success("Art type deleted successfully")
        } catch (err) {
            toast.error(err?.message)
        }
    }
    return (
        <div className="flex flex-col mt-6">
            <GradientButton
                iconFirst={true}
                title="Add Art Type"
                icon={
                    <div
                        className="bg-white flex justify-center items-center rounded-[100%] h-6 w-6 text-[#E93359]"
                    >
                        <AddIcon color="inherit" fontSize="small" />
                    </div>
                }
                onClick={() => { setArtTypeModal(true); setModalData({}) }}
            />
            <div className="flex flex-wrap px-2 gap-8">
                {/* {loading &&  ? ( */}
                {/* <>
                    <Skeleton variant="rectangular" width={367} height={362} className='mt-10' />
                    <Skeleton variant="rectangular" width={367} height={362} className='mt-10' />
                    <Skeleton variant="rectangular" width={367} height={362} className='mt-10' />
                    <Skeleton variant="rectangular" width={367} height={362} />
                    <Skeleton variant="rectangular" width={367} height={362} />
                    <Skeleton variant="rectangular" width={367} height={362} />
                </> */}
                {/* ) : ( */}
                {artTypeList?.length > 0 && artTypeList?.map((item, index) => {
                    return <div
                        className={`flex flex-col rounded-sm p-4 gap-y-2 mt-10 hover:bg-[#FFFFFF]  hover:rounded-lg hover:shadow-md ${resolutionType.type === "mobile" ? "w-full" : "w-[367px]"}`}
                        key={item?._id}
                        onMouseEnter={() => setShowReadMore(index)}
                        onMouseLeave={() => setShowReadMore(false)}
                    >
                        <img
                            src={item?.images[0]}
                            className={`w-full rounded-[4px] ${item?.images[0]
                                ? "object-cover"
                                : "object-contain border-2 border-[#E4E4E4]"
                                } ${resolutionType.type === "mobile" ? "h-50vh" : "h-[290px]"}`}
                            alt="profilePic"
                        />
                        <span className="text-2xl font-semibold">
                            {item?.artTypeName}
                        </span>
                        <span className="break-words">{item?.description?.slice(0, 200)}...
                        </span>
                        {showReadMore === index && <div className='flex'><Button sx={{ color: "#6964B4", fontWeight: 700, fontSize: "18px", lineHeight: "27px", textTransform: "capitalize" }} onClick={() => { setViewArtTypeModal(true); setModalData(item) }}>read more</Button>
                            <Button sx={{ color: "#6964B4", fontWeight: 700, fontSize: "18px", lineHeight: "27px", textTransform: "capitalize" }} onClick={() => { setArtTypeModal(true); setModalData(item) }}>Edit</Button>
                            <Button sx={{ color: "#6964B4", fontWeight: 700, fontSize: "18px", lineHeight: "27px", textTransform: "capitalize" }} onClick={() => { setArtTypeDeleteModal(true); setSelectedArtTypeId(item?._id) }}>Delete</Button>
                        </div>
                        }
                    </div>
                    // }))
                })}
            </div>
            <CustomModal
                title={Object.keys(modalData).length !== 0 ? "Edit Art Type" : "Add Art Type"}
                caption="Here you can"
                open={addArtTypeModal}
                onClose={handleClose}
            >
                <AddArtType setArtTypeModal={setArtTypeModal} fetchArtTypeList={fetchArtTypeList} modalData={modalData} />
            </CustomModal>
            <CustomModal
                title={modalData?.artTypeName}
                caption=""
                open={viewArtTypeModal}
                width={"w-[70rem]"}
                onClose={() => setViewArtTypeModal(false)}
            >
                <ViewArtType modalData={modalData} />
            </CustomModal>
            <ConfirmDeleteModal
                open={artTypeDeleteModal}
                onClose={handleAddressDeleteClose}
                handleYes={handleArtTypeDelete}
            />
        </div>
    )
}

export default UploadArtType
