import { React, useState, useEffect, useRef } from "react";
import styles from "./community.module.css";
import { CircularProgress, Avatar } from "@mui/material";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextArea";
import { useSelector } from "react-redux";
import { doc, collection, serverTimestamp, addDoc } from "firebase/firestore";
import { channelsRef } from "../../../firebase/firestore_config";
import {
	dateEqual,
	formatTimestamp,
	printDateChat,
} from "../../../utils/helperFunctions";
import GradientButton from "../../../components/GradientButton/GradientButton";
import { addMediaCommunity } from "../../../api/api_calls/misc";
import SendIcon from "@mui/icons-material/Send";
import CustomModal from "../../../components/CustomModal/CustomModal";
import EditCommunityChanel from "../../../components/ModalComponents/EditCommunityChannel/EditCommunityChanel";

const Chat = ({
	chatLoading,
	membersLoading,
	setMessagesCache,
	draft,
	setDraft,
	mediaDraft,
	setMediaDraft,
	setChannelMembersLoading,
	setOpenMem,
	openMem,
	currChannel,
	messages,
	fetchChannelMembers,
	setOpenAddMembers,
	channelMembers,
	setOpenLeave,
	openEmoji,
	setCurrChannel,
	setOpenEmoji,
}) => {
	const { resolutionType } = useSelector((state) => state.resolution);
	const userState = useSelector((state) => state.user);
	const userData = userState?.userData;

	const [editChannelModal, setEditChannelModal] = useState(false);
	const [communityData, setCommunityData] = useState(null);

	const chatBodyRef = useRef();

	const scrollToBottom = () => {
		chatBodyRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	// managing textArea
	const textAreaRef = useRef(null);
	useAutosizeTextArea(textAreaRef.current, draft);

	const checkValidMessage = () => {
		return (
			(draft !== "" || mediaDraft) && (mediaDraft ? "aws" in mediaDraft : true)
		);
	};

	const handleFileUpload = (file) => {
		if (!file) return;
		const formData = new FormData();
		formData.append("media", file);
		return addMediaCommunity(formData).then((res) => {
			return res.data.data;
		});
	};

	const handleFileDownload = async (e) => {
		const files = e.target.files;
		const file = files && files[0];
		const local = URL.createObjectURL(file);
		setMediaDraft({
			isVideo: file.type.includes("video/"),
			local,
			file,
		});

		// now the await things!
		handleFileUpload(file).then((res) => {
			setMediaDraft((curr) => ({
				...curr,
				aws: res,
			}));
		}); // make a catch
		e.target.value = null;
	};

	const sendFunction = () => {
		if (!checkValidMessage()) return;
		const tempDraft = draft;
		const tempMediaDraft = mediaDraft;
		setDraft("");
		setMediaDraft(null);
		setMessagesCache((curr) => ({
			...curr,
			[currChannel.id]: {
				draft: "",
				mediaDraft: null,
			},
		}));
		// handleTextSubmit(tempDraft, tempMediaDraft);
		const chatRef = collection(doc(channelsRef, currChannel.id), "chat");
		const newMessage = {
			createdAt: serverTimestamp(),
			sentBy: userData._id,
			content: {
				media: tempMediaDraft
					? {
						isVideo: tempMediaDraft?.isVideo,
						// url: mediaURL,
						url: tempMediaDraft?.aws,
					}
					: null,
				message: tempDraft,
			},
		};

		// add to chat
		addDoc(chatRef, newMessage);
	};

	return (
		<div
			className={styles.chatMain}
			style={{
				...(resolutionType.type === "mobile"
					? { width: "100%", flexDirection: "column" }
					: { width: "calc(100% - 16rem)" }),
			}}
		>
			{chatLoading && membersLoading ? (
				<>
					<div
						className="h-full w-full flex justify-center items-center"
						style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
					>
						<CircularProgress color="inherit" />
					</div>
				</>
			) : (
				<>
					{currChannel && (
						<>
							<div
								className="h-full box-border flex flex-col"
								style={{
									width: `${resolutionType.type === "mobile"
										? "100%"
										: openMem
											? "calc(100% - 16rem)"
											: "100%"
										}`,
								}}
							>
								<div className="header flex flex-row justify-between box-border px-6 py-2 items-center">
									<span className="text-base font-semibold">
										#{currChannel?.fields?.title}
									</span>
									<div className="flex items-center gap-x-4">
										{currChannel?.fields?.admin ===
											userState?.userData?._id && (
												<GradientButton
													style={{ fontSize: "1rem", paddingY: "0.5rem" }}
													title="Edit Channel"
													onClick={() => {
														setEditChannelModal(true);
														setCommunityData({ ...currChannel });
													}}
												/>
											)}
										{!currChannel?.fields?.isPublic && (
											<GradientButton
												style={{ fontSize: "1rem", paddingY: "0.5rem" }}
												title="Leave Channel"
												onClick={() => setOpenLeave(true)}
											/>
										)}

										{openMem ? (
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												style={{ cursor: "pointer" }}
												onClick={() => setOpenMem((curr) => !curr)}
											>
												<path
													d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
													fill="#E6355C"
												/>
												<path
													d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z"
													fill="#E6355C"
												/>
												<path
													d="M19.9899 7.3401C20.1499 9.2801 18.7699 10.9801 16.8599 11.2101C16.8499 11.2101 16.8499 11.2101 16.8399 11.2101H16.8099C16.7499 11.2101 16.6899 11.2101 16.6399 11.2301C15.6699 11.2801 14.7799 10.9701 14.1099 10.4001C15.1399 9.4801 15.7299 8.1001 15.6099 6.6001C15.5399 5.7901 15.2599 5.0501 14.8399 4.4201C15.2199 4.2301 15.6599 4.1101 16.1099 4.0701C18.0699 3.9001 19.8199 5.3601 19.9899 7.3401Z"
													fill="#E6355C"
												/>
												<path
													d="M21.9902 16.5904C21.9102 17.5604 21.2902 18.4004 20.2502 18.9704C19.2502 19.5204 17.9902 19.7804 16.7402 19.7504C17.4602 19.1004 17.8802 18.2904 17.9602 17.4304C18.0602 16.1904 17.4702 15.0004 16.2902 14.0504C15.6202 13.5204 14.8402 13.1004 13.9902 12.7904C16.2002 12.1504 18.9802 12.5804 20.6902 13.9604C21.6102 14.7004 22.0802 15.6304 21.9902 16.5904Z"
													fill="#E6355C"
												/>
											</svg>
										) : (
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												style={{ cursor: "pointer" }}
												onClick={() => {
													setOpenMem((curr) => !curr);
													fetchChannelMembers(currChannel).finally(() => {
														setTimeout(() => {
															setChannelMembersLoading(false);
														}, 200);
													});

												}}
											>
												<path
													d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
													fill="#858585"
												/>
												<path
													d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z"
													fill="#858585"
												/>
												<path
													d="M19.9899 7.3401C20.1499 9.2801 18.7699 10.9801 16.8599 11.2101C16.8499 11.2101 16.8499 11.2101 16.8399 11.2101H16.8099C16.7499 11.2101 16.6899 11.2101 16.6399 11.2301C15.6699 11.2801 14.7799 10.9701 14.1099 10.4001C15.1399 9.4801 15.7299 8.1001 15.6099 6.6001C15.5399 5.7901 15.2599 5.0501 14.8399 4.4201C15.2199 4.2301 15.6599 4.1101 16.1099 4.0701C18.0699 3.9001 19.8199 5.3601 19.9899 7.3401Z"
													fill="#858585"
												/>
												<path
													d="M21.9902 16.5904C21.9102 17.5604 21.2902 18.4004 20.2502 18.9704C19.2502 19.5204 17.9902 19.7804 16.7402 19.7504C17.4602 19.1004 17.8802 18.2904 17.9602 17.4304C18.0602 16.1904 17.4702 15.0004 16.2902 14.0504C15.6202 13.5204 14.8402 13.1004 13.9902 12.7904C16.2002 12.1504 18.9802 12.5804 20.6902 13.9604C21.6102 14.7004 22.0802 15.6304 21.9902 16.5904Z"
													fill="#858585"
												/>
											</svg>
										)}
									</div>
								</div>
								<div className="h-full overflow-y-auto border-y-2 border-[#E4E4E4] box-border p-4 ">
									{messages
										?.slice()
										.reverse()
										.map((item, index, array) => {
											return (
												<div
													key={index}
													ref={
														index === messages.length - 1 ? chatBodyRef : null
													}
												>
													{/* {index === 0 && <>EARLIEST</>} */}
													{item.createdAt &&
														(index === 0 ||
															!dateEqual(
																item?.createdAt,
																array[index - 1]?.createdAt
															)) && (
															<div className="w-full my-2 items-center flex flex-row">
																<div className="h-[1px] bg-[#858585] flex-1" />

																<p className="text-center mx-2 font-normal text-sm text-[#858585]">
																	{printDateChat(item?.createdAt)}
																</p>

																<div className="h-[1px] bg-[#858585] flex-1" />
															</div>
														)}
													<div
														className="w-full flex box-border gap-x-2"
														key={index}
													>
														<div className="w-11">
															<Avatar
																className="w-full"
																src={channelMembers[item.sentBy]?.pfp}
																alt={`${channelMembers[item.sentBy]?.firstName
																	} ${channelMembers[item.sentBy]?.lastName}`}
																sx={{ fontSize: "16px" }}
															>
																{channelMembers[item.sentBy]?.firstName[0]}
																{channelMembers[item.sentBy]?.lastName[0]}
															</Avatar>
														</div>
														<div className="w-full flex flex-col box-border mb-6">
															<div className="text-base font-semibold mb-2 box-border">
																{channelMembers.hasOwnProperty(item.sentBy)
																	? `${channelMembers[item.sentBy]?.firstName
																	} ${channelMembers[item.sentBy]?.lastName}`
																	: "Deleted User"}
																<span className="text-xs font-normal text-[#858585] box-border mr-2">
																	{userData._id === item.sentBy && "(You)"}
																</span>
																<span className="text-sm font-normal text-[#858585]">
																	{formatTimestamp(item.createdAt)}
																</span>
															</div>
															<div className="flex flex-col gap-y-2">
																{item.content.message
																	.split("\n")
																	.map((line, index) => {
																		const urlPattern = /(https?:\/\/[^\s]+)/g;
																		return urlPattern.test(line) ? (
																			<a
																				key={index}
																				href={line}
																				target="_blank"
																				rel="noopener noreferrer"
																				style={{ color: "blue", textDecoration: "underline" }}
																			>
																				{line}
																			</a>) :
																			<span
																				key={index}
																				style={{ display: "block" }}
																			>
																				{line}
																			</span>
																	})}
															</div>
															{item.content.media && (
																<div className="w-full flex">
																	{!item.content.media.isVideo ? (
																		<img
																			src={item.content.media.url}
																			alt="mediaText"
																			style={{ maxWidth: "200px" }}
																		/>
																	) : (
																		<video width="320" height="240" controls>
																			<source
																				src={`${item.content.media.url}#t=0.1`}
																			/>
																		</video>
																	)}
																</div>
															)}
														</div>
													</div>
												</div>
											);
										})}
								</div>
								<div className="h-fit box-border p-4">
									<div className={styles.messageGrey}>
										<div className="w-full h-auto">
											<textarea
												ref={textAreaRef}
												rows={1}
												placeholder="Message..."
												maxLength={1000}
												className={`${styles.textArea} ${mediaDraft
													? "border-b-[2px] border-[#e4e4e4]"
													: "border-transparent"
													}`}
												value={draft}
												onChange={(e) => setDraft(e.target.value)}
												style={{ width: "100%" }}
												onKeyDown={(e) => {
													if (e.key === "Enter" && !e.shiftKey) {
														e.preventDefault();
														sendFunction();
													}
												}}
											/>
											{mediaDraft && (
												<div className="w-[5rem] h-[5rem] bg-black rounded-lg relative">
													<div className="w-full h-full rounded-lg">
														{mediaDraft?.isVideo ? (
															<video
																src={mediaDraft.local}
																alt="media"
																style={{
																	borderRadius: "8px",
																	width: "100%",
																	height: "100%",
																	objectFit: "cover",
																	overflow: "hidden",
																	filter: `${!("aws" in mediaDraft)
																		? "blur(2px)"
																		: "none"
																		}`,
																}}
															/>
														) : (
															<img
																src={mediaDraft.local}
																alt="media"
																style={{
																	borderRadius: "8px",
																	width: "100%",
																	height: "100%",
																	objectFit: "cover",
																	overflow: "hidden",
																	filter: `${!("aws" in mediaDraft)
																		? "blur(2px)"
																		: "none"
																		}`,
																}}
															/>
														)}
														{!("aws" in mediaDraft) && (
															<div className="w-full h-full absolute top-[25%] left-[25%]">
																<CircularProgress />
															</div>
														)}
													</div>
													{"aws" in mediaDraft && (
														<div
															style={{
																position: "absolute",
																top: "-10%",
																left: "80%",
																width: "1.5rem",
																height: "1.5rem",
																display: "flex",
																cursor: "pointer",
															}}
															onClick={() => {
																setMediaDraft(null);
															}}
														>
															<svg
																width="24"
																height="24"
																viewBox="0 0 56 56"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<circle cx="28" cy="28" r="16" fill="white" />
																<path
																	d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
																	fill="#E6355C"
																/>
															</svg>
														</div>
													)}
												</div>
											)}
										</div>
										<div className="flex justify-between items-center w-18 box-border px-2 relative">
											<input
												className="absolute top-[-9999px] left-[-9999px] opacity-0 hidden"
												type="file"
												onChange={handleFileDownload}
												id="mediaInput"
												accept="video/*,image/*"
												display="none"
											/>
											<label htmlFor="mediaInput" className="cursor-pointer">
												<svg
													width="30"
													height="30"
													viewBox="0 0 30 30"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													style={{ cursor: "pointer" }}
												>
													<path
														d="M20.2316 2.8125H10.0184C5.58219 2.8125 2.9375 5.45719 2.9375 9.89344V20.0944C2.9375 24.5428 5.58219 27.1875 10.0184 27.1875H20.2194C24.6556 27.1875 27.3003 24.5428 27.3003 20.1066V9.89344C27.3125 5.45719 24.6678 2.8125 20.2316 2.8125ZM20.4509 17.8641L17.7453 20.5697C17.0872 21.2278 16.2341 21.5447 15.3809 21.5447C14.5278 21.5447 13.6625 21.2156 13.0166 20.5697C12.3828 19.9359 12.0294 19.095 12.0294 18.2053C12.0294 17.3156 12.3828 16.4625 13.0166 15.8409L14.735 14.1225C15.0884 13.7691 15.6734 13.7691 16.0269 14.1225C16.3803 14.4759 16.3803 15.0609 16.0269 15.4144L14.3084 17.1328C14.0159 17.4253 13.8575 17.8031 13.8575 18.2053C13.8575 18.6075 14.0159 18.9975 14.3084 19.2778C14.9056 19.875 15.8684 19.875 16.4656 19.2778L19.1712 16.5722C20.7191 15.0244 20.7191 12.5137 19.1712 10.9659C17.6234 9.41812 15.1128 9.41812 13.565 10.9659L10.6156 13.9153C9.99406 14.5369 9.65281 15.3534 9.65281 16.2188C9.65281 17.0841 9.99406 17.9128 10.6156 18.5222C10.9691 18.8756 10.9691 19.4606 10.6156 19.8141C10.2622 20.1675 9.67719 20.1675 9.32375 19.8141C8.34875 18.8756 7.8125 17.5959 7.8125 16.2309C7.8125 14.8659 8.33656 13.5863 9.29937 12.6234L12.2488 9.67406C14.5034 7.41937 18.1841 7.41937 20.4388 9.67406C22.7056 11.9288 22.7056 15.6094 20.4509 17.8641Z"
														fill="#060709"
													/>
												</svg>
											</label>

											<svg
												width="30"
												height="30"
												viewBox="0 0 30 30"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
												style={{ cursor: "pointer" }}
												onClick={() => setOpenEmoji((curr) => !curr)}
											>
												<path
													d="M20.4816 2.8125H10.2684C5.83219 2.8125 3.1875 5.45719 3.1875 9.89344V20.0944C3.1875 24.5428 5.83219 27.1875 10.2684 27.1875H20.4694C24.9056 27.1875 27.5503 24.5428 27.5503 20.1066V9.89344C27.5625 5.45719 24.9178 2.8125 20.4816 2.8125ZM11.1094 8.15063C12.3647 8.15063 13.4006 9.17438 13.4006 10.4419C13.4006 11.7094 12.3769 12.7331 11.1094 12.7331C9.84188 12.7331 8.81812 11.685 8.81812 10.4297C8.81812 9.17438 9.85406 8.15063 11.1094 8.15063ZM15.375 23.6287C12.0966 23.6287 9.4275 20.9597 9.4275 17.6812C9.4275 16.8281 10.1222 16.1213 10.9753 16.1213H19.7503C20.6034 16.1213 21.2981 16.8159 21.2981 17.6812C21.3225 20.9597 18.6534 23.6287 15.375 23.6287ZM19.6406 12.7087C18.3853 12.7087 17.3494 11.685 17.3494 10.4175C17.3494 9.15 18.3731 8.12625 19.6406 8.12625C20.9081 8.12625 21.9319 9.15 21.9319 10.4175C21.9319 11.685 20.8959 12.7087 19.6406 12.7087Z"
													fill="#060709"
												/>
											</svg>
											<div
												className={`w-12 h-6 ml-1 flex ${checkValidMessage()
													? "bg-[#E6355C] cursor-pointer text-[white]"
													: "bg-[#E6355C] text-[#AE1335]"
													} rounded-md items-center justify-center`}
												onClick={sendFunction}
											>
												<SendIcon
													style={{
														height: "75%",
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							{openMem && (
								<div
									className={`${resolutionType.type === "mobile" ? "w-full" : "w-[16rem]"
										} h-full bg-[#F9F9F9]`}
								>
									<div className="border-2 border-[#E4E4E4] text-base font-semibold p-3">
										Members ({channelMembers.size})
									</div>
									<div className="p-4 h-[calc(100%-3.25rem)] flex flex-col">
										<div className="h-full flex flex-col gap-y-2 mb-2 overflow-y-auto">
											{Object.values(channelMembers)?.map((item, index) => {
												if (typeof item === "object") {
													return (
														<div
															key={index}
															className="flex gap-x-2 items-center"
														>
															<div className="w-11">
																<div
																	className={`w-full aspect-square rounded-full`}
																>
																	<Avatar
																		className="w-full"
																		src={item?.pfp}
																		sx={{ fontSize: "16px" }}
																	>
																		{item?.firstName[0].toUpperCase()}
																		{item?.lastName[0].toUpperCase()}
																	</Avatar>
																</div>
															</div>
															<div className="text-base font-semibold">{`${item.firstName} ${item.lastName}`}</div>
														</div>
													);
												}
											})}
										</div>
										<div className="min-h-[4rem] flex justify-center items-center">
											{!currChannel?.fields?.isPublic && (
												<GradientButton
													style={{ fontSize: "1rem", fontWeight: "600" }}
													iconFirst={true}
													icon={
														<svg
															width="15"
															height="16"
															viewBox="0 0 15 16"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M7.5 0.5C3.3675 0.5 0 3.8675 0 8C0 12.1325 3.3675 15.5 7.5 15.5C11.6325 15.5 15 12.1325 15 8C15 3.8675 11.6325 0.5 7.5 0.5ZM10.5 8.5625H8.0625V11C8.0625 11.3075 7.8075 11.5625 7.5 11.5625C7.1925 11.5625 6.9375 11.3075 6.9375 11V8.5625H4.5C4.1925 8.5625 3.9375 8.3075 3.9375 8C3.9375 7.6925 4.1925 7.4375 4.5 7.4375H6.9375V5C6.9375 4.6925 7.1925 4.4375 7.5 4.4375C7.8075 4.4375 8.0625 4.6925 8.0625 5V7.4375H10.5C10.8075 7.4375 11.0625 7.6925 11.0625 8C11.0625 8.3075 10.8075 8.5625 10.5 8.5625Z"
																fill="white"
															/>
														</svg>
													}
													title="Add Members"
													onClick={() => setOpenAddMembers(true)}
												/>
											)}
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</>
			)}
			<CustomModal
				open={editChannelModal}
				title="Edit Community"
				onClose={() => setEditChannelModal(false)}
			>
				<EditCommunityChanel communityData={communityData} setCurrChannel={setCurrChannel} />
			</CustomModal>
		</div>
	);
};

export default Chat;
