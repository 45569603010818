import { useState, useEffect, useContext, useCallback } from "react";
import { motion } from "framer-motion";
import Ticker from "react-ticker";
import { CircularProgress, Skeleton } from "@mui/material";
import { formatTime } from "../../../../utils/helperFunctions";
import MiniVideo from "../Video/MiniVideo";
import NewVideoModal from "../Video/NewVideoModal";
import YouTube from "react-youtube";
import { extractYouTubeVideoId } from "../../../../utils/helperFunctions";
import { PlayerContext } from "../Sound";
import { useSelector } from "react-redux";
import ProgressBar from "../../../../components/MusicComponents/ProgressBar/ProgressBar";
import PrimaryControls from "../../../../components/MusicComponents/PrimaryControls/PrimaryControls";

function Player() {
	const {
		loadMedia,
		timeProgress,
		setTimeProgress,
		duration,
		play,
		setPlay,
		repeat,
		setRepeat,
		shuffle,
		setShuffle,
		showVideo,
		setShowVideo,
		currentTrack,
		audioRef,
		ytState,
		setYTState,
		progressBarRef,
		vidProgressBarRef,
		playAnimationRef,
		handleBack,
		handleForward,
		prev,
		next,
		shuffleFunc,
		ytref,
		playerCleanup,
		onLoadedMetadata,
		videoRef,
		repeatAni,
	} = useContext(PlayerContext);

	const { resolutionType } = useSelector((state) => state.resolution);

	const [forceRender, setForceRender] = useState(false);

	useEffect(() => {
		setForceRender((prev) => !prev);
	}, [currentTrack]);

	useEffect(() => {
		// console.log("DURATION: ", duration);
		if (currentTrack) {
			if (!currentTrack.isYoutube) {
				if (audioRef.current) {
					if (play) {
						audioRef.current.play();
						// console.log("RA: AUDIO PLAY");
						playAnimationRef.current = requestAnimationFrame(repeatAni);
					} else {
						audioRef.current.pause();
						cancelAnimationFrame(playAnimationRef.current);
					}
				}
			}
		}
	}, [play, currentTrack, repeatAni]);

	return (
		<>
			{currentTrack?.isVideoOnly === true && (
				<NewVideoModal forceRender={forceRender} />
			)}

			<>
				{resolutionType.type === "mobile" ? (
					<motion.div
						initial={{ y: 60 }}
						animate={{ y: 0 }}
						transition={{ ease: "easeOut", duration: 0.75 }}
						className={`min-h-[10.75rem] w-full px-8 py-2 bg-[#E6355C] text-cyan-50 z-10 flex flex-col justify-between relative`}
					>
						{!loadMedia && !currentTrack ? (
							<div className="w-full h-full flex justify-center items-center">
								Error in Music...
							</div>
						) : (
							<>
								<div className="h-full flex items-center gap-x-2 w-52">
									{currentTrack ? (
										<>
											<img
												src={
													currentTrack?.isYoutube
														? currentTrack?.youtube?.thumbnail
														: currentTrack?.audio?.thumbnail
												}
												alt={`${currentTrack?.title}`}
												style={{ borderRadius: "50%" }}
												className="w-9 h-9 object-cover border-solid border-[1px] border-[white]"
											/>
											<div className="grow flex flex-col ">
												<span className="block text-base font-semibold truncate">
													{currentTrack?.title}
												</span>{" "}
												{/* make this name scroll automatically on overflow*/}
												<span className="block text-sm font-normal truncate">
													by {`${currentTrack?.artist?.firstName} `}
													{currentTrack?.artist.lastName}
												</span>
											</div>
										</>
									) : (
										<>
											<Skeleton
												variant="circular"
												sx={{
													bgcolor: "#CF254B",
													width: "2.25rem",
													height: "2.25rem",
												}}
											/>
											<div className="flex flex-col gap-y-[0.5rem]">
												<Skeleton
													variant="rounded"
													sx={{
														bgcolor: "#CF254B",
														width: "6rem",
														height: "0.75rem",
													}}
												/>
												<Skeleton
													variant="rounded"
													sx={{
														bgcolor: "#CF254B",
														width: "3.5rem",
														height: "0.75rem",
													}}
												/>
											</div>
										</>
									)}
								</div>
								<PrimaryControls />
								<div className="flex">
									{!currentTrack?.isYoutube && currentTrack?.audio?.src && (
										<audio
											loop={repeat}
											key={currentTrack?.audio?.src}
											autoplay
											src={currentTrack?.audio?.src}
											ref={audioRef}
											onLoadedMetadata={(e) =>
												onLoadedMetadata(e.target.duration)
											}
											onEnded={() => {
												if (!repeat) {
													playerCleanup();
													if (shuffle) shuffleFunc();
													else handleForward();
												}
											}}
										/>
									)}

									<div className="flex w-full justify-between items-center gap-x-4">
										<span className="w-12">
											{currentTrack && formatTime(timeProgress)}
										</span>
										<ProgressBar referenceHook={progressBarRef} />
										<span className="w-12">
											{currentTrack && formatTime(duration)}
										</span>
									</div>
								</div>
								{showVideo &&
									!currentTrack?.isYoutube &&
									currentTrack?.isVideoOnly !== true && <MiniVideo />}
								{currentTrack?.isYoutube && (
									<YouTube
										key={forceRender}
										opts={{
											width: "320",
											height: "320",
											playerVars: {
												autoplay: 1, // Attempt to autoplay
												mute: 1, // Mute the video (required for autoplay on mobile)
											},
										}}
										style={{
											position: "absolute",
											[showVideo ? "bottom" : "top"]: "100%",
											right: "0",
										}}
										onReady={(e) => {
											const player = e.target;
											// this is mobile
											// Attempt to play the video after the player is ready
											// const tryPlay = () => {
											// 	// if (player.getPlayerState() !== 1) {
											// 	// 	console.log("TRY PLAY");
											// 	// 	player.playVideo();
											// 	// }
											// 	console.log("TRY PLAY");
											// 	document
											// 		.getElementById("player-play-button")
											// 		.dispatchEvent(new Event("click"));
											// };

											// // Ensure the video plays after a short delay (for iOS)
											// setTimeout(tryPlay, 500); // Delay might help iOS handle autoplay

											// // Optional: Unmute after autoplay
											setTimeout(() => {
												player.unMute();
											}, 10);

											onLoadedMetadata(player.getDuration());
											ytref.current = player;
										}}
										onStateChange={(e) => {
											setYTState(e.data);
											if (e.data === 1) {
												// console.log("RA: YOUTUBE MOBILE PLAYED");
												playAnimationRef.current =
													requestAnimationFrame(repeatAni);
											} else {
												cancelAnimationFrame(playAnimationRef.current);
											}
										}}
										videoId={extractYouTubeVideoId(currentTrack?.youtube?.url)}
										onPlay={() => {
											setPlay(true);
										}}
										onPause={() => {
											setPlay(false);
										}}
										onEnd={() => {
											if (repeat) {
												ytref.current?.seekTo(0);
												ytref.current?.playVideo();
											} else {
												playerCleanup();
												if (shuffle) shuffleFunc();
												else handleForward();
												// ytref.current = null; // destroy?!
											}
										}}
									/>
								)}
							</>
						)}
					</motion.div>
				) : (
					<motion.div
						initial={{ y: 60 }}
						animate={{ y: 0 }}
						transition={{ ease: "easeOut", duration: 0.75 }}
						className={`min-h-[4.75rem] w-full px-8 py-2 bg-[#E6355C] text-cyan-50 z-30 flex relative ${
							currentTrack?.isVideoOnly && showVideo
								? "justify-center"
								: "justify-between"
						}`}
					>
						{!loadMedia && !currentTrack ? (
							<>Error in Music...</>
						) : (
							<>
								<div
									className={`max-h-full flex items-center gap-x-2 ${
										currentTrack?.isVideoOnly && showVideo ? "" : "w-52"
									}`}
								>
									{currentTrack ? (
										<>
											<img
												src={
													currentTrack?.isYoutube
														? currentTrack?.youtube?.thumbnail
														: currentTrack?.audio?.thumbnail
												}
												alt={`${currentTrack?.title}`}
												style={{ borderRadius: "50%" }}
												className="w-9 h-9 object-cover border-solid border-[1px] border-[white]"
											/>
											<div className="flex-1 h-full grow flex flex-col justify-between overflow-hidden">
												{currentTrack?.title.length > 10 &&
												!(currentTrack?.isVideoOnly && showVideo) ? (
													<>
														{/* Ticker text with flex-grow and padding between ticker instances */}
														<div className="flex-grow">
															<Ticker key={currentTrack?.title}>
																{() => (
																	<span className="whitespace-nowrap px-4 text-base font-semibold">
																		{currentTrack?.title}
																	</span>
																)}
															</Ticker>
														</div>
													</>
												) : (
													<span className="max-w-full block text-base font-semibold truncate">
														{currentTrack?.title}
													</span>
												)}
												{/* Artist text with flex-grow */}
												<div className="flex-grow">
													<span className="block text-sm font-normal truncate">
														by {`${currentTrack?.artist?.firstName} `}
														{currentTrack?.artist.lastName}
													</span>
												</div>
											</div>
										</>
									) : (
										<>
											<Skeleton
												variant="circular"
												sx={{
													bgcolor: "#CF254B",
													width: "2.25rem",
													height: "2.25rem",
												}}
											/>
											<div className="flex flex-col gap-y-[0.5rem]">
												<Skeleton
													variant="rounded"
													sx={{
														bgcolor: "#CF254B",
														width: "6rem",
														height: "0.75rem",
													}}
												/>
												<Skeleton
													variant="rounded"
													sx={{
														bgcolor: "#CF254B",
														width: "3.5rem",
														height: "0.75rem",
													}}
												/>
											</div>
										</>
									)}
								</div>
								<div
									className={`flex shrink-0 grow ${
										currentTrack?.isVideoOnly && showVideo && "hidden"
									}`}
								>
									<div className="w-full flex justify-between items-center gap-x-4">
										<span className="min-w-[45px]">
											{currentTrack && formatTime(timeProgress)}
										</span>
										<ProgressBar referenceHook={progressBarRef} />
										<span className="min-w-[45px]">
											{currentTrack && formatTime(duration)}
										</span>
									</div>
								</div>

								{(!currentTrack?.isVideoOnly ||
									(currentTrack?.isVideoOnly && !showVideo)) && (
									<PrimaryControls />
								)}

								{/*AUDIO, VIDEO, & YOUTUBE DOM*/}

								{!currentTrack?.isYoutube && currentTrack?.audio?.src && (
									<audio
										loop={repeat}
										key={currentTrack?.audio?.src}
										autoplay
										src={currentTrack?.audio?.src}
										ref={audioRef}
										onLoadedMetadata={(e) =>
											onLoadedMetadata(e.target.duration)
										}
										onEnded={() => {
											if (!repeat) {
												playerCleanup();
												if (shuffle) shuffleFunc();
												else handleForward();
											}
										}}
									/>
								)}

								{showVideo &&
									!currentTrack?.isYoutube &&
									currentTrack?.isVideoOnly !== true && <MiniVideo />}

								{currentTrack?.isYoutube && (
									<YouTube
										key={forceRender}
										opts={{ width: "512", height: "320" }}
										style={{
											position: "absolute",
											[showVideo ? "bottom" : "top"]: "100%",
											right: "0",
										}}
										onReady={(e) => {
											onLoadedMetadata(e.target.getDuration());
											ytref.current = e.target;
											ytref.current.playVideo();
										}}
										onStateChange={(e) => {
											setYTState(e.data);
											if (e.data === 1) {
												// console.log("RA: YOUTUBE DESKTOP PLAYED");
												playAnimationRef.current =
													requestAnimationFrame(repeatAni);
											} else {
												cancelAnimationFrame(playAnimationRef.current);
											}
										}}
										videoId={extractYouTubeVideoId(currentTrack?.youtube?.url)}
										onPlay={() => {
											setPlay(true);
										}}
										onPause={() => {
											setPlay(false);
										}}
										onEnd={() => {
											if (repeat) {
												ytref.current?.seekTo(0);
												ytref.current?.playVideo();
											} else {
												playerCleanup();
												if (shuffle) shuffleFunc();
												else handleForward();
												// ytref.current = null; // destroy?!
											}
										}}
									/>
								)}
							</>
						)}
					</motion.div>
				)}
			</>
		</>
	);
}

export default Player;
