import React from "react";
import { useNavigate } from "react-router-dom";

const dynamicLinks = {
  "": {
    name: "Home",
    url: "/",
  },
  "product-details": {
    name: "Product Details",
    url: "/product-details",
  },
  cart: {
    name: "Cart",
    url: "/cart",
  },
  profile: {
    name: "My Account",
    url: "/profile",
  },
  addresses: {
    name: "Address",
    url: "/profile/addresses",
  },
  orders: {
    name: "Orders",
    url: "/profile/orders",
  },
  artType: {
    name: "Art Type",
    url: "/profile/artType",
  },
  customDesign: {
    name: "Select Your Design",
    url: "/customDesign",
  }, 
  collabWithUs: {
    name: "Collab with Us",
    url: "/collabWithUs",
  },
};

const BreadCrumb = () => {
  const crumbs = window.location.pathname.split("/");
  const navigate = useNavigate();

  return (
    <div className="bg-[#F8F8F8] py-3 px-[12.25%] font-normal text-lg text-black flex gap-[10px] items-center">
      {crumbs.map((crumb, ind) => (
        <>
          <p
            className={`cursor-pointer ${ind === crumbs.length - 1
                ? "font-bold underline underline-offset-2"
                : ""
              }`}
            onClick={() => navigate(dynamicLinks?.[crumb]?.url)}
          >
            {dynamicLinks?.[crumb]?.name}
          </p>
          {ind !== crumbs.length - 1 && <p>{">"}</p>}
        </>
      ))}
    </div>
  );
};

export default BreadCrumb;
