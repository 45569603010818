import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import instagramIcon from "../../../../assets/instagramIcon.svg"
import facebookIcon from "../../../../assets/facebookIcon.svg"
import spotifyIcon from "../../../../assets/spotifyIcon.svg"
import soundCloudIcon from "../../../../assets/soundCloudIcon.svg"
import DefaultPfp from "../../../../assets/company_logo_dark.png";
import { CircularProgress } from '@mui/material';
import { collaboratorsDetails } from '../../../../api/api_calls/misc';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { NA } from '../../../../utils/helperFunctions';
function CollaboratorDetails() {
  const { resolutionType } = useSelector((state) => state.resolution);
  const loader = useSelector((state) => state.loader);
  const [collaboratorDetails, setCollaboratorDetails] = useState({})
  const { id } = useParams()
  useEffect(() => {
    collaboratorsDetails({ id: id }).then((response) => {
      console.log(response)
      const { data } = response?.data
      setCollaboratorDetails(data)
    })
  }, [id])
  const { collabDetails } = collaboratorDetails
  const formattedDate = new Date(collaboratorDetails?.createdAt).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return (
    <>
      <div
        className={`w-full bg-white  ${resolutionType.type === "mobile" ? "px-10 py-2" : "px-60 py-16"
          }`}
      >
        {loader?.loading ? <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress color="inherit" size={80} />
        </Box> :
          <>
            <div className={`flex ${resolutionType.type === "mobile" ? "flex-col gap-4 " : "flex-row relative"}`}>
              <img src={collaboratorDetails?.profilePicture ? collaboratorDetails?.profilePicture : DefaultPfp}
                alt='profilePic'
                className={`rounded-[8px]
                ${resolutionType.type === "mobile" ? "h-50vh" : "h-[290px] w-[250px]"}`}
              />
              <div className={`px-6 py-4 rounded-lg  h-[105px] bg-[#E6355C] ${resolutionType.type !== "mobile" ? "lg:absolute lg:left-48 lg:top-[185px] xl:left-[245px] xl:top-[185px] w-[90%]" : "w-full"}`}
              >
                <label className='text-lg sm:text-3xl font-bold text-[#FFFFFF]'>{`${collaboratorDetails?.firstName} ${collaboratorDetails?.lastName}`}</label>
                <p className='text-sm sm:text-lg font-normal text-[#FFFFFF]'>By Aeternal Lovers  |   {formattedDate}</p>
              </div>
            </div>
            <div className='flex flex-col gap-2 mt-3'>
              <label className='text-lg font-bold'>Describe your style of music.</label>
              <p className='text-lg font-normal text-[#858585]'>{NA(collabDetails?.description)}</p>
            </div>
            <div className='flex flex-col gap-2 mt-6'>
              <label className='text-lg font-bold'>Tell us about your inspiration in making music. What inspires you?</label>
              <p className='text-lg font-normal text-[#858585]'>{NA(collabDetails?.tellUsAboutInspirationFroMusic)}</p>
            </div>
            <div className='flex flex-col gap-2 mt-6'>
              <label className='text-lg font-bold'>What is your favorite Aeternal Lovers design or track. Why?</label>
              <p className='text-lg font-normal text-[#858585]'>{NA(collabDetails?.favouriteAbtAeternal)}</p>
            </div>
            <div className='flex flex-col gap-2 mt-6'>
              <label className='text-lg font-bold'>We would be delighted to hear about anything else you would like to share with us.</label>
              <p className='text-lg font-normal text-[#858585]'>{NA(collabDetails?.additionalDescription)}</p>
            </div>
            <div className='flex flex-col gap-2 mt-6'>
              {/* <div className="h-auto md:w-[40%] bg-[#FFF1F4] rounded-lg p-4 flex gap-3">
            <img src={dummyProduct} alt='dummyProduct' />
            <div className="flex flex-col gap-4 items-start">
              <label className='text-lg font-semibold'>Aeternal Killa Sound</label>
              <button className='text-[#E6355C] text-sm'>Show Now</button>
            </div>
          </div> */}
              {(collabDetails?.socialMedia?.Instagram || collabDetails?.socialMedia?.facebook || collabDetails?.socialMedia?.spotify || collabDetails?.socialMedia?.soundCloud) &&
                <div className='flex flex-col gap-2 mt-6'>
                  <label className='text-lg font-bold'>Where can people go to find out more about your work?</label>
                  <p className='text-lg font-normal text-[#858585]'>Follow me on</p>
                  <div className='flex gap-2 flex-col sm:flex-row'>
                    {collabDetails?.socialMedia?.Instagram && <button className='px-3 py-2  bg-[#FFE4F1] border border-[#D82D7E] rounded-[150px] text-[#D82D7E] text-lg flex gap-3 justify-center sm:justify-start'
                      onClick={() => {
                        let instagramUrl = collabDetails.socialMedia.Instagram;
                        if (!/^https?:\/\//i.test(instagramUrl)) {
                          instagramUrl = `https://${instagramUrl}`;
                        }
                        window.open(instagramUrl, '_blank', 'noopener noreferrer');
                      }}
                    >
                      <img src={instagramIcon} alt='instagramIcon' />
                      Instagram</button>}
                    {collabDetails?.socialMedia?.facebook && <button className='px-3 py-2 bg-[#DDECFF] border border-[#1877F2] rounded-[150px] text-[#1877F2] text-lg flex gap-3 justify-center sm:justify-start'
                      onClick={() => {
                        let facebookUrl = collabDetails.socialMedia.facebook;
                        if (!/^https?:\/\//i.test(facebookUrl)) {
                          facebookUrl = `https://${facebookUrl}`;
                        }
                        window.open(facebookUrl, '_blank', 'noopener noreferrer');
                      }}
                    >
                      <img src={facebookIcon} alt='instagramIcon' />
                      Facebook</button>}
                    {collabDetails?.socialMedia?.spotify && <button className='px-3 py-2 bg-[#EFFFF5] border border-[#0FA845] rounded-[150px] text-[#0FA845] text-lg flex gap-3 justify-center sm:justify-start'
                      onClick={() => {
                        let spotifyUrl = collabDetails.socialMedia.spotify;
                        if (!/^https?:\/\//i.test(spotifyUrl)) {
                          spotifyUrl = `https://${spotifyUrl}`;
                        }
                        window.open(spotifyUrl, '_blank', 'noopener noreferrer');
                      }}
                    >
                      <img src={spotifyIcon} alt='instagramIcon' />
                      Spotify</button>}
                    {collabDetails?.socialMedia?.soundCloud && <button className='px-3 py-2 bg-[#FFE8E0] border border-[#FF5500] rounded-[150px] text-[#FF5500] text-lg flex gap-3 justify-center sm:justify-start'
                      onClick={() => {
                        let soundCloudUrl = collabDetails.socialMedia.soundCloud;
                        if (!/^https?:\/\//i.test(soundCloudUrl)) {
                          soundCloudUrl = `https://${soundCloudUrl}`;
                        }
                        window.open(soundCloudUrl, '_blank', 'noopener noreferrer');
                      }}
                    >
                      <img src={soundCloudIcon} alt='instagramIcon' />
                      Sound Cound</button>}
                  </div>
                </div>
              }
            </div>
          </>}
      </div>
      {/* <CollaboratorsAllReviews /> */}
    </>
  )
}

export default CollaboratorDetails
