import Joi from "joi";

export const loginSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  password: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .messages({
      "string.empty":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    })
    .label("Password"),
});

export const registerSchema = Joi.object({
  firstName: Joi.string().required().max(20).label("First Name"),
  lastName: Joi.string().required().max(20).label("Last Name"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
  password: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .label("Password")
    .messages({
      "string.empty": "Password cannot be empty",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    }),
  confirmPassword: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .label("Confirm Password")
    .messages({
      "string.empty": "Password cannot be empty",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    }),
});

export const forgotPasswordSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email"),
});

export const resetPasswordSchema = Joi.object({
  newPassword: Joi.string()
    .min(8)
    .regex(/[A-Z]/, "upper-case")
    .regex(/[a-z]/, "lower-case")
    .regex(/[^\w]/, "special character")
    .regex(/[0-9]/, "number")
    .label("Password")
    .messages({
      "string.empty": "Password cannot be empty",
      "string.min":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
      "string.pattern.name":
        "Password should be atleast 8 character long with one lower and one upper case with one special symbol.",
    }),
  otp: Joi.string().required(),
  id: Joi.string().required(),
});

export const addCollaboratorSchema = Joi.object({
  firstName: Joi.string().required().label("First Name"),
  lastName: Joi.string().required().label("Last Name"),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required().label("Email Address"),
  mobileNumber: Joi.string()
    .pattern(/^\d{11}$/)
    .required()
    .label("Phone Number")
    .messages({
      "string.pattern.base": `"Phone Number" must be a valid 10-digit US phone number.`,
    }),
  collabType: Joi.string().required().label("Collab Type"),
  Instagram: Joi.string()
    .pattern(/^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9._-]+\/?$/)
    .label("Instagram Profile")
    .messages({
      "string.pattern.base": `"Instagram" must be a valid Instagram URL (e.g., https://instagram.com/username).`
    }).optional(),
  facebook: Joi.string()
    .pattern(/^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9.]+\/?$/)
    .label("Facebook Profile")
    .messages({
      "string.pattern.base": `"Facebook" must be a valid Facebook URL (e.g., https://facebook.com/username).`
    }).optional(),
  spotify: Joi.string()
    .pattern(/^https?:\/\/(open\.spotify\.com\/user\/[a-zA-Z0-9]+|spotify:user:[a-zA-Z0-9]+)$/)
    .label("Spotify Profile")
    .messages({
      "string.pattern.base": `"Spotify" must be a valid Spotify user profile URL (e.g., https://open.spotify.com/user/username or spotify:user:username).`
    }).optional(),
  soundCloud: Joi.string()
    .pattern(/^https?:\/\/(www\.)?soundcloud\.com\/[a-zA-Z0-9_-]+\/?$/)
    .label("SoundCloud")
    .messages({
      "string.pattern.base": `"SoundCloud" must be a valid SoundCloud URL (e.g., https://soundcloud.com/username).`
    }).optional(),
})
