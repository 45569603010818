import { React, useEffect } from "react";
import classes from "./LandingPage.module.css";
import { motion } from "framer-motion";
import landingPagePills from "../../../assets/landing_page_pills.png";
import gradient from "../../../assets/gradient.png";
import GradientButton from "../../../components/GradientButton/GradientButton";
import EastIcon from "@mui/icons-material/East";
import audioSketch from "../../../assets/audio_sketch.png";
import musicCollage from "../../../assets/music_collage.png";
import playingInstrument from "../../../assets/man_playing_instrument.png";
import { useNavigate, useOutletContext } from "react-router-dom";
import UniqueDesignBanner from "../Shop/UniqueDesignBanner/UniqueDesignBanner";
import TopSellers from "../ProductDetails/TopSellers/TopSellers";
import { useSelector } from "react-redux";
import ProductsSection from "../Shop/ProductsSection/ProductsSection";

const blogContent = [
	{
		blogName: "SPEAK LOVE in the Pollinators Garden",
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20211009_134811_480x480.jpg?v=1634156230",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<img
						className="mb-20"
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20211009_134811_480x480.jpg?v=1634156230"
						alt=""
					/>
				</p>
				<p>
					While the letter "A'" stands for communication through words,&nbsp;
					the heart represents the highest vibrational emotion, LOVE.
				</p>
				<p>
					In each moment, we have a choice to operate from a place of fear or
					love.
				</p>
				<p>
					Keep your eyes open for our Speak Love design. We hope it will
					reminded you to live from love.
				</p>
			</div>
		),
		blogCaption: `While the letter "A'" stands for communication through words, the 
  heart represents the highest vibrational emotion, LOVE. In each moment, we have...`,
	},
	{
		blogName: "Aeternal Garden",
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/Sharon_the_the_Garden_480x480.jpg?v=1633556129",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<img
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/Sharon_the_the_Garden_480x480.jpg?v=1633556129"
						alt=""
						className="mb-20"
					/>
				</p>
				<p>
					This Pollinators Garden is part of the James Baldwin Outdoor Learning
					Center on the campus of DeWitt Clinton High School. JBOLC is hosting a
					Saturday Farmers Market like no other a few feet away from this garden
					until November 20th. Stop by to shop local for eco-friendly goods,
					build community and chase butterflies.&nbsp;
				</p>
			</div>
		),
		blogCaption: `This Pollinators Garden is part of the James Baldwin Outdoor Learning Center on the campus of DeWitt Clinton High School. JBOLC is hosting a Saturday Farmers Market like no other a few feet away from this garden until November 20th. Stop by to shop local for eco-friendly goods, build community and chase butterflies.`,
	},
	{
		blogName: "Aeternal Basil",
		blogCaption: `Miss Sheila and Mr. B are long time residents of Tracy Towers who came to the James Baldwin Outdoor Learning Center Farmers Market for the first time last Saturday.  They were delight to find a community garden, farmers market and community meeting place a short walk from their home.  I had a lovely conversation with Mr.B about growing up in South Carolina, his military service and his service to his community as a church leader and foster parent. But the highlight for me was picking fresh basil from the garden for Miss Sheila's fish dish.  The JBOLC herb garden is in bloom...`,
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20210925_113907_480x480.jpg?v=1632930062",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<img
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20210925_113907_480x480.jpg?v=1632930062"
						alt=""
						className="mb-20"
					/>
				</p>
				<p>
					Miss Sheila and Mr. B are long time residents of Tracy Towers who came
					to the James Baldwin Outdoor Learning Center Farmers Market for the
					first time last Saturday.&nbsp; They were delight to find a community
					garden, farmers market and community meeting place a short walk from
					their home.&nbsp; I had a lovely conversation with Mr.B about growing
					up in South Carolina, his military service and his service to his
					community as a church leader and foster parent. But the highlight for
					me was picking fresh basil from the garden for Miss Sheila's fish
					dish.&nbsp; The JBOLC herb garden is in bloom...
				</p>
				<p>
					<img
						src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/20210925_113814_480x480.jpg?v=1632930031"
						alt=""
						className="mt-20"
					/>
				</p>
			</div>
		),
	},
	{
		blogName: "Aeternal Mushrooms",
		blogCaption: `We have been immersed in Climate Week NYC activities  this week. In the park Monday morning, five minutes away from the last stop on the number #1 train, I came across this mushroom. This area of  Van Cortlandt Park was flooded by Hurricane Ida earlier this month. Fungi have been around for a billion years and they know a thing or two about partnership, adaptability, regeneration and design.`,
		blogImage:
			"https://cdn.shopify.com/s/files/1/0245/1118/4980/files/IMG_20210922_101710_552_480x480.jpg?v=1632506476",
		blogHTML: (
			<div class="custom-blog">
				<p>
					<span>
						<img
							src="https://cdn.shopify.com/s/files/1/0245/1118/4980/files/IMG_20210922_101710_552_480x480.jpg?v=1632506476"
							alt=""
							width="504"
							height="504"
							className="mb-20"
						/>
					</span>
				</p>
				<p>
					<span>
						We have been immersed in Climate Week NYC activities&nbsp; this
						week. In the park Monday morning,&nbsp;five minutes away from the
						last stop on the number #1 train, I came across this mushroom. This
						area of&nbsp; Van Cortlandt Park was flooded by Hurricane Ida
						earlier this month. Fungi have been around for a billion years and
						they know a thing or two about partnership, adaptability,
						regeneration and design.
					</span>
				</p>
			</div>
		),
	},
];

const LandingPage = () => {
	const [artTypes, landingPageProducts] = useOutletContext();
	const navigate = useNavigate();

	const resolutionSelector = useSelector((state) => state.resolution);

	return (
		<>
			{resolutionSelector?.resolutionType.type === "mobile" ? (
				<div className="flex flex-col items-center">
					<div className="w-full bg-black flex flex-col items-center px-6 py-6 relative">
						<div className="w-full text-4xl font-bold text-white flex flex-col md:flex-row md:justify-center md:items-center md:gap-x-2 mb-4">
							Welcome to{" "}
							<span className={`text-5xl ${classes.company_name}`}>
								Aeternal Lovers
							</span>
						</div>
						<div className="text-xl md:text-2xl font-normal text-white mb-4">
							Dive into our world, where every note played and every stroke
							painted tells a story of unity, love, and the enduring spirit of
							our community.
						</div>
						<div className="flex flex-col items-center gap-2 w-full mb-2">
							<GradientButton
								className="text-lg md:text-xl w-full"
								title="Explore Music"
								icon={<EastIcon />}
								onClick={() => navigate("/app/sound")}
							/>
							<p
								className="text-[#6964B4] text-lg md:text-xl cursor-pointer font-medium flex gap-[10px] items-center"
								onClick={() =>
									navigate("/shop", {
										state: {
											artType: artTypes?.[0]?._id,
											artName: artTypes?.[0]?.artTypeName,
										},
									})
								}
							>
								Explore Merchandise{" "}
								<span>
									<EastIcon />
								</span>
							</p>
						</div>
						<div className="z-10">
							<img src={landingPagePills} alt="landingPagePills"/>
						</div>
						<div
							style={{
								background:
									"radial-gradient(circle at center, rgba(230, 53, 92, 0.5) 0%, transparent 50%)",
							}}
							className="w-full h-full absolute -bottom-[25%] -right-[40%]"
						/>
					</div>
					<div className="w-full bg-white z-10 px-6 py-12 flex flex-col">
						<p className="text-[#060709] text-5xl font-bold">Who are we?</p>
						<p className="text-black font-normal text-xl mt-[16px]">
							Aeternal Lovers Sound System is a Bronx-based sustainable living
							brand inspired by the people, nature, sights and sounds of New
							York City—past, present and future.
						</p>
						<p className="text-black font-normal text-xl mt-[16px]">
							Our virtual community of music lovers, music makers, models,
							artists, ers, and spoken word artists exists to inspire creation,
							collaboration, and regeneration within the Bronx, across the
							United States, in Jamaica, and around the world.
						</p>
						<p className="text-black font-normal text-xl mt-[16px]">
							From our upcycled, limited edition s to our collaborative Sound
							System concept, we foster community through art and music, helping
							people reconnect to themselves, each other, and our beautiful home
							planet.
						</p>
						<GradientButton
							title="Read More"
							className="mt-4"
							icon={<EastIcon />}
							onClick={() => navigate("/aboutUs")}
						/>
					</div>
					<div className="w-full px-6 py-12 flex flex-col">
						<h2 className="font-bold text-5xl text-white mb-4">What we do</h2>
						<p className="font-normal text-[#E4E4E4] text-xl mb-4">
							Aeternal Lovers is more than a community; it's a movement. We
							strive to create a space where creativity knows no bounds. Our
							essence is our people—talented, passionate, and united in the
							celebration and the promotion of sustainable living.
						</p>
						<div className="flex flex-col gap-4">
							<div
								className={classes.action_labels}
								onClick={() => navigate("/app/sound")}
							>
								<div className="flex items-center gap-[12px]">
									<svg
										width="68"
										height="68"
										viewBox="0 0 68 68"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M7.31001 26.6899C7.08334 26.6899 6.82834 26.6616 6.60168 26.5766C5.49668 26.1799 4.93001 24.9616 5.29834 23.8566C7.19668 18.5299 10.4833 13.8549 14.875 10.3133C15.7817 9.57659 17.1133 9.71826 17.85 10.6249C18.5867 11.5316 18.445 12.8633 17.5383 13.6283C13.7983 16.6599 10.9367 20.6833 9.32168 25.2733C9.01001 26.1516 8.18834 26.6899 7.31001 26.6899Z"
											fill="white"
										/>
										<path
											d="M7.30996 45.56C6.43163 45.56 5.60996 45.0217 5.29829 44.1433C4.13663 40.8567 3.54163 37.4283 3.54163 34C3.54163 32.8383 4.50496 31.875 5.66663 31.875C6.82829 31.875 7.79163 32.8383 7.79163 34C7.79163 36.9467 8.30163 39.8933 9.32163 42.7267C9.71829 43.8317 9.12329 45.05 8.01829 45.4467C7.79163 45.5317 7.53663 45.56 7.30996 45.56Z"
											fill="white"
										/>
										<path
											d="M34.0001 64.4586C30.9968 64.4586 28.0218 64.0053 25.1318 63.127C23.9984 62.787 23.3751 61.597 23.7151 60.4636C24.0551 59.3303 25.2451 58.707 26.3784 59.047C28.8434 59.812 31.4218 60.1803 34.0001 60.1803C48.4501 60.1803 60.2084 48.422 60.2084 33.972C60.2084 32.4986 60.0668 30.9686 59.7834 29.3536C59.5851 28.192 60.3501 27.087 61.5118 26.8886C62.6451 26.6903 63.7784 27.4553 63.9768 28.617C64.3168 30.487 64.4868 32.2436 64.4868 34.0003C64.4584 50.802 50.8018 64.4586 34.0001 64.4586Z"
											fill="white"
										/>
										<path
											d="M16.2067 58.0835C15.725 58.0835 15.2717 57.9418 14.875 57.6018C13.26 56.2985 11.9567 55.0518 10.8517 53.7768C10.0867 52.8985 10.2 51.5385 11.0783 50.7735C11.985 50.0085 13.3167 50.1218 14.0817 51.0002C15.0167 52.0768 16.15 53.1535 17.5383 54.2868C18.445 55.0235 18.5867 56.3552 17.85 57.2618C17.4533 57.8002 16.83 58.0835 16.2067 58.0835Z"
											fill="white"
										/>
										<path
											d="M57.3467 20.0883C56.6667 20.0883 56.015 19.7767 55.59 19.1533C50.7167 12.0417 42.6133 7.79166 34 7.79166C31.4217 7.79166 28.8433 8.15999 26.3783 8.92499C25.2733 9.26499 24.0833 8.64166 23.715 7.50832C23.3467 6.37499 23.9983 5.18499 25.1317 4.84499C28.0217 3.99499 30.9967 3.54166 34 3.54166C44.03 3.54166 53.4083 8.49999 59.1033 16.7733C59.755 17.7367 59.5283 19.0683 58.565 19.72C58.1967 19.975 57.7717 20.0883 57.3467 20.0883Z"
											fill="white"
										/>
										<path
											d="M45.4184 18.4164C44.4834 17.7081 42.7834 16.9997 40.0634 17.7364L31.025 20.1731C28.4184 20.9097 26.7184 23.1197 26.7184 25.8397V30.4864V37.7964C25.9817 37.5131 25.1884 37.3431 24.3384 37.3431C20.6834 37.3431 17.7084 40.3181 17.7084 43.9731C17.7084 47.6281 20.6834 50.6031 24.3384 50.6031C27.965 50.6031 30.8834 47.6847 30.94 44.0864C30.94 44.0581 30.9684 44.0297 30.9684 43.9731V32.1014L43.2084 28.7581V34.7931C42.4717 34.5097 41.6784 34.3397 40.8284 34.3397C37.1734 34.3397 34.1984 37.3147 34.1984 40.9697C34.1984 44.6247 37.1734 47.5997 40.8284 47.5997C44.4834 47.5997 47.4584 44.6247 47.4584 40.9697V25.9814V23.3747C47.4584 21.1081 46.7784 19.4364 45.4184 18.4164ZM24.3384 46.3531C23.035 46.3531 21.9584 45.2764 21.9584 43.9731C21.9584 42.6697 23.035 41.5931 24.3384 41.5931C25.6417 41.5931 26.7184 42.6697 26.7184 43.9731C26.7184 45.2764 25.6417 46.3531 24.3384 46.3531ZM40.8284 43.3497C39.525 43.3497 38.4484 42.2731 38.4484 40.9697C38.4484 39.6664 39.525 38.5897 40.8284 38.5897C42.1317 38.5897 43.2084 39.6664 43.2084 40.9697C43.2084 42.2731 42.1317 43.3497 40.8284 43.3497Z"
											fill="white"
										/>
									</svg>
									<div className="flex-col">
										<h3 className="font-bold text-[22px] text-white">
											Feel the Vibration
										</h3>
										<p className="font-normal text-white text-lg">
											Check out a sample of our video and Music content.
										</p>
									</div>
									<EastIcon />
								</div>
							</div>
							<div
								className={classes.action_labels}
								onClick={() => navigate("/app/community")}
							>
								<div className="flex items-center gap-[12px]">
									<svg
										width="69"
										height="68"
										viewBox="0 0 69 68"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M52.8316 47.685L53.9366 56.6383C54.2199 58.99 51.6983 60.6333 49.6866 59.415L39.8833 53.5783C39.2033 53.1817 39.0333 52.3317 39.4016 51.6517C40.8183 49.045 41.5833 46.0983 41.5833 43.1517C41.5833 32.7817 32.6866 24.3383 21.7499 24.3383C19.5116 24.3383 17.3299 24.6783 15.2899 25.3583C14.2416 25.6983 13.2216 24.735 13.4766 23.6583C16.0549 13.345 25.9716 5.66666 37.8149 5.66666C51.6416 5.66666 62.8333 16.1217 62.8333 29.0133C62.8333 36.6633 58.8949 43.435 52.8316 47.685Z"
											fill="white"
										/>
										<path
											d="M37.3333 43.1516C37.3333 46.5233 36.0866 49.64 33.99 52.105C31.185 55.505 26.7366 57.6867 21.75 57.6867L14.355 62.0783C13.1083 62.8433 11.5216 61.795 11.6916 60.35L12.4 54.7683C8.60329 52.1333 6.16663 47.9116 6.16663 43.1516C6.16663 38.165 8.82996 33.7733 12.91 31.1667C15.4316 29.5233 18.4633 28.5883 21.75 28.5883C30.3633 28.5883 37.3333 35.105 37.3333 43.1516Z"
											fill="white"
										/>
									</svg>

									<div className="flex-col">
										<h3 className="font-bold text-[22px] text-white">
											Join our Community
										</h3>
										<p className="font-normal text-white text-lg">
											Enlist today, where individuals with similar interests
											unite.
										</p>
									</div>
									<EastIcon />
								</div>
							</div>
						</div>
					</div>
					<div className="w-full px-6 py-12 bg-[#F9F9FF] flex flex-col">
						<div className="font-bold text-[#060709] text-5xl mb-4">
							Check Our Best & Inspired
						</div>
						<GradientButton
							className="mb-4"
							title="View All"
							icon={<EastIcon />}
							onClick={() =>
								navigate("/shop", {
									state: {
										artType: artTypes?.[0]?._id,
										artName: artTypes?.[0]?.artTypeName,
									},
								})
							}
						/>
						<ProductsSection
							className="flex flex-wrap gap-y-[52px] gap-x-[18px]"
							noCTA={true}
							products={landingPageProducts?.products}
						/>
					</div>
					<div className="w-full px-6 py-12 bg-[#FFF1F4] flex flex-col">
						<div className="text-4xl font-semibold text-black mb-2">
							Have an <span className="text-[#E6355C]">Idea</span> for a
							one-of-a-kind look?
						</div>
						<p className="text-xl text-black font-normal mb-6">
							Let's create a custom piece together using items already found in
							your wardrobe.
						</p>
						<GradientButton
							title="Select your Design"
							icon={<EastIcon />}
							onClick={() => navigate("/customDesign")}
						/>
					</div>
					<div className="w-full bg-white flex flex-col items-center px-6 py-12">
						<img src={musicCollage} style={{ marginBottom: "2rem" }} alt="musicCollage"/>
						<div className="flex flex-col gap-[32px]">
							<h2 className="font-bold text-5xl text-black">Music</h2>
							<p className="font-normal text-[#060709] text-xl">
								Immerse yourself in the soulful sounds of Aeternal Lovers. Our
								music streaming web-app is a digital concert hall, showcasing
								the extraordinary talents of our community members. Discover new
								favorites, support local artists, and let the power of music
								connect you to the heart of our community.
							</p>
							<GradientButton
								title="Feel the Vibrations"
								icon={<EastIcon />}
								onClick={() => navigate("/app/sound")}
							/>
						</div>
					</div>
					<div className="w-full bg-white flex flex-col px-6 py-12">
						<img src={playingInstrument} style={{ marginBottom: "2rem" }} alt="playingInstrument"/>
						<div className="flex flex-col gap-[32px]">
							<h2 className="font-bold text-5xl text-black">Community</h2>
							<p className="font-normal text-[#060709] text-xl">
								At the core of Aeternal Lovers is our vibrant community. Our
								web-app brings members together in a dynamic space, where
								conversations flow, ideas blossom, and connections are made.
								Create channels for your interests, and share resources. Here,
								every member is a vital thread in the fabric of our community,
								weaving together stories of support and shared values.
							</p>
							<GradientButton
								title="Join the Community"
								icon={<EastIcon />}
								onClick={() => navigate("/app/community")}
							/>
						</div>
					</div>
					<div className="w-full bg-white px-6 py-12">
						<TopSellers products={landingPageProducts?.topSellers} />
					</div>
					<div className="px-6 py-12 bg-white">
						<div className="flex flex-col justify-between">
							<h3 className="font-bold text-[#060709] text-[36px] mb-4 text-center">
								Feature Blog Posts
							</h3>
							<GradientButton
								title="View All"
								icon={<EastIcon />}
								onClick={() => navigate("/blogs")}
							/>
						</div>
						<div className="flex flex-col py-6 gap-5">
							{blogContent.map((blogDetails, ind) => (
								<div
									className="max-w-full flex flex-col gap-4 cursor-pointer overflow-hidden"
									// onClick={() => setBlogSelected(ind + 1)}
									onClick={() =>
										navigate("/blogs", { state: { selectedBlog: ind + 1 } })
									}
								>
									<img
										src={blogDetails?.blogImage}
										className="h-[381px] w-full object-cover"
										alt="BlogImage"
									/>
									<h3 className="font-semibold text-[20px] text-[#060709]">
										{blogDetails?.blogName}
									</h3>
									<p className="text-[#858585] font-normal text-base truncate-multiline">
										{blogDetails?.blogCaption}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col">
					<div className="flex items-center">
						<div className="w-1/2 flex justify-center">
							<div className="flex flex-col gap-[32px] items-center justify-center w-[580px]">
								<h2 className="text-5xl text-nowrap relative text-white font-bold">
									Welcome to{" "}
									<span className={classes.company_name}>Aeternal Lovers</span>
									<svg
										className="absolute top-[-19px] right-[-32px]"
										width="31"
										height="31"
										viewBox="0 0 31 31"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M24.6193 6.32081L25.9561 19.3066C26.1904 21.582 24.5307 23.6227 22.2553 23.8569C19.9914 24.09 17.9392 22.4315 17.705 20.1561C17.4719 17.8922 19.1431 15.8503 21.407 15.6173C22.3723 15.5179 23.2685 15.7624 24.0263 16.2419L23.3224 9.40419L13.0483 13.5047L14.0031 22.7786C14.2373 25.054 12.5661 27.0958 10.3022 27.3289C8.02683 27.5632 5.98616 25.9034 5.75191 23.628C5.51885 21.3641 7.17857 19.3235 9.45396 19.0892C10.4078 18.991 11.304 19.2356 12.0606 19.7035L11.0148 9.54471C10.8409 7.8554 11.7288 6.44004 13.3042 5.83653L19.7595 3.33701C21.0662 2.83085 22.2169 2.84014 23.0853 3.37788C23.9639 3.90295 24.4726 4.89581 24.6193 6.32081Z"
											fill="#E6355C"
										/>
									</svg>
								</h2>
								<p className="font-normal text-[#E4E4E4] text-2xl">
									Dive into our world, where every note played and every stroke
									painted tells a story of unity, love, and the enduring spirit
									of our community.
								</p>
								<div className="flex gap-[24px] w-full">
									<GradientButton
										title="Explore Music"
										icon={<EastIcon />}
										onClick={() => navigate("/app/sound")}
									/>
									<p
										className="text-[#6964B4] text-xl cursor-pointer font-medium flex gap-[10px] items-center"
										onClick={() =>
											navigate("/shop", {
												state: {
													artType: artTypes?.[0]?._id,
													artName: artTypes?.[0]?.artTypeName,
												},
											})
										}
									>
										Explore Merchandise{" "}
										<span>
											<EastIcon />
										</span>
									</p>
								</div>
							</div>
						</div>
						<div className="w-1/2 relative">
							<img className="my-16 z-10 sticky" src={landingPagePills} alt="LandingPills"/>
							<img className="absolute top-[-20px]" src={gradient} alt="Gradient"/>
						</div>
					</div>
					<div className="flex items-center bg-white">
						<div className="w-1/2 flex flex-col pl-[12%]">
							<h2 className="text-[#060709] text-5xl font-bold">Who are we?</h2>
							<p className="text-black font-normal text-xl mt-[16px]">
								Aeternal Lovers Sound System is a Bronx-based sustainable living
								brand inspired by the people, nature, sights and sounds of New
								York City—past, present and future.
								<br />
								<br />
								<span>
									Our virtual community of music lovers, music makers, models,
									artists, ers, and spoken word artists exists to inspire
									creation, collaboration, and regeneration within the Bronx,
									across the United States, in Jamaica, and around the world.
								</span>
								<br />
								<br />
								<span>
									From our upcycled, limited edition s to our collaborative
									Sound System concept, we foster community through art and
									music, helping people reconnect to themselves, each other, and
									our beautiful home planet.
								</span>
							</p>
							<GradientButton
								title="Read More"
								className="mt-4"
								icon={<EastIcon />}
								onClick={() => navigate("/aboutUs")}
							/>
						</div>
						<div className="w-1/2 flex justify-center">
							<img className="my-[120px]" src={audioSketch} alt="audioSketch"/>
						</div>
					</div>
					<div className="py-[120px] flex justify-center">
						<div className="w-1/2  flex">
							<div className="flex flex-col items-center gap-[48px]">
								<h2 className="font-bold text-5xl text-white">What we do</h2>
								<p className="font-normal text-[#E4E4E4] text-xl text-center">
									Aeternal Lovers is more than a community; it's a movement. We
									strive to create a space where creativity knows no bounds. Our
									essence is our people—talented, passionate, and united in the
									celebration and the promotion of sustainable living.
								</p>
								<div className="flex gap-[21px]">
									<div
										className={classes.action_labels}
										onClick={() => navigate("/app/sound")}
									>
										<div className="flex items-center gap-[12px]">
											<svg
												width="68"
												height="68"
												viewBox="0 0 68 68"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M7.31001 26.6899C7.08334 26.6899 6.82834 26.6616 6.60168 26.5766C5.49668 26.1799 4.93001 24.9616 5.29834 23.8566C7.19668 18.5299 10.4833 13.8549 14.875 10.3133C15.7817 9.57659 17.1133 9.71826 17.85 10.6249C18.5867 11.5316 18.445 12.8633 17.5383 13.6283C13.7983 16.6599 10.9367 20.6833 9.32168 25.2733C9.01001 26.1516 8.18834 26.6899 7.31001 26.6899Z"
													fill="white"
												/>
												<path
													d="M7.30996 45.56C6.43163 45.56 5.60996 45.0217 5.29829 44.1433C4.13663 40.8567 3.54163 37.4283 3.54163 34C3.54163 32.8383 4.50496 31.875 5.66663 31.875C6.82829 31.875 7.79163 32.8383 7.79163 34C7.79163 36.9467 8.30163 39.8933 9.32163 42.7267C9.71829 43.8317 9.12329 45.05 8.01829 45.4467C7.79163 45.5317 7.53663 45.56 7.30996 45.56Z"
													fill="white"
												/>
												<path
													d="M34.0001 64.4586C30.9968 64.4586 28.0218 64.0053 25.1318 63.127C23.9984 62.787 23.3751 61.597 23.7151 60.4636C24.0551 59.3303 25.2451 58.707 26.3784 59.047C28.8434 59.812 31.4218 60.1803 34.0001 60.1803C48.4501 60.1803 60.2084 48.422 60.2084 33.972C60.2084 32.4986 60.0668 30.9686 59.7834 29.3536C59.5851 28.192 60.3501 27.087 61.5118 26.8886C62.6451 26.6903 63.7784 27.4553 63.9768 28.617C64.3168 30.487 64.4868 32.2436 64.4868 34.0003C64.4584 50.802 50.8018 64.4586 34.0001 64.4586Z"
													fill="white"
												/>
												<path
													d="M16.2067 58.0835C15.725 58.0835 15.2717 57.9418 14.875 57.6018C13.26 56.2985 11.9567 55.0518 10.8517 53.7768C10.0867 52.8985 10.2 51.5385 11.0783 50.7735C11.985 50.0085 13.3167 50.1218 14.0817 51.0002C15.0167 52.0768 16.15 53.1535 17.5383 54.2868C18.445 55.0235 18.5867 56.3552 17.85 57.2618C17.4533 57.8002 16.83 58.0835 16.2067 58.0835Z"
													fill="white"
												/>
												<path
													d="M57.3467 20.0883C56.6667 20.0883 56.015 19.7767 55.59 19.1533C50.7167 12.0417 42.6133 7.79166 34 7.79166C31.4217 7.79166 28.8433 8.15999 26.3783 8.92499C25.2733 9.26499 24.0833 8.64166 23.715 7.50832C23.3467 6.37499 23.9983 5.18499 25.1317 4.84499C28.0217 3.99499 30.9967 3.54166 34 3.54166C44.03 3.54166 53.4083 8.49999 59.1033 16.7733C59.755 17.7367 59.5283 19.0683 58.565 19.72C58.1967 19.975 57.7717 20.0883 57.3467 20.0883Z"
													fill="white"
												/>
												<path
													d="M45.4184 18.4164C44.4834 17.7081 42.7834 16.9997 40.0634 17.7364L31.025 20.1731C28.4184 20.9097 26.7184 23.1197 26.7184 25.8397V30.4864V37.7964C25.9817 37.5131 25.1884 37.3431 24.3384 37.3431C20.6834 37.3431 17.7084 40.3181 17.7084 43.9731C17.7084 47.6281 20.6834 50.6031 24.3384 50.6031C27.965 50.6031 30.8834 47.6847 30.94 44.0864C30.94 44.0581 30.9684 44.0297 30.9684 43.9731V32.1014L43.2084 28.7581V34.7931C42.4717 34.5097 41.6784 34.3397 40.8284 34.3397C37.1734 34.3397 34.1984 37.3147 34.1984 40.9697C34.1984 44.6247 37.1734 47.5997 40.8284 47.5997C44.4834 47.5997 47.4584 44.6247 47.4584 40.9697V25.9814V23.3747C47.4584 21.1081 46.7784 19.4364 45.4184 18.4164ZM24.3384 46.3531C23.035 46.3531 21.9584 45.2764 21.9584 43.9731C21.9584 42.6697 23.035 41.5931 24.3384 41.5931C25.6417 41.5931 26.7184 42.6697 26.7184 43.9731C26.7184 45.2764 25.6417 46.3531 24.3384 46.3531ZM40.8284 43.3497C39.525 43.3497 38.4484 42.2731 38.4484 40.9697C38.4484 39.6664 39.525 38.5897 40.8284 38.5897C42.1317 38.5897 43.2084 39.6664 43.2084 40.9697C43.2084 42.2731 42.1317 43.3497 40.8284 43.3497Z"
													fill="white"
												/>
											</svg>
											<div className="flex-col">
												<h3 className="font-bold text-[22px] text-white">
													Feel the Vibration
												</h3>
												<p className="font-normal text-white text-lg">
													Check out a sample of our video and Music content.
												</p>
											</div>
											<EastIcon />
										</div>
									</div>
									<div
										className={classes.action_labels}
										onClick={() => navigate("/app/community")}
									>
										<div className="flex items-center gap-[12px]">
											<svg
												width="69"
												height="68"
												viewBox="0 0 69 68"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M52.8316 47.685L53.9366 56.6383C54.2199 58.99 51.6983 60.6333 49.6866 59.415L39.8833 53.5783C39.2033 53.1817 39.0333 52.3317 39.4016 51.6517C40.8183 49.045 41.5833 46.0983 41.5833 43.1517C41.5833 32.7817 32.6866 24.3383 21.7499 24.3383C19.5116 24.3383 17.3299 24.6783 15.2899 25.3583C14.2416 25.6983 13.2216 24.735 13.4766 23.6583C16.0549 13.345 25.9716 5.66666 37.8149 5.66666C51.6416 5.66666 62.8333 16.1217 62.8333 29.0133C62.8333 36.6633 58.8949 43.435 52.8316 47.685Z"
													fill="white"
												/>
												<path
													d="M37.3333 43.1516C37.3333 46.5233 36.0866 49.64 33.99 52.105C31.185 55.505 26.7366 57.6867 21.75 57.6867L14.355 62.0783C13.1083 62.8433 11.5216 61.795 11.6916 60.35L12.4 54.7683C8.60329 52.1333 6.16663 47.9116 6.16663 43.1516C6.16663 38.165 8.82996 33.7733 12.91 31.1667C15.4316 29.5233 18.4633 28.5883 21.75 28.5883C30.3633 28.5883 37.3333 35.105 37.3333 43.1516Z"
													fill="white"
												/>
											</svg>

											<div className="flex-col">
												<h3 className="font-bold text-[22px] text-white">
													Join our Community
												</h3>
												<p className="font-normal text-white text-lg">
													Enlist today, where individuals with similar interests
													unite.
												</p>
											</div>
											<EastIcon />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="py-[120px] bg-[#F9F9FF] flex flex-col">
						<div className="flex justify-between px-[12.25%] items-end">
							<h3 className="font-bold text-[#060709] text-[48px]">
								Check Our
								<br /> Best & Inspired
							</h3>
							<GradientButton
								title="View All"
								icon={<EastIcon />}
								onClick={() =>
									navigate("/shop", {
										state: {
											artType: artTypes?.[0]?._id,
											artName: artTypes?.[0]?.artTypeName,
										},
									})
								}
							/>
						</div>
						<ProductsSection
							noCTA={true}
							products={landingPageProducts?.products}
						/>
					</div>
					<div className="bg-white">
						<UniqueDesignBanner />
					</div>
					<div className="bg-white flex items-center py-[120px]">
						<div className="w-1/2 flex justify-center">
							<div className="flex w-1/2 flex-col gap-[32px]">
								<h2 className="font-bold text-5xl text-black">Music</h2>
								<p className="font-normal text-[#060709] text-xl">
									Immerse yourself in the soulful sounds of Aeternal Lovers. Our
									music streaming web-app is a digital concert hall, showcasing
									the extraordinary talents of our community members. Discover
									new favorites, support local artists, and let the power of
									music connect you to the heart of our community.
								</p>
								<GradientButton
									title="Feel the Vibrations"
									icon={<EastIcon />}
									onClick={() => navigate("/app/sound")}
								/>
							</div>
						</div>
						<div className="w-1/2 flex">
							<img src={musicCollage} alt="musicCollage"/>
						</div>
					</div>
					<div className="bg-white flex py-[120px]">
						<div className="w-1/2 flex justify-end">
							<img src={playingInstrument} alt="playingInstrument"/>
						</div>
						<div className="w-1/2 flex justify-center">
							<div className="flex w-1/2 flex-col justify-center gap-[32px]">
								<h2 className="font-bold text-5xl text-black">Community</h2>
								<p className="font-normal text-[#060709] text-xl">
									At the core of Aeternal Lovers is our vibrant community. Our
									web-app brings members together in a dynamic space, where
									conversations flow, ideas blossom, and connections are made.
									Create channels for your interests, and share resources. Here,
									every member is a vital thread in the fabric of our community,
									weaving together stories of support and shared values.
								</p>
								<GradientButton
									title="Join the Community"
									icon={<EastIcon />}
									onClick={() => navigate("/app/community")}
								/>
							</div>
						</div>
					</div>
					<div className="px-[12.25%] bg-white">
						<TopSellers products={landingPageProducts?.topSellers} />
					</div>
					<div className="px-[12.25%] py-[120px] bg-white">
						<div className="flex items-center justify-between">
							<h3 className="font-bold text-[#060709] text-[48px]">
								Feature Blog Posts
							</h3>
							<GradientButton
								title="View All"
								icon={<EastIcon />}
								onClick={() => navigate("/blogs")}
							/>
						</div>
						<div className="flex py-16 gap-5">
							{blogContent.map((blogDetails, ind) => (
								<div
									className="max-w-[345px] flex flex-col gap-4 cursor-pointer overflow-hidden"
									// onClick={() => setBlogSelected(ind + 1)}
									onClick={() =>
										navigate("/blogs", { state: { selectedBlog: ind + 1 } })
									}
								>
									<img
										src={blogDetails?.blogImage}
										className="h-[381px] w-[345px] object-cover"
										alt="BlogImage"
									/>
									<h3 className="font-semibold text-[20px] h-[60px] text-[#060709]">
										{blogDetails?.blogName}
									</h3>
									<p className="text-[#858585] font-normal text-base truncate-multiline">
										{blogDetails?.blogCaption}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LandingPage;
