import React from "react";
import classes from "./CollabBanner.module.css";
import GradientButton from "../../../../components/GradientButton/GradientButton";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CollabBanner = () => {
  const navigate = useNavigate();
  const { resolutionType } = useSelector((state) => state.resolution);
  const { userData } = useSelector((state) => state?.user);
  return (
    <div
      className={`flex flex-col w-full ${resolutionType.type === "mobile"
          ? `px-8 py-16 ${classes.mobileBanner}`
          : `p-[56px] rounded-[16px] ${classes.banner}`
        }`}
    >
      <div
        className={`font-semibold text-black ${resolutionType.type === "mobile" ? "mb-4 text-3xl" : "text-[30px]"
          }`}
      >
        Always looking to Collab
      </div>
      <p
        className={`font-normal text-xl ${resolutionType.type === "mobile" ? "w-full" : "w-1/2"
          }`}
      >
        Delighted to partner with you! Initiating collaboration is a breeze –
        simply complete a form to connect with us effortlessly.
      </p>
      <GradientButton
        className="mt-[24px]"
        title="Collab with Us"
        icon={<EastIcon />}
        onClick={() => {
          if (!userData?._id) {
            navigate("/login");
            return;
          }
          // navigate("/app/sound", { state: { modalOpen: true } });
          navigate("/collabWithUs")
        }}
      />
    </div>
  );
};

export default CollabBanner;
