import { React, useState } from "react";
import GradientButton from "../components/GradientButton/GradientButton";
import LooperGroup from "../assets/LooperGroup.png";
import CompanyLogo from "../assets/company_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../api/api_calls/auth";
import { toast } from "react-toastify";
import { loginSchema } from "../validations/auth";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../redux/reducers/user";
import CustomInput from "../components/CustomInput/CustomInput";
import { handleApiError } from "../utils/helperFunctions";
import { fetchCart } from "../redux/reducers/cart";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { resolutionType } = useSelector((state) => state.resolution);

	const { state } = useLocation();
	const { loading } = useSelector((state) => state.loader);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [formError, setFormError] = useState({});

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formData = {
			email,
			password,
		};

		const { error } = loginSchema.validate(formData);

		if (error) {
			// console.log({ error });
			setFormError({ [error?.details?.[0]?.context?.key]: error?.message });
			return;
		}

		login(formData)
			.then((res) => {
				localStorage.setItem(
					"AL_USER",
					JSON.stringify({ accessToken: res?.data?.data?.accessToken })
				);
				dispatch(userActions.storeUser(res?.data?.data));
				dispatch(fetchCart());
				if (state?.from) {
					navigate(state?.from);
				} else navigate("/");
				// console.log("LOGGED IN", res);
			})
			.catch((error) => {
				handleApiError(error, setFormError);
				// toast.error(error?.message, { theme: "dark" });
				// console.log("NOT LOGGED IN", error);
			});
	};

	return (
		<div className="w-full h-full bg-[#FFF1F4] flex items-center justify-center">
			<div
				className={`flex flex-col md:flex-row py-[5%] box-border ${resolutionType.type === "mobile" ? "mx-6" : "mx-[15%]"
					}`}
			>
				<form
					className={`py-16 flex-col bg-[#FFFFFF] md:w-full ${resolutionType.type === "mobile" ? "px-8" : "w-1/2 pr-40 pl-8"
						}`}
					onSubmit={handleSubmit}
				>
					<div className="font-bold text-3xl mb-1">Login to your Account</div>
					<div className="font-normal text-base mb-5">
						To log in to your account or register a new account please enter
						your credentials below
					</div>
					<div className="font-medium text-lg mb-2">Email Address</div>
					{/* <input
            className="w-full text-base font-normal rounded-lg py-4 px-2 mb-5 border border-zinc-200"
            type="text"
            placeholder="Enter Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
					<CustomInput
						type="text"
						placeholder="Enter Email Address"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						error={formError?.email}
					/>
					<div className="font-medium text-lg mt-5 mb-2">Password</div>
					{/* <input
            className="w-full text-base font-normal rounded-lg py-4 px-2 mb-1 border border-zinc-200"
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
					<CustomInput
						type="password"
						placeholder="Enter Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						error={formError?.password}
					/>
					<div className="flex justify-end mt-1 font-medium mb-5">
						<div
							className="text-[#6964B4] cursor-pointer"
							onClick={() => navigate("/forgot")}
						>
							Forgot Password?
						</div>
					</div>
					<GradientButton
						type="submit"
						style={{ width: "100%", justifyContent: "center" }}
						title={"Login"}
						loading={loading}
					/>
				</form>
				<div
					className={`py-20 text-white box-border flex flex-col ${resolutionType.type === "mobile"
							? "px-8"
							: "w-1/2 md:w-full pr-20 pl-8"
						}`}
					style={{
						background: `url(${LooperGroup}) 5% 0%/40rem no-repeat, linear-gradient(90deg, #e93359 0%, #6167ba 100%)`,
					}}
				>
					{resolutionType.type !== "mobile" && (
						<img
							src={CompanyLogo}
							style={{ width: "1.5rem", marginBottom: "1.625rem" }}
						/>
					)}
					<div className="font-bold text-3xl mb-1">Create Your Account</div>
					<div className="font-normal text-lg mb-5">
						Embark on a seamless journey by creating your personalized account.
						Simply navigate to our website and click on the "Register Now"
						button to begin.
					</div>
					<button
						className={`bg-white py-2 px-14 mb-5 font-semibold text-lg text-[#E6355C] rounded-[160px] ${resolutionType.type !== "mobile" && "w-64"
							}`}
						onClick={() => navigate("/register")}
					>
						Register Now
					</button>
					<div className="font-semibold">
						Having issues with login or creating an account?
					</div>
					<span
						className="text-decoration-line: underline font-semibold"
					// onClick={() => navigate("/")}
					>
						Click Here
					</span>
				</div>
			</div>
		</div>
	);
};

export default Login;
