import { useState, useEffect, useContext, useCallback } from "react";
import UploadMusicCard from "../UploadMusicCard/UploadMusicCard";
import BookBandCard from "../BookBandCard/BookBandCard";
import TableMusic from "../TableMusic/TableMusic";
import { PlayerContext } from "../Sound";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Avatar, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

import { latestMusic } from "../../../../api/api_calls/music";

function Latest({ fetchLatestRef }) {
	const { open } = useContext(PlayerContext);
	const navigate = useNavigate();
	const [setCollabReqModal, render, setRender] = useOutletContext();

	const { resolutionType } = useSelector((state) => state.resolution);

	// latest music work
	const [latestArr, setLatestArr] = useState(null);
	const [latestLoad, setLatestLoad] = useState(false);
	const [artistArr, setArtistArr] = useState(null);
	const [artistLoad, setArtistLoad] = useState(true);
	const [currPage, setCurrPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [load, setLoad] = useState(true);
	const [moreLoad, setMoreLoad] = useState(false);
	const [debounce, setDebounce] = useState(false);

	// const fetchMore = useCallback(() => {
	// 	console.log("LATEST ARR IN FETCH MORE: ", latestArr);
	// 	if (currPage < totalPages) {
	// 		console.log("NEED TO FETCH", currPage, totalPages);
	// 	} else {
	// 		console.log("NO NEED TO FETCH", currPage, totalPages);
	// 	}
	// }, [currPage, totalPages]);

	// const fetchMore = useCallback(() => {

	// }, [currPage, totalPages, moreLoad]);

	const fetchMore = () => {
		if (currPage + 1 > totalPages || debounce) {
			// if (debounce) console.log("DEBOUNCED!");
			return;
		}

		setDebounce(true);

		const newPage = currPage + 1;

		latestMusic({ page: newPage, limit: 10 })
			.then((res) => {
				setCurrPage(res?.data?.currentPage);
				setTotalPages(res?.data?.totalPages);
				setTotalCount(res?.data?.totalCount);

				if (res?.data?.currentPage === res?.data?.totalPages)
					setMoreLoad(false);

				// latest music
				const tempLatest = res?.data?.data?.latestMusic?.map((item, index) => {
					let tempObj = {
						title: item?.title,
						artist: `${item?.artist?.firstName + " "}${item?.artist?.lastName}`,
						isYoutube: item?.isYoutube,
						duration: item?.playTime,
					};

					if (item?.isYoutube) {
						tempObj["youtube"] = {
							url: item?.youtubeUrl,
							thumbnail: item?.thumbnailImages?.youtubeThumbnail,
						};
					} else {
						tempObj["audio"] = {
							src: item?.musicUrl,
							thumbnail: item?.thumbnailImages?.musicThumbnail,
						};
						if ("videoUrl" in item) {
							tempObj["video"] = {
								src: item?.videoUrl,
								thumbnail: item?.thumbnailImages?.videoThumbnail,
							};
						}
					}
					return tempObj;
				});

				setLatestArr([...latestArr, ...tempLatest]);
			})
			.finally(() => {
				setDebounce(false);
			});
	};

	const fetchLatestMusicAllArtists = async () => {
		return latestMusic({ page: 1, limit: 10 }).then((res) => {
			setCurrPage(res?.data?.currentPage);
			setTotalPages(res?.data?.totalPages);
			setTotalCount(res?.data?.totalCount);

			if (res?.data?.currentPage < res?.data?.totalPages) setMoreLoad(true);

			// latest music
			const tempLatest = res?.data?.data?.latestMusic?.map((item, index) => {
				let tempObj = {
					title: item?.title,
					artist: `${item?.artist?.firstName + " "}${item?.artist?.lastName}`,
					isYoutube: item?.isYoutube,
					duration: item?.playTime,
				};

				if (item?.isYoutube) {
					tempObj["youtube"] = {
						url: item?.youtubeUrl,
						thumbnail: item?.thumbnailImages?.youtubeThumbnail,
					};
				} else {
					tempObj["audio"] = {
						src: item?.musicUrl,
						thumbnail: item?.thumbnailImages?.musicThumbnail,
					};
					if ("videoUrl" in item) {
						tempObj["video"] = {
							src: item?.videoUrl,
							thumbnail: item?.thumbnailImages?.videoThumbnail,
						};
					}
				}
				return tempObj;
			});

			setLatestArr([...tempLatest]);
			setRender(false);

			// all artists
			const tempArtist = res?.data?.data?.artist.map((item, index) => {
				return {
					id: item?._id,
					fullName: `${item.firstName} ${item.lastName}`,
					pic: item.profilePicture,
				};
			});
			setArtistArr([...tempArtist]);
		});
	};

	useEffect(() => {
		if (render) {
			// console.log("SET LOAD IN RENDER");
			// setArtistLoad(true);
			fetchLatestMusicAllArtists()
				.then(() => {})
				.finally(() => {
					setArtistLoad(false);
					setLoad(false);
				});
		}
	}, [render]);

	useEffect(() => {
		// console.log("SET LOAD");
		// setArtistLoad(true);
		fetchLatestMusicAllArtists()
			.then(() => {})
			.finally(() => {
				setArtistLoad(false);
				setLoad(false);
			});
	}, []);

	const skeletons = Array(10).fill(0);

	return (
		<>
			{resolutionType.type === "mobile" ? (
				<div
					className="w-full flex flex-col p-2 overflow-y-auto gap-y-[40px]"
					style={{ height: [open ? "calc(100% - 10.75rem)" : "100%"] }}
				>
					<div className=" max-w-full py-[1rem] bg-[#FFF1F4] rounded-[12px] flex flex-col justify-center gap-y-4">
						<h3 className="text-black font-bold text-xl ml-4">All Artists</h3>
						<div className="flex box-border gap-x-8 overflow-x-auto ml-4 hidden-scroll">
							{artistLoad ? (
								<>
									<div className="shrink-0 flex flex-col justify-between items-center h-full aspect-[0.8]">
										<div className="w-full aspect-square flex justify-center items-center">
											<Skeleton
												variant="circular"
												width={"100%"}
												height={"100%"}
											/>
										</div>
										<Skeleton
											variant="rectangular"
											width={"80%"}
											height={"1rem"}
										/>
									</div>
									<div className="shrink-0 flex flex-col justify-between items-center h-full aspect-[0.8]">
										<div className="w-full aspect-square flex justify-center items-center">
											<Skeleton
												variant="circular"
												width={"100%"}
												height={"100%"}
											/>
										</div>
										<Skeleton
											variant="rectangular"
											width={"80%"}
											height={"1rem"}
										/>
									</div>
									<div className="shrink-0 flex flex-col justify-between items-center h-full aspect-[0.8]">
										<div className="w-full aspect-square flex justify-center items-center">
											<Skeleton
												variant="circular"
												width={"100%"}
												height={"100%"}
											/>
										</div>
										<Skeleton
											variant="rectangular"
											width={"80%"}
											height={"1rem"}
										/>
									</div>
									<div className="shrink-0 flex flex-col justify-between items-center h-full aspect-[0.8]">
										<div className="w-full aspect-square flex justify-center items-center">
											<Skeleton
												variant="circular"
												width={"100%"}
												height={"100%"}
											/>
										</div>
										<Skeleton
											variant="rectangular"
											width={"80%"}
											height={"1rem"}
										/>
									</div>
								</>
							) : (
								<>
									{artistArr ? (
										<>
											{artistArr?.map((item, index) => {
												return (
													<div
														style={{
															flexShrink: "0",
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
														key={index}
														onClick={() => {
															navigate(`artist/${item?.id}`, {
																state: {
																	firstName: item?.fullName.split(" ")[0],
																	lastName: item?.fullName.split(" ")[1],
																	pfp: item?.pic,
																},
															});
														}}
													>
														<Avatar
															className="w-full"
															src={item.pic}
															alt={`${item.fullName.split(" ")[0][0]}${
																item.fullName.split(" ")[1][0]
															}`}
															sx={{ fontSize: "16px", width: 56, height: 56 }}
														>
															{item.fullName.split(" ")[0][0]}
															{item.fullName.split(" ")[1][0]}
														</Avatar>
														<p className="font-semibold text-[10.23px] text-center">
															{item.fullName}
														</p>
													</div>
												);
											})}
										</>
									) : (
										<>
											<span className="block text-lg font-bold">
												No Artists
											</span>
										</>
									)}
								</>
							)}
						</div>
					</div>
					<div className="min-h-[400px] max-w-full overflow-x-auto">
						<div className="flex box-border gap-x-2">
							<UploadMusicCard {...{ setCollabReqModal }} />
							<BookBandCard />
						</div>
					</div>
					<div className="flex-shrink-0 flex flex-col h-[45vh]">
						<h3 className="text-black font-bold text-xl">Latest Music</h3>
						<div className="w-full h-0 grow my-2 flex">
							<TableMusic
								{...{
									labels: [
										"#",
										"Title",
										"Play Time",
										"Artist/Creator",
										"Action",
									],
									content: latestArr,
									playType: "latest",
									currPage, // only 1 in the UI
									limit: 10,
									fetchMore,
									totalPages,
									moreLoad,
									load,
								}}
							/>
						</div>
						{/* <div className="flex-shrink-0 grow max-w-full bg-black" /> */}
						<div
							className="w-full h-[2.5rem] bg-[#FFF1F4] text-[#E6355C] font-bold text-base text-center leading-10 cursor-pointer"
							onClick={() => navigate("list")}
						>
							View All
						</div>
					</div>
				</div>
			) : (
				<div
					className="grow flex w-full"
					style={{ height: [open ? "calc(100% - 4.75rem)" : "100%"] }}
				>
					<div className="flex h-full flex-col w-[70%] max-w-[70%] box-border p-[16px] gap-y-8">
						<div className="h-[28%] max-w-full py-[1rem] px-[32px] bg-[#FFF1F4] rounded-[12px] flex flex-col justify-center gap-y-4">
							<h3 className="text-black font-bold text-xl">All Artists</h3>
							<div className="flex box-border gap-x-8 overflow-x-auto w-full hidden-scroll grow">
								{artistLoad ? (
									<>
										{skeletons.map((_, index) => (
											<div
												key={index}
												className="shrink-0 flex flex-col justify-between items-center h-full aspect-[0.8]"
											>
												<div className="w-full aspect-square flex justify-center items-center">
													<Skeleton
														variant="circular"
														width={"100%"}
														height={"100%"}
													/>
												</div>
												<Skeleton
													variant="rectangular"
													width={"80%"}
													height={"1rem"}
												/>
											</div>
										))}
									</>
								) : (
									<>
										{artistArr ? (
											<>
												{artistArr?.map((item, index) => {
													return (
														<div
															className="aspect-[0.8] flex flex-col justify-between items-center cursor-pointer"
															key={index}
															onClick={() => {
																navigate(`artist/${item?.id}`, {
																	state: {
																		firstName: item?.fullName.split(" ")[0],
																		lastName: item?.fullName.split(" ")[1],
																		pfp: item?.pic,
																	},
																});
															}}
														>
															<div className="h-[80%] aspect-square flex flex-col justify-center items-center">
																<Avatar
																	src={item.pic}
																	alt={`${item.fullName.split(" ")[0][0]}${
																		item.fullName.split(" ")[1][0]
																	}`}
																	sx={{
																		fontSize: "16px",
																		width: "100%",
																		flexGrow: "1",
																		objectFit: "contain",
																	}}
																>
																	{item.fullName.split(" ")[0][0]}
																	{item.fullName.split(" ")[1][0]}
																</Avatar>
															</div>
															<span className="w-full max-h-8 text-center font-semibold text-sm truncate">
																{item.fullName}
															</span>
														</div>
													);
												})}
											</>
										) : (
											<>
												<span className="block text-lg font-bold">
													No Artists
												</span>
											</>
										)}
									</>
								)}
							</div>
						</div>
						<div className="flex flex-col h-[72%]">
							<h3 className="text-black font-bold text-xl">Latest Music</h3>
							<div className="w-full h-0 grow my-2 flex">
								<TableMusic
									{...{
										labels: [
											"#",
											"Title",
											"Play Time",
											"Artist/Creator",
											"Action",
										],
										content: latestArr,
										playType: "latest",
										currPage, // only 1 in the UI
										limit: 10,
										fetchMore,
										totalPages,
										moreLoad,
										load,
									}}
								/>
							</div>
							<div
								className="w-full h-[2.5rem] bg-[#FFF1F4] text-[#E6355C] font-bold text-base text-center leading-10 cursor-pointer"
								onClick={() => navigate("list")}
							>
								View All
							</div>
						</div>
					</div>
					<div className="flex flex-col h-full w-[30%] box-border p-[16px] gap-[10px]">
						<UploadMusicCard {...{ setCollabReqModal }} />
						<BookBandCard />
					</div>
				</div>
			)}
		</>
	);
}

export default Latest;
