import React from "react";
import styles from "./ShopDropdown.module.css";
import { motion } from "framer-motion";
import allDesignImage from "../../../assets/allDesignImage.svg"
import { useNavigate } from "react-router-dom";

// box-shadow: 0px 4px 24px 0px #00000040;

const ShopDropdown = ({ arts, closeDropdown }) => {
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{ top: "8%", opacity: "0" }}
      animate={{ top: "14%", opacity: 1 }}
      exit={{ top: "8%", opacity: 0 }}
      className={`${styles.main} sleek-scrollbar`}
    >
      <div
        className="flex flex-col p-[12px] cursor-pointer text-[#060709] gap-[12px]"
        onClick={() => {
          navigate("/collection");
          closeDropdown();
        }}
      >
        <img
          src={allDesignImage}
          alt="allDesign"
          className="h-[213px] object-cover"
        />
        <p className="font-semibold text-lg text-inherit text-nowrap"
          style={{ width: "197px" }}
        >
          All Designs
        </p>
      </div>
      {
        arts.map((art, ind) => {
          return (
            <div
              className="flex flex-col p-[12px] cursor-pointer text-[#060709] hover:bg-[#E6355C] hover:text-white gap-[12px]"
              onClick={() => {
                navigate("/shop", {
                  state: { artType: art?._id, artName: art?.artTypeName },
                });
                closeDropdown();
              }}
            >
              <img
                // loading="lazy"
                alt="shop image"
                src={art?.images[0]}
                className="h-[213px] unloaded-img w-[197px] object-cover"
              />
              <p className="font-semibold text-lg text-inherit">
                {art?.artTypeName}
              </p>
            </div>
          );
        })
      }
    </motion.div >
  );
};

export default ShopDropdown;
