import React from 'react'
import DefaultPfp from "../../../assets/company_logo_dark.png";


function ViewArtType(props) {
    const { modalData } = props
    return (
        <div
            className="bg-white max-h-[80vh] overflow-auto py-[24px] px-[16px] flex flex-col gap-[16px]"
        >
            <div className='flex gap-2'>
                <img
                    src={modalData.images[0]}
                    className={`w-1/2 h-[30rem] rounded-[4px]
                    object-cover`}
                    alt="profilePic"
                />
                {modalData.images[1] && <img
                    src={modalData.images[1]}
                    className={`w-1/2 h-[30rem] rounded-[4px]
                object-cover`}
                    alt="profilePic"
                />}
            </div>
            <div className='flex gap-2'>
                {modalData.images[2] && <img
                    src={modalData.images[2]}
                    className={`w-1/3 h-[30rem] rounded-[4px]
            object-cover`}
                    alt="profilePic"
                />}
                {modalData.images[3] && <img
                    src={modalData.images[3]}
                    className={`w-1/3 h-[30rem] rounded-[4px]
        object-cover`}
                    alt="profilePic"
                />}
                {modalData.images[4] && <img
                    src={modalData.images[4]}
                    className={`w-1/3 h-[30rem] rounded-[4px]
                    object-cover`}
                    alt="profilePic"
                />}
            </div>
            <span className="text-2xl font-semibold mt-2">
                {modalData?.artTypeName}
            </span>
            <span
            >
                {modalData?.description}
            </span>
        </div>
    )
}

export default ViewArtType
